import { useMemo } from "react";
import { ALL_CHAIN_IDS } from "uniswap/src/features/chains/types";
import { ChainsConfigKey, DynamicConfigs } from "uniswap/src/features/gating/configs";
import { useDynamicConfigValue } from "uniswap/src/features/gating/hooks";
export function useOrderedChainIds(chainIds) {
  const serverOrderedChains = useDynamicConfigValue(
    DynamicConfigs.Chains,
    ChainsConfigKey.OrderedChainIds,
    ALL_CHAIN_IDS
  );
  return useMemo(() => {
    const orderedChains = serverOrderedChains.filter((c) => chainIds.includes(c));
    const unspecifiedChains = chainIds.filter((c) => !serverOrderedChains.includes(c));
    return [...orderedChains, ...unspecifiedChains];
  }, [serverOrderedChains, chainIds]);
}
