"use strict";
import { SearchResultType } from "uniswap/src/features/search/SearchResult";
const recentSearchAtomName = "recentlySearchedAssetsV3";
function webResultToUniswapResult(webItem) {
  if (webItem.type === SearchResultType.Token) {
    return {
      type: SearchResultType.Token,
      chainId: webItem.chainId,
      symbol: webItem.symbol,
      address: webItem.address,
      name: webItem.name,
      logoUrl: webItem.logoUrl,
      safetyInfo: webItem.safetyInfo
    };
  } else if (webItem.type === SearchResultType.NFTCollection) {
    return {
      type: SearchResultType.NFTCollection,
      chainId: webItem.chainId,
      address: webItem.address,
      name: webItem.name,
      imageUrl: webItem.logoUrl,
      isVerified: false
    };
  } else {
    return null;
  }
}
export const migration15 = (state) => {
  if (!state) {
    return void 0;
  }
  const newState = { ...state };
  const recentlySearchedAssetsAtomValue = localStorage.getItem(recentSearchAtomName);
  const webSearchHistory = JSON.parse(recentlySearchedAssetsAtomValue ?? "[]");
  const translatedResults = webSearchHistory.map(webResultToUniswapResult).filter((r) => r !== null);
  newState.searchHistory = { results: translatedResults };
  localStorage.removeItem(recentSearchAtomName);
  return { ...newState, _persist: { ...state._persist, version: 15 } };
};
