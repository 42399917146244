"use strict";
import { LoadingBubble } from "components/Tokens/loading";
import { MouseoverTooltip } from "components/Tooltip";
import { useMemo } from "react";
import { Trans } from "react-i18next";
import { Flex, Text } from "ui/src";
import { NumberType, useFormatter } from "utils/formatNumbers";
import { warningSeverity } from "utils/prices";
export function FiatValue({
  fiatValue,
  priceImpact,
  testId
}) {
  const { formatNumber, formatPercent } = useFormatter();
  const priceImpactColor = useMemo(() => {
    if (!priceImpact) {
      return void 0;
    }
    if (priceImpact.lessThan("0")) {
      return "$statusSuccess";
    }
    const severity = warningSeverity(priceImpact);
    if (severity < 1) {
      return "$neutral3";
    }
    if (severity < 3) {
      return "$statusWarning";
    }
    return "$statusCritical";
  }, [priceImpact]);
  if (fiatValue.isLoading) {
    return <LoadingBubble borderRadius="$rounded4" width={64} height={14} />;
  }
  return <Flex row gap="$gap8"><Text variant="body3" color="$neutral2" data-testid={testId}>{fiatValue.data ? formatNumber({
    input: fiatValue.data,
    type: NumberType.FiatTokenPrice
  }) : <MouseoverTooltip text={<Trans i18nKey="liquidity.notEnough.label" />}>-</MouseoverTooltip>}</Text>{priceImpact && <Text variant="body3" color={priceImpactColor}><MouseoverTooltip placement="right" text={<Trans i18nKey="swap.estimatedDifference.label" />}>
            ({formatPercent(priceImpact.multiply(-1))})
          </MouseoverTooltip></Text>}</Flex>;
}
