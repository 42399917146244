"use strict";
import { CurrencyAmount, TradeType } from "@uniswap/sdk-core";
import { ConnectWalletButtonText } from "components/NavBar/accountCTAsExperimentUtils";
import { NATIVE_CHAIN_ID } from "constants/tokens";
import { useCurrency, useCurrencyInfo } from "hooks/Tokens";
import { useAccount } from "hooks/useAccount";
import useAutoSlippageTolerance from "hooks/useAutoSlippageTolerance";
import { useDebouncedTrade } from "hooks/useDebouncedTrade";
import { useSwapTaxes } from "hooks/useSwapTaxes";
import { useUSDPrice } from "hooks/useUSDPrice";
import useNativeCurrency from "lib/hooks/useNativeCurrency";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { useCallback, useEffect, useMemo } from "react";
import { Trans } from "react-i18next";
import { useCurrencyBalance, useCurrencyBalances } from "state/connection/hooks";
import { useMultichainContext } from "state/multichain/useMultichainContext";
import { isClassicTrade, isSubmittableTrade, isUniswapXTrade } from "state/routing/utils";
import { useSwapAndLimitContext, useSwapContext } from "state/swap/useSwapContext";
import { useUserSlippageToleranceWithDefault } from "state/user/hooks";
import { getNativeAddress } from "uniswap/src/constants/addresses";
import { useUrlContext } from "uniswap/src/contexts/UrlContext";
import { getChainInfo } from "uniswap/src/features/chains/chainInfo";
import { useEnabledChains } from "uniswap/src/features/chains/hooks/useEnabledChains";
import { useSupportedChainId } from "uniswap/src/features/chains/hooks/useSupportedChainId";
import { UniverseChainId } from "uniswap/src/features/chains/types";
import { CurrencyField } from "uniswap/src/types/currency";
import { isAddress } from "utilities/src/addresses";
import { getParsedChainId } from "utils/chainParams";
export function useSwapActionHandlers() {
  const { swapState, setSwapState } = useSwapContext();
  const { setCurrencyState } = useSwapAndLimitContext();
  const onSwitchTokens = useCallback(
    ({
      newOutputHasTax,
      previouslyEstimatedOutput
    }) => {
      if (newOutputHasTax && swapState.independentField === CurrencyField.INPUT) {
        setSwapState((swapState2) => ({
          ...swapState2,
          typedValue: previouslyEstimatedOutput
        }));
      } else {
        setSwapState((prev) => ({
          ...prev,
          independentField: prev.independentField === CurrencyField.INPUT ? CurrencyField.OUTPUT : CurrencyField.INPUT
        }));
      }
      setCurrencyState((prev) => ({
        inputCurrency: prev.outputCurrency,
        outputCurrency: prev.inputCurrency
      }));
    },
    [setCurrencyState, setSwapState, swapState.independentField]
  );
  return {
    onSwitchTokens
  };
}
export function useDerivedSwapInfo(state) {
  const account = useAccount();
  const { chainId } = useMultichainContext();
  const { currencyState } = useSwapAndLimitContext();
  const nativeCurrency = useNativeCurrency(chainId);
  const balance = useCurrencyBalance(account.address, nativeCurrency);
  const inputCurrencyInfo = useCurrencyInfo(currencyState.inputCurrency);
  const outputCurrencyInfo = useCurrencyInfo(currencyState.outputCurrency);
  const inputCurrency = inputCurrencyInfo?.currency;
  const outputCurrency = outputCurrencyInfo?.currency;
  const { independentField, typedValue } = state;
  const { inputTax, outputTax } = useSwapTaxes(
    inputCurrency?.isToken ? inputCurrency.address : void 0,
    outputCurrency?.isToken ? outputCurrency.address : void 0,
    chainId
  );
  const relevantTokenBalances = useCurrencyBalances(
    account.address,
    useMemo(() => [inputCurrency ?? void 0, outputCurrency ?? void 0], [inputCurrency, outputCurrency])
  );
  const isExactIn = independentField === CurrencyField.INPUT;
  const parsedAmount = useMemo(
    () => tryParseCurrencyAmount(typedValue, (isExactIn ? inputCurrency : outputCurrency) ?? void 0),
    [inputCurrency, isExactIn, outputCurrency, typedValue]
  );
  const trade = useDebouncedTrade(
    isExactIn ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
    parsedAmount,
    (isExactIn ? outputCurrency : inputCurrency) ?? void 0,
    state.routerPreferenceOverride,
    account.address
  );
  const { data: nativeCurrencyBalanceUSD } = useUSDPrice(balance, nativeCurrency);
  const { data: outputFeeFiatValue } = useUSDPrice(
    isSubmittableTrade(trade.trade) && trade.trade.swapFee ? CurrencyAmount.fromRawAmount(trade.trade.outputAmount.currency, trade.trade.swapFee.amount) : void 0,
    trade.trade?.outputAmount.currency
  );
  const currencyBalances = useMemo(
    () => ({
      [CurrencyField.INPUT]: relevantTokenBalances[0],
      [CurrencyField.OUTPUT]: relevantTokenBalances[1]
    }),
    [relevantTokenBalances]
  );
  const currencies = useMemo(
    () => ({
      [CurrencyField.INPUT]: inputCurrency,
      [CurrencyField.OUTPUT]: outputCurrency
    }),
    [inputCurrency, outputCurrency]
  );
  const classicAutoSlippage = useAutoSlippageTolerance(isClassicTrade(trade.trade) ? trade.trade : void 0);
  const uniswapXAutoSlippage = isUniswapXTrade(trade.trade) ? trade.trade.slippageTolerance : void 0;
  const autoSlippage = uniswapXAutoSlippage ?? classicAutoSlippage;
  const classicAllowedSlippage = useUserSlippageToleranceWithDefault(autoSlippage);
  const allowedSlippage = uniswapXAutoSlippage ?? classicAllowedSlippage;
  const insufficientGas = isClassicTrade(trade.trade) && (nativeCurrencyBalanceUSD ?? 0) < (trade.trade.totalGasUseEstimateUSDWithBuffer ?? 0);
  const { isDisconnected } = useAccount();
  const inputError = useMemo(() => {
    let inputError2;
    if (!account.isConnected) {
      inputError2 = isDisconnected ? <ConnectWalletButtonText /> : <Trans i18nKey="common.connectingWallet" />;
    }
    if (!currencies[CurrencyField.INPUT] || !currencies[CurrencyField.OUTPUT]) {
      inputError2 = inputError2 ?? <Trans i18nKey="common.selectToken.label" />;
    }
    if (!parsedAmount) {
      inputError2 = inputError2 ?? <Trans i18nKey="common.noAmount.error" />;
    }
    if (insufficientGas) {
      inputError2 = <Trans
        i18nKey="common.insufficientTokenBalance.error"
        values={{
          tokenSymbol: nativeCurrency.symbol
        }}
      />;
    }
    const [balanceIn, maxAmountIn] = [
      currencyBalances[CurrencyField.INPUT],
      trade?.trade?.maximumAmountIn(allowedSlippage)
    ];
    if (balanceIn && maxAmountIn && balanceIn.lessThan(maxAmountIn)) {
      inputError2 = <Trans
        i18nKey="common.insufficientTokenBalance.error"
        values={{
          tokenSymbol: balanceIn.currency.symbol
        }}
      />;
    }
    return inputError2;
  }, [
    account.isConnected,
    currencies,
    parsedAmount,
    insufficientGas,
    currencyBalances,
    trade?.trade,
    allowedSlippage,
    isDisconnected,
    nativeCurrency.symbol
  ]);
  return useMemo(
    () => ({
      currencies,
      currencyBalances,
      parsedAmount,
      inputError,
      trade,
      autoSlippage,
      allowedSlippage,
      outputFeeFiatValue,
      inputTax,
      outputTax
    }),
    [
      allowedSlippage,
      autoSlippage,
      currencies,
      currencyBalances,
      inputError,
      outputFeeFiatValue,
      parsedAmount,
      trade,
      inputTax,
      outputTax
    ]
  );
}
function parseFromURLParameter(urlParam) {
  if (typeof urlParam === "string") {
    return urlParam;
  }
  return void 0;
}
export function parseCurrencyFromURLParameter(urlParam) {
  if (typeof urlParam === "string") {
    const valid = isAddress(urlParam);
    if (valid) {
      return valid;
    }
    const upper = urlParam.toUpperCase();
    if (upper === "ETH") {
      return "ETH";
    }
    if (urlParam === NATIVE_CHAIN_ID) {
      return NATIVE_CHAIN_ID;
    }
  }
  return void 0;
}
function createBaseSwapURLParams({
  chainId,
  outputChainId,
  inputCurrency,
  outputCurrency,
  typedValue,
  independentField
}) {
  const params = new URLSearchParams();
  if (chainId) {
    params.set("chain", getChainInfo(chainId).interfaceName);
  }
  if (outputChainId && outputChainId !== chainId) {
    params.set("outputChain", getChainInfo(outputChainId).interfaceName);
  }
  if (inputCurrency) {
    params.set("inputCurrency", inputCurrency);
  }
  if (outputCurrency) {
    params.set("outputCurrency", outputCurrency);
  }
  if (typedValue) {
    params.set("value", typedValue);
  }
  if (independentField) {
    params.set("field", independentField);
  }
  return params;
}
export function serializeSwapStateToURLParameters(state) {
  const { inputCurrency, outputCurrency, typedValue, independentField, chainId } = state;
  const hasValidInput = (inputCurrency || outputCurrency) && typedValue;
  return "?" + createBaseSwapURLParams({
    chainId,
    outputChainId: outputCurrency?.chainId !== inputCurrency?.chainId ? outputCurrency?.chainId : void 0,
    inputCurrency: inputCurrency ? inputCurrency.isNative ? NATIVE_CHAIN_ID : inputCurrency.address : void 0,
    outputCurrency: outputCurrency ? outputCurrency.isNative ? NATIVE_CHAIN_ID : outputCurrency.address : void 0,
    typedValue: hasValidInput ? typedValue : void 0,
    independentField: hasValidInput ? independentField : void 0
  }).toString();
}
export function serializeSwapAddressesToURLParameters({
  inputTokenAddress,
  outputTokenAddress,
  chainId,
  outputChainId
}) {
  const chainIdOrDefault = chainId ?? UniverseChainId.Mainnet;
  return "?" + createBaseSwapURLParams({
    chainId: chainId ?? void 0,
    outputChainId: outputChainId ?? void 0,
    inputCurrency: inputTokenAddress ? inputTokenAddress === getNativeAddress(chainIdOrDefault) ? NATIVE_CHAIN_ID : inputTokenAddress : void 0,
    outputCurrency: outputTokenAddress ? outputTokenAddress === getNativeAddress(outputChainId ?? chainIdOrDefault) ? NATIVE_CHAIN_ID : outputTokenAddress : void 0
  }).toString();
}
export function queryParametersToCurrencyState(parsedQs) {
  const chainId = getParsedChainId(parsedQs);
  const outputChainId = getParsedChainId(parsedQs, CurrencyField.OUTPUT);
  const inputCurrencyId = parseCurrencyFromURLParameter(parsedQs.inputCurrency ?? parsedQs.inputcurrency);
  const parsedOutputCurrencyId = parseCurrencyFromURLParameter(parsedQs.outputCurrency ?? parsedQs.outputcurrency);
  const outputCurrencyId = parsedOutputCurrencyId === inputCurrencyId && outputChainId === chainId ? void 0 : parsedOutputCurrencyId;
  const hasCurrencyInput = inputCurrencyId || outputCurrencyId;
  const value = hasCurrencyInput ? parseFromURLParameter(parsedQs.value) : void 0;
  const field = value ? parseFromURLParameter(parsedQs.field) : void 0;
  return {
    inputCurrencyId,
    outputCurrencyId,
    value,
    field,
    chainId,
    outputChainId
  };
}
export function useInitialCurrencyState() {
  const { setIsUserSelectedToken } = useMultichainContext();
  const { defaultChainId, isTestnetModeEnabled } = useEnabledChains();
  const { useParsedQueryString } = useUrlContext();
  const parsedQs = useParsedQueryString();
  const parsedCurrencyState = useMemo(() => {
    return queryParametersToCurrencyState(parsedQs);
  }, [parsedQs]);
  const supportedChainId = useSupportedChainId(parsedCurrencyState.chainId ?? defaultChainId) ?? UniverseChainId.Mainnet;
  const supportedChainInfo = getChainInfo(supportedChainId);
  const isSupportedChainCompatible = isTestnetModeEnabled === !!supportedChainInfo.testnet;
  const hasCurrencyQueryParams = parsedCurrencyState.inputCurrencyId || parsedCurrencyState.outputCurrencyId || parsedCurrencyState.chainId;
  useEffect(() => {
    if (parsedCurrencyState.inputCurrencyId || parsedCurrencyState.outputCurrencyId) {
      setIsUserSelectedToken(true);
    }
  }, [parsedCurrencyState.inputCurrencyId, parsedCurrencyState.outputCurrencyId, setIsUserSelectedToken]);
  const { initialInputCurrencyAddress, initialChainId } = useMemo(() => {
    if (!hasCurrencyQueryParams || !isSupportedChainCompatible) {
      return {
        initialInputCurrencyAddress: "ETH",
        initialChainId: defaultChainId
      };
    }
    if (parsedCurrencyState.inputCurrencyId) {
      return {
        initialInputCurrencyAddress: parsedCurrencyState.inputCurrencyId,
        initialChainId: supportedChainId
      };
    }
    return {
      initialInputCurrencyAddress: parsedCurrencyState.outputCurrencyId ? void 0 : "ETH",
      initialChainId: supportedChainId
    };
  }, [
    hasCurrencyQueryParams,
    parsedCurrencyState.outputCurrencyId,
    parsedCurrencyState.inputCurrencyId,
    isSupportedChainCompatible,
    supportedChainId,
    defaultChainId
  ]);
  const outputChainIsSupported = useSupportedChainId(parsedCurrencyState.outputChainId);
  const initialOutputCurrencyAddress = useMemo(
    () => (
      // clear output if identical unless there's a supported outputChainId which means we're bridging
      initialInputCurrencyAddress === parsedCurrencyState.outputCurrencyId && !outputChainIsSupported ? void 0 : parsedCurrencyState.outputCurrencyId
    ),
    [initialInputCurrencyAddress, parsedCurrencyState.outputCurrencyId, outputChainIsSupported]
  );
  const initialInputCurrency = useCurrency(initialInputCurrencyAddress, initialChainId);
  const initialOutputCurrency = useCurrency(
    initialOutputCurrencyAddress,
    parsedCurrencyState.outputChainId ?? initialChainId
  );
  const initialTypedValue = initialInputCurrency || initialOutputCurrency ? parsedCurrencyState.value : void 0;
  const initialFieldUpper = parsedCurrencyState.field && typeof parsedCurrencyState.field === "string" ? parsedCurrencyState.field.toUpperCase() : void 0;
  const initialField = initialTypedValue && initialFieldUpper && initialFieldUpper in CurrencyField ? CurrencyField[initialFieldUpper] : void 0;
  return {
    initialInputCurrency,
    initialOutputCurrency,
    initialTypedValue,
    initialField,
    initialChainId,
    triggerConnect: !!parsedQs.connect
  };
}
