import { useEffect, useState } from "react";
import { useWindowDimensions } from "react-native";
import { breakpoints } from "ui/src/theme";
import { isExtension } from "utilities/src/platform";
const isClient = typeof window === "object";
function getDeviceDimensions() {
  return {
    fullHeight: window.innerHeight,
    fullWidth: window.innerWidth
  };
}
export const useDeviceDimensions = () => {
  const [deviceDimensions, setDeviceDimensions] = useState(getDeviceDimensions);
  useEffect(() => {
    function handleResize() {
      setDeviceDimensions(getDeviceDimensions());
    }
    if (isExtension) {
      handleResize();
    }
    if (!isClient) {
      return void 0;
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const { width: extensionWidth, height: extensionHeight } = useWindowDimensions();
  if (isExtension) {
    return {
      fullHeight: extensionHeight ?? 0,
      fullWidth: extensionWidth ?? 0
    };
  }
  return deviceDimensions;
};
export const useIsExtraLargeScreen = () => {
  const { fullWidth } = useDeviceDimensions();
  return fullWidth >= breakpoints.xl;
};
