"use strict";
import { InterfacePageName } from "@uniswap/analytics-events";
import { useAccountDrawer } from "components/AccountDrawer/MiniPortfolio/hooks";
import { SwapBottomCard } from "components/SwapBottomCard";
import { SwitchLocaleLink } from "components/SwitchLocaleLink";
import { PageWrapper } from "components/swap/styled";
import { PrefetchBalancesWrapper } from "graphql/data/apollo/AdaptiveTokenBalancesProvider";
import { PageType, useIsPage } from "hooks/useIsPage";
import { BuyForm } from "pages/Swap/Buy/BuyForm";
import { LimitFormWrapper } from "pages/Swap/Limit/LimitForm";
import { SendForm } from "pages/Swap/Send/SendForm";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { MultichainContextProvider } from "state/multichain/MultichainContext";
import { useSwapCallback } from "state/sagas/transactions/swapSaga";
import { useWrapCallback } from "state/sagas/transactions/wrapSaga";
import { SwapAndLimitContextProvider } from "state/swap/SwapContext";
import { useInitialCurrencyState } from "state/swap/hooks";
import { Flex, SegmentedControl, Text, Tooltip, styled } from "ui/src";
import { zIndexes } from "ui/src/theme";
import { useUniswapContext } from "uniswap/src/contexts/UniswapContext";
import { useEnabledChains } from "uniswap/src/features/chains/hooks/useEnabledChains";
import { FeatureFlags } from "uniswap/src/features/gating/flags";
import { useFeatureFlag } from "uniswap/src/features/gating/hooks";
import Trace from "uniswap/src/features/telemetry/Trace";
import { InterfaceEventNameLocal } from "uniswap/src/features/telemetry/constants";
import { sendAnalyticsEvent } from "uniswap/src/features/telemetry/send";
import { TransactionSettingsContextProvider } from "uniswap/src/features/transactions/settings/contexts/TransactionSettingsContext";
import { TransactionSettingKey } from "uniswap/src/features/transactions/settings/slice";
import { SwapFlow } from "uniswap/src/features/transactions/swap/SwapFlow";
import { SwapFormContextProvider } from "uniswap/src/features/transactions/swap/contexts/SwapFormContext";
import { useSwapPrefilledState } from "uniswap/src/features/transactions/swap/hooks/useSwapPrefilledState";
import { Deadline } from "uniswap/src/features/transactions/swap/settings/configs/Deadline";
import { ProtocolPreference } from "uniswap/src/features/transactions/swap/settings/configs/ProtocolPreference";
import { Slippage } from "uniswap/src/features/transactions/swap/settings/configs/Slippage";
import { currencyToAsset } from "uniswap/src/features/transactions/swap/utils/asset";
import { CurrencyField } from "uniswap/src/types/currency";
import { SwapTab } from "uniswap/src/types/screens/interface";
import { isMobileWeb } from "utilities/src/platform";
import noop from "utilities/src/react/noop";
import { isIFramed } from "utils/isIFramed";
export default function SwapPage() {
  const navigate = useNavigate();
  const location = useLocation();
  useFeatureFlag(FeatureFlags.AATestWeb);
  const accountDrawer = useAccountDrawer();
  const {
    initialInputCurrency,
    initialOutputCurrency,
    initialChainId,
    initialTypedValue,
    initialField,
    triggerConnect
  } = useInitialCurrencyState();
  useEffect(() => {
    if (triggerConnect) {
      accountDrawer.open();
      navigate(location.pathname, { replace: true });
    }
  }, [accountDrawer, triggerConnect, navigate, location.pathname]);
  return <Trace logImpression page={InterfacePageName.SWAP_PAGE}><PageWrapper><Swap
    chainId={initialChainId}
    initialInputCurrency={initialInputCurrency}
    initialOutputCurrency={initialOutputCurrency}
    initialTypedValue={initialTypedValue}
    initialIndependentField={initialField}
    syncTabToUrl={true}
  /></PageWrapper>{location.pathname === "/swap" && <SwitchLocaleLink />}</Trace>;
}
export function Swap({
  initialInputCurrency,
  initialOutputCurrency,
  initialTypedValue,
  initialIndependentField,
  chainId,
  hideHeader = false,
  hideFooter = false,
  onCurrencyChange,
  syncTabToUrl,
  swapRedirectCallback,
  tokenColor
}) {
  const isExplorePage = useIsPage(PageType.EXPLORE);
  const { isTestnetModeEnabled } = useEnabledChains();
  const isSharedSwapDisabled = isTestnetModeEnabled && isExplorePage;
  const input = currencyToAsset(initialInputCurrency);
  const output = currencyToAsset(initialOutputCurrency);
  const { isSwapTokenSelectorOpen, swapOutputChainId } = useUniswapContext();
  const prefilledState = useSwapPrefilledState({
    input,
    output,
    exactAmountToken: initialTypedValue ?? "",
    exactCurrencyField: initialIndependentField ?? CurrencyField.INPUT,
    selectingCurrencyField: isSwapTokenSelectorOpen ? CurrencyField.OUTPUT : void 0,
    selectingCurrencyChainId: swapOutputChainId,
    skipFocusOnCurrencyField: isMobileWeb
  });
  return <MultichainContextProvider initialChainId={chainId}><TransactionSettingsContextProvider settingKey={TransactionSettingKey.Swap}><SwapAndLimitContextProvider
    initialInputCurrency={initialInputCurrency}
    initialOutputCurrency={initialOutputCurrency}
  ><PrefetchBalancesWrapper><SwapFormContextProvider prefilledState={prefilledState} hideSettings={hideHeader} hideFooter={hideFooter}><Flex position="relative" gap="$spacing16" opacity={isSharedSwapDisabled ? 0.6 : 1}>{isSharedSwapDisabled && <DisabledSwapOverlay />}<UniversalSwapFlow
    hideHeader={hideHeader}
    hideFooter={hideFooter}
    syncTabToUrl={syncTabToUrl}
    swapRedirectCallback={swapRedirectCallback}
    onCurrencyChange={onCurrencyChange}
    prefilledState={prefilledState}
    tokenColor={tokenColor}
  /></Flex></SwapFormContextProvider></PrefetchBalancesWrapper></SwapAndLimitContextProvider></TransactionSettingsContextProvider></MultichainContextProvider>;
}
const SWAP_TABS = [SwapTab.Swap, SwapTab.Limit, SwapTab.Send, SwapTab.Buy];
const TAB_TYPE_TO_LABEL = {
  [SwapTab.Swap]: (t) => t("swap.form.header"),
  [SwapTab.Limit]: (t) => t("swap.limit"),
  [SwapTab.Send]: (t) => t("send.title"),
  [SwapTab.Buy]: (t) => t("common.buy.label")
};
const PATHNAME_TO_TAB = {
  "/swap": SwapTab.Swap,
  "/send": SwapTab.Send,
  "/limit": SwapTab.Limit,
  "/buy": SwapTab.Buy
};
function UniversalSwapFlow({
  hideHeader = false,
  hideFooter = false,
  disableTokenInputs = false,
  syncTabToUrl = true,
  prefilledState,
  onCurrencyChange,
  swapRedirectCallback,
  tokenColor
}) {
  const [currentTab, setCurrentTab] = useState(SwapTab.Swap);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const swapCallback = useSwapCallback();
  const wrapCallback = useWrapCallback();
  useEffect(() => {
    if (pathname === "/send" && isIFramed()) {
      setCurrentTab(SwapTab.Swap);
    } else {
      setCurrentTab(PATHNAME_TO_TAB[pathname] ?? SwapTab.Swap);
    }
  }, [pathname, setCurrentTab]);
  const onTabClick = useCallback(
    (tab) => {
      sendAnalyticsEvent(InterfaceEventNameLocal.SwapTabClicked, { tab });
      if (syncTabToUrl) {
        navigate(`/${tab}`, { replace: true });
      } else {
        setCurrentTab(tab);
      }
    },
    [navigate, syncTabToUrl, setCurrentTab]
  );
  const SWAP_TAB_OPTIONS = useMemo(() => {
    return SWAP_TABS.filter((tab) => !(isIFramed() && tab === SwapTab.Send)).map((tab) => ({
      value: tab,
      display: <Text
        variant="buttonLabel3"
        hoverStyle={{ color: "$neutral1" }}
        color={currentTab === tab ? "$neutral1" : "$neutral2"}
        tag="h1"
      >{TAB_TYPE_TO_LABEL[tab](t)}</Text>
    }));
  }, [t, currentTab]);
  return <Flex>{!hideHeader && <Flex row gap="$spacing16"><SegmentedControl
    outlined={false}
    size="large"
    options={SWAP_TAB_OPTIONS}
    selectedOption={currentTab}
    onSelectOption={onTabClick}
    gap={isMobileWeb ? "$spacing8" : void 0}
  /></Flex>}{currentTab === SwapTab.Swap && <Flex gap="$spacing16"><SwapFlow
    settings={[Slippage, Deadline, ProtocolPreference]}
    hideHeader={hideHeader}
    hideFooter={hideFooter}
    onClose={noop}
    swapRedirectCallback={swapRedirectCallback}
    onCurrencyChange={onCurrencyChange}
    swapCallback={swapCallback}
    wrapCallback={wrapCallback}
    prefilledState={prefilledState}
    tokenColor={tokenColor}
  /><SwapBottomCard /></Flex>}{currentTab === SwapTab.Limit && <LimitFormWrapper onCurrencyChange={onCurrencyChange} />}{currentTab === SwapTab.Send && <SendForm disableTokenInputs={disableTokenInputs} onCurrencyChange={onCurrencyChange} />}{currentTab === SwapTab.Buy && <BuyForm disabled={disableTokenInputs} initialCurrency={prefilledState?.output} />}</Flex>;
}
const DisabledOverlay = styled(Flex, {
  position: "absolute",
  width: "100%",
  height: "100%",
  zIndex: zIndexes.overlay
});
const DisabledSwapOverlay = () => {
  const { t } = useTranslation();
  return <DisabledOverlay cursor="not-allowed"><Tooltip placement="left-start"><Tooltip.Content><Tooltip.Arrow /><Text variant="body4">{t("testnet.unsupported")}</Text></Tooltip.Content><Tooltip.Trigger position="relative" width="100%" height="100%"><DisabledOverlay /></Tooltip.Trigger></Tooltip></DisabledOverlay>;
};
