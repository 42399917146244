"use strict";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import i18n from "uniswap/src/i18n";
const DEFAULT_METATAGS = {
  title: i18n.t("interface.metatags.title"),
  description: i18n.t("interface.metatags.description"),
  image: `https://app.uniswap.com/images/1200x630_Rich_Link_Preview_Image.png`,
  url: "https://app.uniswap.com"
};
export function useDynamicMetatags(metaTags = DEFAULT_METATAGS) {
  const [metaTagAttributes, setMetaTagAttributes] = useState([]);
  const location = useLocation();
  useEffect(() => {
    metaTags.url = window.location.href;
    const attributes = [
      { property: "og:title", content: metaTags.title },
      { property: "og:url", content: metaTags.url },
      { property: "twitter:title", content: metaTags.title }
    ];
    if (metaTags.description) {
      attributes.push(
        { property: "og:description", content: metaTags.description },
        { name: "description", content: metaTags.description }
      );
    }
    if (metaTags.image) {
      attributes.push(
        { property: "og:image", content: metaTags.image },
        { property: "og:image:alt", content: metaTags.title },
        { property: "twitter:image", content: metaTags.image },
        { property: "twitter:image:alt", content: metaTags.title }
      );
    }
    setMetaTagAttributes(attributes);
  }, [metaTags, location]);
  return metaTagAttributes;
}
