"use strict";
import { TickMarkType } from "lightweight-charts";
import { Trans } from "react-i18next";
export var PriceChartType = /* @__PURE__ */ ((PriceChartType2) => {
  PriceChartType2["LINE"] = "Line chart";
  PriceChartType2["CANDLESTICK"] = "Candlestick";
  return PriceChartType2;
})(PriceChartType || {});
export var ChartType = /* @__PURE__ */ ((ChartType2) => {
  ChartType2["PRICE"] = "Price";
  ChartType2["VOLUME"] = "Volume";
  ChartType2["TVL"] = "TVL";
  ChartType2["LIQUIDITY"] = "Liquidity";
  return ChartType2;
})(ChartType || {});
export const CHART_TYPE_LABELS = {
  ["Price" /* PRICE */]: <Trans i18nKey="common.price" />,
  ["Volume" /* VOLUME */]: <Trans i18nKey="common.volume" />,
  ["TVL" /* TVL */]: <Trans i18nKey="common.totalValueLocked" />,
  ["Liquidity" /* LIQUIDITY */]: <Trans i18nKey="common.liquidity" />,
  ["Line chart" /* LINE */]: <Trans i18nKey="chart.line" />,
  ["Candlestick" /* CANDLESTICK */]: <Trans i18nKey="chart.candlestick" />
};
export function formatTickMarks(time, tickMarkType, locale) {
  const date = new Date(time.valueOf() * 1e3);
  switch (tickMarkType) {
    case TickMarkType.Year:
      return date.toLocaleString(locale, { year: "numeric" });
    case TickMarkType.Month:
      return date.toLocaleString(locale, { month: "short", year: "numeric" });
    case TickMarkType.DayOfMonth:
      return date.toLocaleString(locale, { month: "short", day: "numeric" });
    case TickMarkType.Time:
      return date.toLocaleString(locale, { hour: "numeric", minute: "numeric" });
    case TickMarkType.TimeWithSeconds:
      return date.toLocaleString(locale, { hour: "numeric", minute: "numeric", second: "2-digit" });
  }
}
export function roundRect(ctx, x, y, w, h, radii) {
  if (ctx.roundRect) {
    ctx.beginPath();
    ctx.roundRect(x, y, w, h, radii);
    ctx.fill();
  } else {
    ctx.fillRect(x, y, w, h);
  }
}
