import { useMemo } from "react";
import { UniverseChainId } from "uniswap/src/features/chains/types";
import { filterChainIdsByFeatureFlag } from "uniswap/src/features/chains/utils";
import { FeatureFlags } from "uniswap/src/features/gating/flags";
import { useFeatureFlag } from "uniswap/src/features/gating/hooks";
export function useFeatureFlaggedChainIds() {
  const monadTestnetEnabled = useFeatureFlag(FeatureFlags.MonadTestnet);
  const soneiumEnabled = useFeatureFlag(FeatureFlags.Soneium);
  return useMemo(
    () => filterChainIdsByFeatureFlag({
      [UniverseChainId.MonadTestnet]: monadTestnetEnabled,
      [UniverseChainId.Soneium]: soneiumEnabled
    }),
    [monadTestnetEnabled, soneiumEnabled]
  );
}
