import { validateGasFeeResult } from "uniswap/src/features/gas/types";
import { isBridge, isClassic, isUniswapX } from "uniswap/src/features/transactions/swap/utils/routing";
import { isInterface } from "utilities/src/platform";
export function isValidSwapTxContext(swapTxContext) {
  return validateSwapTxContext(swapTxContext) !== void 0;
}
function validateSwapTxContext(swapTxContext) {
  if (!isSwapTx(swapTxContext)) {
    return void 0;
  }
  const gasFee = validateGasFeeResult(swapTxContext.gasFee);
  if (!gasFee) {
    return void 0;
  }
  if (swapTxContext.trade) {
    if (isClassic(swapTxContext)) {
      const { trade, txRequest, unsigned, permit } = swapTxContext;
      if (unsigned) {
        if (!isInterface || !permit) {
          return void 0;
        }
        return { ...swapTxContext, trade, gasFee, unsigned, txRequest: void 0, permit };
      } else if (txRequest) {
        return { ...swapTxContext, trade, gasFee, unsigned, txRequest, permit: void 0 };
      }
    } else if (isBridge(swapTxContext) && swapTxContext.txRequest) {
      const { trade, txRequest, unsigned, permit } = swapTxContext;
      if (unsigned) {
        if (!isInterface || !permit) {
          return void 0;
        }
        return { ...swapTxContext, trade, gasFee, unsigned, txRequest: void 0, permit };
      } else if (txRequest) {
        return { ...swapTxContext, trade, gasFee, unsigned, txRequest, permit: void 0 };
      }
    } else if (isUniswapX(swapTxContext) && swapTxContext.permit) {
      const { trade, permit } = swapTxContext;
      return { ...swapTxContext, trade, gasFee, permit };
    }
  }
  return void 0;
}
function isSwapTx(swapTxContext) {
  return typeof swapTxContext === "object" && swapTxContext !== null && "trade" in swapTxContext && "routing" in swapTxContext;
}
