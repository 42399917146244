export var TransactionStepType = /* @__PURE__ */ ((TransactionStepType2) => {
  TransactionStepType2["TokenApprovalTransaction"] = "TokenApproval";
  TransactionStepType2["TokenRevocationTransaction"] = "TokenRevocation";
  TransactionStepType2["SwapTransaction"] = "SwapTransaction";
  TransactionStepType2["WrapTransaction"] = "WrapTransaction";
  TransactionStepType2["SwapTransactionAsync"] = "SwapTransactionAsync";
  TransactionStepType2["Permit2Signature"] = "Permit2Signature";
  TransactionStepType2["UniswapXSignature"] = "UniswapXSignature";
  TransactionStepType2["IncreasePositionTransaction"] = "IncreasePositionTransaction";
  TransactionStepType2["IncreasePositionTransactionAsync"] = "IncreasePositionTransactionAsync";
  TransactionStepType2["DecreasePositionTransaction"] = "DecreasePositionTransaction";
  TransactionStepType2["MigratePositionTransactionStep"] = "MigratePositionTransaction";
  TransactionStepType2["MigratePositionTransactionStepAsync"] = "MigratePositionTransactionAsync";
  TransactionStepType2["CollectFeesTransactionStep"] = "CollectFeesTransaction";
  return TransactionStepType2;
})(TransactionStepType || {});
