export const DDRumAction = {
  ApplicationStartJs: "application_start_js",
  Context: (contextName) => `${contextName} Update`,
  ManualTiming: "manual_timing"
};
export const DDRumTiming = {
  ScreenInteractive: "screen_interactive"
};
export const DDRumManualTiming = {
  TokenSelectorListRender: "token_selector_list_render",
  RenderExploreSections: "render_explore_sections",
  RenderActivityTabList: "render_activity_tab_list",
  RenderTokenBalanceList: "render_token_balance_list"
};
