"use strict";
import { axisRight, select } from "d3";
import styled from "lib/styled-components";
import { useMemo } from "react";
import { NumberType, useFormatter } from "utils/formatNumbers";
const StyledGroup = styled.g`
  line {
    display: none;
  }

  text {
    color: ${({ theme }) => theme.neutral2};
  }
`;
const TEXT_Y_OFFSET = 5;
const Axis = ({
  axisGenerator,
  yScale,
  height
}) => {
  const axisRef = (axis) => {
    if (axis) {
      select(axis).call(axisGenerator).call((g) => g.select(".domain").remove()).call(
        (g) => g.selectAll("text").attr("transform", function(d) {
          const yCoordinate = yScale(d);
          if (yCoordinate < TEXT_Y_OFFSET) {
            return `translate(0, ${TEXT_Y_OFFSET})`;
          }
          if (yCoordinate > height - TEXT_Y_OFFSET) {
            return `translate(0, ${-TEXT_Y_OFFSET})`;
          }
          return "";
        })
      );
    }
  };
  return <g ref={axisRef} />;
};
export const AxisRight = ({
  yScale,
  offset = 0,
  min,
  current,
  max,
  height
}) => {
  const { formatNumber } = useFormatter();
  const tickValues = useMemo(() => {
    const minCoordinate = min ? yScale(min) : void 0;
    const maxCoordinate = max ? yScale(max) : void 0;
    const currentCoordinate = current ? yScale(current) : void 0;
    if (minCoordinate && currentCoordinate && Math.abs(minCoordinate - currentCoordinate) < TEXT_Y_OFFSET) {
      return [min, max].filter(Boolean);
    }
    if (maxCoordinate && currentCoordinate && Math.abs(maxCoordinate - currentCoordinate) < TEXT_Y_OFFSET) {
      return [min, max].filter(Boolean);
    }
    return [min, current, max].filter(Boolean);
  }, [current, max, min, yScale]);
  return <StyledGroup transform={`translate(${offset}, 0)`}><Axis
    axisGenerator={axisRight(yScale).tickValues(tickValues).tickFormat(
      (d) => formatNumber({
        input: d,
        type: NumberType.TokenQuantityStats
      })
    )}
    height={height}
    yScale={yScale}
  /></StyledGroup>;
};
