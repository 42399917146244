const DELAY_VALUES = [50, 100, 150, 200, 250, 300, 350];
const DEFAULT_ANIMATION_DELAY = 350;
export const getDelayValue = (delay) => `200msDelayed${delay}ms`;
export const delayAnimations200ms = {
  // needs to be one-to-one with DelayAnimationKey
  ...DELAY_VALUES.reduce(
    (acc, delay) => {
      acc[getDelayValue(delay)] = {
        type: "timing",
        delay,
        duration: 200
      };
      return acc;
    },
    {}
  )
};
export const get200MsAnimationDelayFromIndex = (index) => {
  return getDelayValue(DELAY_VALUES[index] || DEFAULT_ANIMATION_DELAY);
};
