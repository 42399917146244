"use strict";
import { UniverseChainId } from "uniswap/src/features/chains/types";
import { ArbitrumXV2SamplingProperties, Experiments } from "uniswap/src/features/gating/experiments";
import { useExperimentValue } from "uniswap/src/features/gating/hooks";
import { useUniswapXPriorityOrderFlag } from "uniswap/src/features/transactions/swap/utils/protocols";
export function useIsUniswapXSupportedChain(chainId) {
  const xv2ArbitrumRoutingType = useExperimentValue(Experiments.ArbitrumXV2Sampling, ArbitrumXV2SamplingProperties.RoutingType, "CLASSIC");
  const isPriorityOrdersEnabled = useUniswapXPriorityOrderFlag(chainId);
  return chainId === UniverseChainId.Mainnet || xv2ArbitrumRoutingType !== "CLASSIC" && chainId === UniverseChainId.ArbitrumOne || isPriorityOrdersEnabled;
}
