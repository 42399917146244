"use strict";
import { InterfaceEventName } from "@uniswap/analytics-events";
import { CurrencyAmount, MaxUint256 } from "@uniswap/sdk-core";
import { useTokenContract } from "hooks/useContract";
import { useTriggerOnTransactionType } from "hooks/useTriggerOnTransactionType";
import { useCallback, useMemo, useRef } from "react";
import { TransactionType } from "state/transactions/types";
import { trace } from "tracing/trace";
import { sendAnalyticsEvent } from "uniswap/src/features/telemetry/send";
import { useTrace } from "utilities/src/telemetry/trace/TraceContext";
import { UserRejectedRequestError } from "utils/errors";
import { didUserReject } from "utils/swapErrorToUserReadableMessage";
import { assume0xAddress } from "utils/wagmi";
import { erc20Abi } from "viem";
import { useReadContract } from "wagmi";
const MAX_ALLOWANCE = MaxUint256.toString();
export function useTokenAllowance(token, owner, spender) {
  const queryEnabled = !!owner && !!spender;
  const {
    data: rawAmount,
    isFetching,
    refetch: refetchAllowance
  } = useReadContract({
    address: assume0xAddress(token?.address),
    chainId: token?.chainId,
    abi: erc20Abi,
    functionName: "allowance",
    args: queryEnabled ? [assume0xAddress(owner), assume0xAddress(spender)] : void 0,
    query: { enabled: queryEnabled }
  });
  useTriggerOnTransactionType(TransactionType.APPROVAL, refetchAllowance);
  const allowance = useMemo(
    () => token && rawAmount !== void 0 ? CurrencyAmount.fromRawAmount(token, rawAmount.toString()) : void 0,
    [token, rawAmount]
  );
  return useMemo(() => ({ tokenAllowance: allowance, isSyncing: isFetching }), [allowance, isFetching]);
}
export function useUpdateTokenAllowance(amount, spender) {
  const analyticsTrace = useTrace();
  const contract = useTokenContract(amount?.currency.address, true, amount?.currency.chainId);
  const contractRef = useRef(contract);
  contractRef.current = contract;
  return useCallback(
    () => trace({ name: "Allowance", op: "permit.allowance" }, async (trace2) => {
      try {
        const contract2 = contractRef.current;
        if (!amount) {
          throw new Error("missing amount");
        }
        if (!contract2) {
          throw new Error("missing contract");
        }
        if (!spender) {
          throw new Error("missing spender");
        }
        const allowance = amount.equalTo(0) ? "0" : MAX_ALLOWANCE;
        const response = await trace2.child({ name: "Approve", op: "wallet.approve" }, async (walletTrace) => {
          const contract3 = contractRef.current;
          try {
            if (!contract3) {
              throw new Error("missing contract");
            }
            return await contract3.approve(spender, allowance);
          } catch (error) {
            if (didUserReject(error)) {
              walletTrace.setStatus("cancelled");
              const symbol = amount?.currency.symbol ?? "Token";
              throw new UserRejectedRequestError(`${symbol} token allowance failed: User rejected`);
            } else {
              throw error;
            }
          }
        });
        sendAnalyticsEvent(InterfaceEventName.APPROVE_TOKEN_TXN_SUBMITTED, {
          chain_id: amount.currency.chainId,
          token_symbol: amount.currency.symbol,
          token_address: amount.currency.address,
          ...analyticsTrace
        });
        return {
          response,
          info: {
            type: TransactionType.APPROVAL,
            tokenAddress: contract2.address,
            spender,
            amount: allowance
          }
        };
      } catch (error) {
        if (error instanceof UserRejectedRequestError) {
          trace2.setStatus("cancelled");
          throw error;
        } else {
          const symbol = amount?.currency.symbol ?? "Token";
          throw new Error(`${symbol} token allowance failed: ${error instanceof Error ? error.message : error}`);
        }
      }
    }),
    [amount, spender, analyticsTrace]
  );
}
export function useRevokeTokenAllowance(token, spender) {
  const amount = useMemo(() => token ? CurrencyAmount.fromRawAmount(token, 0) : void 0, [token]);
  return useUpdateTokenAllowance(amount, spender);
}
