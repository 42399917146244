"use strict";
import { popupRegistry } from "components/Popups/registry";
import { PopupType } from "components/Popups/types";
import { INTERNAL_JSON_RPC_ERROR_CODE } from "constants/misc";
import { useAccount } from "hooks/useAccount";
import useSelectChain from "hooks/useSelectChain";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { handleOnChainStep } from "state/sagas/transactions/utils";
import { TransactionType } from "state/transactions/types";
import { call } from "typed-redux-saga";
import { isTestnetChain } from "uniswap/src/features/chains/utils";
import { TransactionStepType } from "uniswap/src/features/transactions/swap/types/steps";
import { createSaga } from "uniswap/src/utils/saga";
import { logger } from "utilities/src/logger/logger";
import noop from "utilities/src/react/noop";
import { didUserReject } from "utils/swapErrorToUserReadableMessage";
export function* handleWrapStep(params) {
  const info = getWrapTransactionInfo(params.step.amount);
  return yield* call(handleOnChainStep, { ...params, info });
}
function* wrap(params) {
  try {
    const { account, inputCurrencyAmount, selectChain, txRequest, startChainId, onFailure } = params;
    if (txRequest.chainId !== startChainId) {
      const chainSwitched = yield* call(selectChain, txRequest.chainId);
      if (!chainSwitched) {
        onFailure();
        return;
      }
    }
    const step = { type: TransactionStepType.WrapTransaction, txRequest, amount: inputCurrencyAmount };
    const hash = yield* call(handleWrapStep, {
      step,
      account,
      setCurrentStep: noop,
      shouldWaitForConfirmation: false,
      allowDuplicativeTx: true
      // Compared to UniswapX wraps, the user should not be stopped from wrapping in quick succession
    });
    popupRegistry.addPopup({ type: PopupType.Transaction, hash }, hash);
    params.onSuccess();
  } catch (error) {
    if (didUserReject(error)) {
      params.onFailure();
      return;
    }
    if (!(isTestnetChain(params.txRequest.chainId) && error.code === INTERNAL_JSON_RPC_ERROR_CODE)) {
      logger.error(error, {
        tags: {
          file: "wrapSaga",
          function: "wrap",
          chainId: params.txRequest.chainId
        }
      });
    }
    params.onFailure();
  }
}
function getWrapTransactionInfo(amount) {
  return amount.currency.isNative ? {
    type: TransactionType.WRAP,
    unwrapped: false,
    currencyAmountRaw: amount.quotient.toString()
  } : {
    type: TransactionType.WRAP,
    unwrapped: true,
    currencyAmountRaw: amount.quotient.toString()
  };
}
export const wrapSaga = createSaga(wrap, "wrap");
export function useWrapCallback() {
  const appDispatch = useDispatch();
  const selectChain = useSelectChain();
  const startChainId = useAccount().chainId;
  return useCallback(
    (params) => {
      appDispatch(wrapSaga.actions.trigger({ ...params, selectChain, startChainId }));
    },
    [appDispatch, selectChain, startChainId]
  );
}
