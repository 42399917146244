"use strict";
import { ExploreTab } from "pages/Explore";
import { capitalize } from "tsafe/capitalize";
import i18n from "uniswap/src/i18n";
import { logger } from "utilities/src/logger/logger";
import { isChainUrlParam } from "utils/chainParams";
export const getExploreTitle = (path) => {
  const parts = path?.split("/").filter((part) => part !== "");
  const tabsToFind = [ExploreTab.Pools, ExploreTab.Tokens, ExploreTab.Transactions];
  const tab = parts?.find((part) => tabsToFind.includes(part)) ?? ExploreTab.Tokens;
  const networkPart = parts?.find(isChainUrlParam) ?? "ethereum";
  const network = capitalize(networkPart);
  switch (tab) {
    case ExploreTab.Pools:
      return i18n.t(`web.explore.title.pools`, {
        network
      });
    case ExploreTab.Tokens:
      return i18n.t(`web.explore.title.tokens`, {
        network
      });
    case ExploreTab.Transactions:
      return i18n.t(`web.explore.title.transactions`, {
        network
      });
    default:
      logger.error(`Unavailable explore title for tab ${tab}`, {
        tags: {
          file: "getExploreTitle",
          function: "getExploreTitle"
        }
      });
      return "";
  }
};
export const getExploreDescription = (path) => {
  const parts = path?.split("/").filter((part) => part !== "");
  const network = parts?.find(isChainUrlParam) ?? "ethereum";
  return i18n.t(`web.explore.description`, {
    network: capitalize(network)
  });
};
