"use strict";
import { useAccount } from "hooks/useAccount";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "state/hooks";
import { addSignature } from "state/signatures/reducer";
import {
  OFFCHAIN_ORDER_TYPE_TO_SIGNATURE_TYPE,
  SignatureType
} from "state/signatures/types";
import { UniswapXOrderStatus } from "types/uniswapx";
export function useAllSignatures() {
  const account = useAccount();
  const signatures = useAppSelector((state) => state.signatures) ?? {};
  if (!account.address || !signatures[account.address]) {
    return {};
  }
  return signatures[account.address];
}
export function usePendingOrders() {
  const signatures = useAllSignatures();
  return useMemo(() => {
    return Object.values(signatures).filter(isPendingOrder);
  }, [signatures]);
}
export function useOrder(orderHash) {
  const signatures = useAllSignatures();
  return useMemo(() => {
    const order = signatures[orderHash];
    if (!order || ![
      SignatureType.SIGN_UNISWAPX_ORDER,
      SignatureType.SIGN_UNISWAPX_V2_ORDER,
      SignatureType.SIGN_UNISWAPX_V3_ORDER,
      SignatureType.SIGN_LIMIT,
      SignatureType.SIGN_PRIORITY_ORDER
    ].includes(order.type)) {
      return void 0;
    }
    return order;
  }, [signatures, orderHash]);
}
export function useAddOrder() {
  const dispatch = useDispatch();
  return useCallback(
    (offerer, orderHash, chainId, expiry, swapInfo, encodedOrder, offchainOrderType) => {
      dispatch(
        addSignature({
          type: OFFCHAIN_ORDER_TYPE_TO_SIGNATURE_TYPE[offchainOrderType],
          offerer,
          id: orderHash,
          chainId,
          expiry,
          orderHash,
          swapInfo,
          status: UniswapXOrderStatus.OPEN,
          addedTime: Date.now(),
          encodedOrder
        })
      );
    },
    [dispatch]
  );
}
export function isFinalizedOrder(order) {
  if (order.type === SignatureType.SIGN_LIMIT) {
    return ![
      UniswapXOrderStatus.OPEN,
      UniswapXOrderStatus.PENDING_CANCELLATION,
      UniswapXOrderStatus.INSUFFICIENT_FUNDS
    ].includes(order.status);
  } else {
    return ![UniswapXOrderStatus.OPEN, UniswapXOrderStatus.PENDING_CANCELLATION].includes(order.status);
  }
}
export function isOnChainOrder(orderStatus) {
  return orderStatus === UniswapXOrderStatus.FILLED;
}
function isPendingOrder(signature) {
  if (signature.type === SignatureType.SIGN_LIMIT) {
    return [
      UniswapXOrderStatus.OPEN,
      UniswapXOrderStatus.PENDING_CANCELLATION,
      UniswapXOrderStatus.INSUFFICIENT_FUNDS
    ].includes(signature.status);
  } else if (signature.type === SignatureType.SIGN_UNISWAPX_ORDER || signature.type === SignatureType.SIGN_UNISWAPX_V2_ORDER || signature.type === SignatureType.SIGN_UNISWAPX_V3_ORDER || signature.type === SignatureType.SIGN_PRIORITY_ORDER) {
    return [
      UniswapXOrderStatus.OPEN,
      UniswapXOrderStatus.PENDING_CANCELLATION,
      UniswapXOrderStatus.INSUFFICIENT_FUNDS
    ].includes(signature.status);
  }
  return false;
}
