import { useQuery } from "@connectrpc/connect-query";
import { tokenRankings } from "@uniswap/client-explore/dist/uniswap/explore/v1/service-ExploreStatsService_connectquery";
import {
  ProtectionAttackType,
  ProtectionResult,
  SafetyLevel
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { uniswapGetTransport } from "uniswap/src/data/rest/base";
import { fromGraphQLChain } from "uniswap/src/features/chains/utils";
import { buildCurrency, buildCurrencyInfo, getCurrencySafetyInfo } from "uniswap/src/features/dataApi/utils";
import { currencyId } from "uniswap/src/utils/currencyId";
import { logger } from "utilities/src/logger/logger";
export function useTokenRankingsQuery(input, enabled = true) {
  return useQuery(tokenRankings, input, { transport: uniswapGetTransport, enabled });
}
function parseSafetyLevel(safetyLevel) {
  if (!safetyLevel) {
    return void 0;
  }
  const validSafetyLevels = Object.values(SafetyLevel);
  if (validSafetyLevels.includes(safetyLevel)) {
    return safetyLevel;
  } else {
    logger.warn(
      "uniswap/data/rest/tokenRankings.ts",
      "parseSafetyLevel",
      `Invalid safetyLevel from REST TokenRankings query: ${safetyLevel}`
    );
    return void 0;
  }
}
function parseProtectionInfo(protectionInfo) {
  if (!protectionInfo) {
    return void 0;
  }
  let protectionResult;
  const validProtectionResults = Object.values(ProtectionResult);
  if (validProtectionResults.includes(protectionInfo.result)) {
    protectionResult = protectionInfo.result;
  } else {
    logger.warn(
      "uniswap/data/rest/tokenRankings.ts",
      "parseProtectionInfo",
      `Invalid protectionResult from REST TokenRankings query: ${protectionInfo.result}`
    );
    return void 0;
  }
  const validAttackTypes = Object.values(ProtectionAttackType);
  const attackTypes = protectionInfo.attackTypes.filter((at) => validAttackTypes.includes(at)).map((at) => at);
  if (attackTypes.length !== protectionInfo.attackTypes.length) {
    logger.warn(
      "uniswap/data/rest/tokenRankings.ts",
      "parseProtectionInfo",
      `Invalid attackTypes in REST TokenRankings query: ${protectionInfo.attackTypes}`
    );
  }
  return { attackTypes, result: protectionResult };
}
export function tokenRankingsStatToCurrencyInfo(tokenRankingsStat) {
  const { chain, address, symbol, name, logo, decimals, feeData } = tokenRankingsStat;
  const chainId = fromGraphQLChain(chain);
  const protectionInfo = parseProtectionInfo(tokenRankingsStat.protectionInfo);
  const safetyLevel = parseSafetyLevel(tokenRankingsStat.safetyLevel);
  if (!chainId || !symbol || !name) {
    return null;
  }
  const currency = buildCurrency({
    chainId,
    address,
    decimals,
    symbol,
    name,
    buyFeeBps: feeData?.buyFeeBps,
    sellFeeBps: feeData?.sellFeeBps
  });
  if (!currency) {
    return null;
  }
  return buildCurrencyInfo({
    currency,
    currencyId: currencyId(currency),
    logoUrl: logo,
    safetyInfo: getCurrencySafetyInfo(safetyLevel, protectionInfo)
  });
}
