import { Popover } from "tamagui";
import { WebBottomSheet } from "ui/src/components/modal/AdaptiveWebModal";
export function AdaptiveWebPopoverContent({
  children,
  isOpen,
  webBottomSheetProps,
  ...popoverContentProps
}) {
  return <><Popover.Content {...popoverContentProps}>{children}</Popover.Content><Popover.Adapt when="sm"><WebBottomSheet isOpen={isOpen} {...webBottomSheetProps || {}}><Popover.Adapt.Contents /></WebBottomSheet></Popover.Adapt></>;
}
