"use strict";
import { Language } from "uniswap/src/features/language/constants";
import { getCurrentLanguageFromNavigator } from "uniswap/src/features/language/utils";
import { isInterface } from "utilities/src/platform";
export const migration22 = (state) => {
  if (!state?.userSettings) {
    return void 0;
  }
  const newState = { ...state };
  if (newState.userSettings.currentLanguage === Language.English && isInterface) {
    newState.userSettings.currentLanguage = getCurrentLanguageFromNavigator();
  }
  return { ...newState, _persist: { ...state._persist, version: 22 } };
};
