"use strict";
import { ProtocolVersion } from "@uniswap/client-pools/dist/pools/v1/types_pb";
import {
  DEFAULT_POSITION_STATE,
  PositionFlowStep
} from "pages/Pool/Positions/create/types";
import React, { useContext } from "react";
import { PositionField } from "types/position";
export const CreatePositionContext = React.createContext({
  reset: () => void 0,
  step: PositionFlowStep.SELECT_TOKENS_AND_FEE_TIER,
  setStep: () => void 0,
  positionState: DEFAULT_POSITION_STATE,
  setPositionState: () => void 0,
  feeTierSearchModalOpen: false,
  setFeeTierSearchModalOpen: () => void 0,
  derivedPositionInfo: {
    protocolVersion: ProtocolVersion.V4,
    currencies: [void 0, void 0],
    isPoolOutOfSync: false,
    refetchPoolData: () => void 0,
    defaultInitialPrice: void 0,
    isDefaultInitialPriceLoading: false
  },
  dynamicFeeTierSpeedbumpData: {
    open: false,
    wishFeeData: DEFAULT_POSITION_STATE.fee
  },
  setDynamicFeeTierSpeedbumpData: () => void 0,
  currentTransactionStep: void 0,
  setCurrentTransactionStep: () => void 0
});
export const useCreatePositionContext = () => {
  return useContext(CreatePositionContext);
};
export const DEFAULT_PRICE_RANGE_STATE = {
  priceInverted: false,
  fullRange: true,
  minPrice: "",
  maxPrice: "",
  initialPrice: ""
};
export const PriceRangeContext = React.createContext({
  reset: () => void 0,
  priceRangeState: DEFAULT_PRICE_RANGE_STATE,
  setPriceRangeState: () => void 0,
  derivedPriceRangeInfo: {
    protocolVersion: ProtocolVersion.V4,
    isSorted: false,
    ticksAtLimit: [false, false],
    invertPrice: false,
    tickSpaceLimits: [0, 0],
    deposit0Disabled: false,
    deposit1Disabled: false,
    ticks: [0, 0],
    invalidPrice: false,
    invalidRange: false,
    outOfRange: false,
    prices: [void 0, void 0],
    pricesAtLimit: [void 0, void 0],
    pricesAtTicks: [void 0, void 0]
  }
});
export const usePriceRangeContext = () => {
  return useContext(PriceRangeContext);
};
export const DEFAULT_DEPOSIT_STATE = {
  exactField: PositionField.TOKEN0,
  exactAmounts: {}
};
export const DepositContext = React.createContext({
  reset: () => void 0,
  depositState: DEFAULT_DEPOSIT_STATE,
  setDepositState: () => void 0,
  derivedDepositInfo: {}
});
export const useDepositContext = () => {
  return useContext(DepositContext);
};
export const CreateTxContext = React.createContext({ error: false });
export const useCreateTxContext = () => {
  return useContext(CreateTxContext);
};
