"use strict";
import { useMedia } from "ui/src";
export function useIsMobileDrawer() {
  const media = useMedia();
  return media.sm;
}
export function useTabsVisible() {
  const media = useMedia();
  return !media.md;
}
