/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ProtocolItems {
    V2 = 'V2',
    V3 = 'V3',
    V4 = 'V4',
    UNISWAPX = 'UNISWAPX',
    UNISWAPX_V2 = 'UNISWAPX_V2',
    UNISWAPX_V3 = 'UNISWAPX_V3',
}
