"use strict";
import { useEffect, useMemo } from "react";
import { usePendingTransactions } from "state/transactions/hooks";
import { usePrevious } from "utilities/src/react/hooks";
export function useTriggerOnTransactionType(type, trigger) {
  const pendingTransactions = usePendingTransactions();
  const numPendingTransactions = useMemo(
    () => pendingTransactions.filter((tx) => tx.info.type === type).length,
    [pendingTransactions, type]
  );
  const prevNumPendingTransactions = usePrevious(numPendingTransactions);
  useEffect(() => {
    if (prevNumPendingTransactions && numPendingTransactions < prevNumPendingTransactions) {
      trigger();
    }
  }, [numPendingTransactions, prevNumPendingTransactions, trigger]);
}
