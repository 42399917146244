import { cloneElement } from "react";
import { useStyle } from "tamagui";
import { CustomButtonText } from "ui/src/components/buttons/Button/components/CustomButtonText";
import { useIconSizes } from "ui/src/components/buttons/Button/hooks/useIconSizes";
export const ThemedIcon = ({
  children,
  size = "medium",
  variant,
  isDisabled,
  emphasis,
  typeOfButton
}) => {
  const iconSizes = useIconSizes(typeOfButton);
  const { color, "$group-item-hover": groupItemHover } = useStyle(
    { variant, emphasis, isDisabled },
    {
      forComponent: CustomButtonText
    }
  );
  if (!children) {
    return null;
  }
  const width = iconSizes[size];
  const height = width;
  return cloneElement(children, {
    color: children.props?.color ?? color,
    width,
    height,
    "$group-item-hover": groupItemHover
  });
};
