"use strict";
export const migration23 = (state) => {
  if (!state) {
    return void 0;
  }
  return {
    ...state,
    visibility: {
      ...state.visibility,
      tokens: state.visibility?.tokens ?? {},
      // Ensure tokens property exists
      positions: state.visibility?.positions ?? {}
      // Ensure positions property exists
    },
    _persist: { ...state._persist, version: 23 }
  };
};
