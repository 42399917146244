"use strict";
import { Percent } from "@uniswap/sdk-core";
import JSBI from "jsbi";
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 10;
export const L2_DEADLINE_FROM_NOW = 60 * 5;
export const DEFAULT_TXN_DISMISS_MS = 1e4;
export const L2_TXN_DISMISS_MS = 5e3;
export const BIG_INT_ZERO = JSBI.BigInt(0);
export const BIPS_BASE = 1e4;
export const ALLOWED_PRICE_IMPACT_LOW = new Percent(1, 100);
export const ALLOWED_PRICE_IMPACT_MEDIUM = new Percent(3, 100);
export const ALLOWED_PRICE_IMPACT_HIGH = new Percent(5, 100);
export const BLOCKED_PRICE_IMPACT_NON_EXPERT = new Percent(15, 100);
export const ZERO_PERCENT = new Percent(0);
export const ONE_HUNDRED_PERCENT = new Percent(1);
export const INTERNAL_JSON_RPC_ERROR_CODE = -32603;
