"use strict";
import {
  PoolPosition,
  PositionStatus,
  ProtocolVersion,
  Pool as RestPool
} from "@uniswap/client-pools/dist/pools/v1/types_pb";
import { CurrencyAmount, Percent, Token } from "@uniswap/sdk-core";
import { Pair } from "@uniswap/v2-sdk";
import { FeeAmount, Pool as V3Pool, Position as V3Position } from "@uniswap/v3-sdk";
import { Pool as V4Pool, Position as V4Position } from "@uniswap/v4-sdk";
import { defaultFeeTiers } from "components/Liquidity/constants";
import { ZERO_ADDRESS } from "constants/misc";
import { DYNAMIC_FEE_DATA } from "pages/Pool/Positions/create/types";
import { Flag } from "ui/src/components/icons/Flag";
import { Pools } from "ui/src/components/icons/Pools";
import { SwapCoin } from "ui/src/components/icons/SwapCoin";
import { nativeOnChain } from "uniswap/src/constants/tokens";
import { ProtocolItems } from "uniswap/src/data/tradingApi/__generated__";
import { getChainInfo } from "uniswap/src/features/chains/chainInfo";
export function hasLPFoTTransferError(currencyInfo, protocolVersion) {
  const currency = currencyInfo?.currency;
  if (!protocolVersion || protocolVersion === ProtocolVersion.V2 || !currency || currency?.isNative) {
    return void 0;
  }
  return currency?.wrapped.buyFeeBps?.gt(0) || (currencyInfo?.safetyInfo?.blockaidFees?.buyFeePercent ?? 0) > 0 || currency?.wrapped.sellFeeBps?.gt(0) || (currencyInfo?.safetyInfo?.blockaidFees?.sellFeePercent ?? 0) > 0 ? currencyInfo : void 0;
}
export function getProtocolVersionLabel(version) {
  switch (version) {
    case ProtocolVersion.V2:
      return "v2";
    case ProtocolVersion.V3:
      return "v3";
    case ProtocolVersion.V4:
      return "v4";
    default:
      return void 0;
  }
}
export function getProtocolItems(version) {
  switch (version) {
    case ProtocolVersion.V2:
      return ProtocolItems.V2;
    case ProtocolVersion.V3:
      return ProtocolItems.V3;
    case ProtocolVersion.V4:
      return ProtocolItems.V4;
  }
  return void 0;
}
export function getProtocolStatusLabel(status, t) {
  switch (status) {
    case PositionStatus.IN_RANGE:
      return t("common.withinRange");
    case PositionStatus.OUT_OF_RANGE:
      return t("common.outOfRange");
    case PositionStatus.CLOSED:
      return t("common.closed");
  }
  return void 0;
}
export function parseProtocolVersion(version) {
  switch (version?.toLowerCase()) {
    case "v2":
      return ProtocolVersion.V2;
    case "v3":
      return ProtocolVersion.V3;
    case "v4":
      return ProtocolVersion.V4;
    default:
      return void 0;
  }
}
export function getPositionUrl(position) {
  const chainInfo = getChainInfo(position.chainId);
  if (position.version === ProtocolVersion.V2) {
    return `/positions/v2/${chainInfo.urlParam}/${position.liquidityToken.address}`;
  } else if (position.version === ProtocolVersion.V3) {
    return `/positions/v3/${chainInfo.urlParam}/${position.tokenId}`;
  }
  return `/positions/v4/${chainInfo.urlParam}/${position.tokenId}`;
}
function parseV3FeeTier(feeTier) {
  const parsedFee = parseInt(feeTier || "");
  return parsedFee in FeeAmount ? parsedFee : void 0;
}
export function getPoolFromRest({
  pool,
  token0,
  token1,
  protocolVersion,
  hooks
}) {
  if (!pool || !token0 || !token1) {
    return void 0;
  }
  if (pool instanceof RestPool) {
    if (protocolVersion === ProtocolVersion.V3) {
      return new V3Pool(token0, token1, pool.fee, pool.sqrtPriceX96, pool.liquidity, pool.tick);
    }
    return new V4Pool(
      token0,
      token1,
      pool.fee,
      pool.tickSpacing,
      hooks || ZERO_ADDRESS,
      pool.sqrtPriceX96,
      pool.liquidity,
      pool.tick
    );
  }
  if (pool instanceof PoolPosition) {
    if (protocolVersion === ProtocolVersion.V3) {
      const feeTier = parseV3FeeTier(pool.feeTier);
      if (feeTier) {
        return new V3Pool(
          token0,
          token1,
          feeTier,
          pool.currentPrice,
          pool.currentLiquidity,
          parseInt(pool.currentTick)
        );
      }
    }
    const fee = parseInt(pool.feeTier ?? "");
    return new V4Pool(
      token0,
      token1,
      fee,
      parseInt(pool.tickSpacing),
      hooks || ZERO_ADDRESS,
      pool.currentPrice,
      pool.liquidity,
      parseInt(pool.currentTick)
    );
  }
  return void 0;
}
function parseRestToken(token) {
  if (!token) {
    return void 0;
  }
  if (token.address === ZERO_ADDRESS) {
    return nativeOnChain(token.chainId);
  }
  return new Token(token.chainId, token.address, token.decimals, token.symbol);
}
function getPairFromRest({
  pair,
  token0,
  token1
}) {
  if (!pair) {
    return void 0;
  }
  return new Pair(
    CurrencyAmount.fromRawAmount(token0, pair.reserve0),
    CurrencyAmount.fromRawAmount(token1, pair.reserve1)
  );
}
export function parseRestPosition(position) {
  if (position?.position.case === "v2Pair") {
    const v2PairPosition = position.position.value;
    const token0 = parseRestToken(v2PairPosition.token0);
    const token1 = parseRestToken(v2PairPosition.token1);
    const liquidityToken = parseRestToken(v2PairPosition.liquidityToken);
    if (!token0 || !token1 || !liquidityToken) {
      return void 0;
    }
    const pair = getPairFromRest({ pair: position.position.value, token0, token1 });
    return {
      status: position.status,
      version: ProtocolVersion.V2,
      pair,
      liquidityToken,
      chainId: token0.chainId,
      poolId: liquidityToken.address,
      currency0Amount: CurrencyAmount.fromRawAmount(token0, v2PairPosition.liquidity0),
      currency1Amount: CurrencyAmount.fromRawAmount(token1, v2PairPosition.liquidity1),
      totalSupply: CurrencyAmount.fromRawAmount(liquidityToken, v2PairPosition.totalSupply),
      liquidityAmount: CurrencyAmount.fromRawAmount(liquidityToken, v2PairPosition.liquidity),
      apr: v2PairPosition.apr,
      v4hook: void 0,
      feeTier: void 0,
      owner: void 0,
      isHidden: position.isHidden
    };
  } else if (position?.position.case === "v3Position") {
    const v3Position = position.position.value;
    const token0 = parseRestToken(v3Position.token0);
    const token1 = parseRestToken(v3Position.token1);
    if (!token0 || !token1) {
      return void 0;
    }
    const pool = getPoolFromRest({ pool: position.position.value, token0, token1, protocolVersion: ProtocolVersion.V3 });
    const sdkPosition = pool ? new V3Position({
      pool,
      liquidity: v3Position.liquidity,
      tickLower: Number(v3Position.tickLower),
      tickUpper: Number(v3Position.tickUpper)
    }) : void 0;
    return {
      status: position.status,
      feeTier: parseV3FeeTier(v3Position.feeTier),
      version: ProtocolVersion.V3,
      chainId: token0.chainId,
      pool,
      poolId: position.position.value.poolId,
      position: sdkPosition,
      tickLower: v3Position.tickLower,
      tickUpper: v3Position.tickUpper,
      tickSpacing: Number(v3Position.tickSpacing),
      liquidity: v3Position.liquidity,
      tokenId: v3Position.tokenId,
      token0UncollectedFees: v3Position.token0UncollectedFees,
      token1UncollectedFees: v3Position.token1UncollectedFees,
      currency0Amount: CurrencyAmount.fromRawAmount(token0, v3Position.amount0),
      currency1Amount: CurrencyAmount.fromRawAmount(token1, v3Position.amount1),
      apr: v3Position.apr,
      v4hook: void 0,
      owner: v3Position.owner,
      isHidden: position.isHidden
    };
  } else if (position?.position.case === "v4Position") {
    const v4Position = position.position.value.poolPosition;
    const token0 = parseRestToken(v4Position?.token0);
    const token1 = parseRestToken(v4Position?.token1);
    if (!v4Position || !token0 || !token1) {
      return void 0;
    }
    const hook = position.position.value.hooks[0]?.address;
    const pool = getPoolFromRest({ pool: v4Position, token0, token1, hooks: hook, protocolVersion: ProtocolVersion.V4 });
    const sdkPosition = pool ? new V4Position({
      pool,
      liquidity: v4Position.liquidity,
      tickLower: Number(v4Position.tickLower),
      tickUpper: Number(v4Position.tickUpper)
    }) : void 0;
    const poolId = V4Pool.getPoolId(token0, token1, Number(v4Position.feeTier), Number(v4Position.tickSpacing), hook);
    return {
      status: position.status,
      feeTier: v4Position.feeTier,
      version: ProtocolVersion.V4,
      position: sdkPosition,
      chainId: token0.chainId,
      pool,
      poolId,
      v4hook: hook,
      tokenId: v4Position.tokenId,
      tickLower: v4Position.tickLower,
      tickUpper: v4Position.tickUpper,
      tickSpacing: Number(v4Position.tickSpacing),
      currency0Amount: CurrencyAmount.fromRawAmount(token0, v4Position.amount0 ?? 0),
      currency1Amount: CurrencyAmount.fromRawAmount(token1, v4Position.amount1 ?? 0),
      token0UncollectedFees: v4Position.token0UncollectedFees,
      token1UncollectedFees: v4Position.token1UncollectedFees,
      liquidity: v4Position.liquidity,
      apr: v4Position.apr,
      owner: v4Position.owner,
      isHidden: position.isHidden
    };
  } else {
    return void 0;
  }
}
export function calculateInvertedValues({
  priceLower,
  priceUpper,
  quote,
  base,
  invert
}) {
  return {
    priceUpper: invert ? priceLower?.invert() : priceUpper,
    priceLower: invert ? priceUpper?.invert() : priceLower,
    quote: invert ? base : quote,
    base: invert ? quote : base
  };
}
export function calculateTickSpacingFromFeeAmount(feeAmount) {
  return Math.max(Math.round(2 * feeAmount / 100), 1);
}
export var HookFlag = /* @__PURE__ */ ((HookFlag2) => {
  HookFlag2["BeforeAddLiquidity"] = "before-add-liquidity";
  HookFlag2["AfterAddLiquidity"] = "after-add-liquidity";
  HookFlag2["BeforeRemoveLiquidity"] = "before-remove-liquidity";
  HookFlag2["AfterRemoveLiquidity"] = "after-remove-liquidity";
  HookFlag2["BeforeSwap"] = "before-swap";
  HookFlag2["AfterSwap"] = "after-swap";
  HookFlag2["BeforeDonate"] = "before-donate";
  HookFlag2["AfterDonate"] = "after-donate";
  HookFlag2["BeforeSwapReturnsDelta"] = "before-swap-returns-delta";
  HookFlag2["AfterSwapReturnsDelta"] = "after-swap-returns-delta";
  HookFlag2["AfterAddLiquidityReturnsDelta"] = "after-add-liquidity-returns-delta";
  HookFlag2["AfterRemoveLiquidityReturnsDelta"] = "after-remove-liquidity-returns-delta";
  return HookFlag2;
})(HookFlag || {});
const FLAGS = {
  ["before-remove-liquidity" /* BeforeRemoveLiquidity */]: 1 << 9,
  ["after-remove-liquidity" /* AfterRemoveLiquidity */]: 1 << 8,
  ["before-add-liquidity" /* BeforeAddLiquidity */]: 1 << 11,
  ["after-add-liquidity" /* AfterAddLiquidity */]: 1 << 10,
  ["before-swap" /* BeforeSwap */]: 1 << 7,
  ["after-swap" /* AfterSwap */]: 1 << 6,
  ["before-donate" /* BeforeDonate */]: 1 << 5,
  ["after-donate" /* AfterDonate */]: 1 << 4,
  ["before-swap-returns-delta" /* BeforeSwapReturnsDelta */]: 1 << 3,
  ["after-swap-returns-delta" /* AfterSwapReturnsDelta */]: 1 << 2,
  ["after-add-liquidity-returns-delta" /* AfterAddLiquidityReturnsDelta */]: 1 << 1,
  ["after-remove-liquidity-returns-delta" /* AfterRemoveLiquidityReturnsDelta */]: 1 << 0
};
export function getFlagsFromContractAddress(contractAddress) {
  const last4Hex = contractAddress.slice(-4);
  const binaryStr = parseInt(last4Hex, 16).toString(2);
  const relevantBits = binaryStr.slice(-12);
  const activeFlags = Object.entries(FLAGS).filter(([, bitPosition]) => (parseInt(relevantBits, 2) & bitPosition) !== 0).map(([flag]) => flag);
  return activeFlags;
}
export function getFlagWarning(flag, t) {
  switch (flag) {
    case "before-swap" /* BeforeSwap */:
    case "before-swap-returns-delta" /* BeforeSwapReturnsDelta */:
      return {
        Icon: SwapCoin,
        name: t("common.swap"),
        info: t("position.hook.swapWarning"),
        dangerous: false
      };
    case "before-add-liquidity" /* BeforeAddLiquidity */:
    case "after-add-liquidity" /* AfterAddLiquidity */:
      return {
        Icon: Pools,
        name: t("common.addLiquidity"),
        info: t("position.hook.liquidityWarning"),
        dangerous: false
      };
    case "before-remove-liquidity" /* BeforeRemoveLiquidity */:
    case "after-remove-liquidity" /* AfterRemoveLiquidity */:
      return {
        Icon: Flag,
        name: t("common.warning"),
        info: t("position.hook.removeWarning"),
        dangerous: true
      };
    case "before-donate" /* BeforeDonate */:
    case "after-donate" /* AfterDonate */:
      return {
        Icon: Flag,
        name: t("common.donate"),
        info: t("position.hook.donateWarning"),
        dangerous: false
      };
    default:
      return void 0;
  }
}
export function mergeFeeTiers(feeTiers, feeData, formatPercent, formattedDynamicFeeTier) {
  const result = {};
  for (const feeTier of feeData) {
    result[feeTier.feeAmount] = {
      fee: feeTier,
      formattedFee: isDynamicFeeTier(feeTier) ? formattedDynamicFeeTier : formatPercent(new Percent(feeTier.feeAmount, 1e6)),
      totalLiquidityUsd: 0,
      percentage: new Percent(0, 100),
      created: false,
      tvl: "0"
    };
  }
  return { ...result, ...feeTiers };
}
function getDefaultFeeTiersForChain(chainId, protocolVersion) {
  const feeData = Object.values(defaultFeeTiers).filter((feeTier) => {
    if (protocolVersion === ProtocolVersion.V3) {
      return !feeTier.supportedChainIds || chainId && feeTier.supportedChainIds.includes(chainId);
    }
    return !feeTier.supportedChainIds;
  }).map((feeTier) => feeTier.feeData);
  return feeData.reduce(
    (acc, fee) => {
      acc[fee.feeAmount] = fee;
      return acc;
    },
    {}
  );
}
export function getDefaultFeeTiersForChainWithDynamicFeeTier({
  chainId,
  dynamicFeeTierEnabled,
  protocolVersion
}) {
  const feeTiers = getDefaultFeeTiersForChain(chainId, protocolVersion);
  if (!dynamicFeeTierEnabled) {
    return feeTiers;
  }
  return { ...feeTiers, [DYNAMIC_FEE_DATA.feeAmount]: DYNAMIC_FEE_DATA };
}
export function getDefaultFeeTiersWithData({
  chainId,
  feeTierData,
  protocolVersion,
  t
}) {
  const defaultFeeTiersForChain = getDefaultFeeTiersForChain(chainId, protocolVersion);
  const feeTiers = [
    {
      tier: FeeAmount.LOWEST,
      value: defaultFeeTiersForChain[FeeAmount.LOWEST],
      title: t(`fee.bestForVeryStable`),
      selectionPercent: feeTierData[FeeAmount.LOWEST]?.percentage,
      tvl: feeTierData[FeeAmount.LOWEST]?.tvl
    },
    {
      tier: FeeAmount.LOW_200,
      value: defaultFeeTiersForChain[FeeAmount.LOW_200],
      title: "",
      selectionPercent: feeTierData[FeeAmount.LOW_200]?.percentage,
      tvl: feeTierData[FeeAmount.LOW_200]?.tvl
    },
    {
      tier: FeeAmount.LOW_300,
      value: defaultFeeTiersForChain[FeeAmount.LOW_300],
      title: "",
      selectionPercent: feeTierData[FeeAmount.LOW_300]?.percentage,
      tvl: feeTierData[FeeAmount.LOW_300]?.tvl
    },
    {
      tier: FeeAmount.LOW_400,
      value: defaultFeeTiersForChain[FeeAmount.LOW_400],
      title: "",
      selectionPercent: feeTierData[FeeAmount.LOW_400]?.percentage,
      tvl: feeTierData[FeeAmount.LOW_400]?.tvl
    },
    {
      tier: FeeAmount.LOW,
      value: defaultFeeTiersForChain[FeeAmount.LOW],
      title: t(`fee.bestForStablePairs`),
      selectionPercent: feeTierData[FeeAmount.LOW]?.percentage,
      tvl: feeTierData[FeeAmount.LOW]?.tvl
    },
    {
      tier: FeeAmount.MEDIUM,
      value: defaultFeeTiersForChain[FeeAmount.MEDIUM],
      title: t(`fee.bestForMost`),
      selectionPercent: feeTierData[FeeAmount.MEDIUM]?.percentage,
      tvl: feeTierData[FeeAmount.MEDIUM]?.tvl
    },
    {
      tier: FeeAmount.HIGH,
      value: defaultFeeTiersForChain[FeeAmount.HIGH],
      title: t(`fee.bestForExotic`),
      selectionPercent: feeTierData[FeeAmount.HIGH]?.percentage,
      tvl: feeTierData[FeeAmount.HIGH]?.tvl
    }
  ];
  return feeTiers.filter(
    (feeTier) => feeTier.value !== void 0 && Object.keys(feeTierData).includes(feeTier.tier.toString())
  );
}
export function isDynamicFeeTier(feeData) {
  return feeData.feeAmount === DYNAMIC_FEE_DATA.feeAmount;
}
export function isDynamicFeeTierAmount(feeAmount) {
  if (!feeAmount) {
    return false;
  }
  const feeAmountNumber = Number(feeAmount);
  if (isNaN(feeAmountNumber)) {
    return false;
  }
  return feeAmountNumber === DYNAMIC_FEE_DATA.feeAmount;
}
