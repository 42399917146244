"use strict";
import styled from "lib/styled-components";
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${({ gap, theme }) => gap && theme.grids[gap] || gap};
  ${({ flex }) => flex && `flex: ${flex};`}
`;
export const ColumnCenter = styled(Column)`
  width: 100%;
  align-items: center;
`;
export const AutoColumn = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: ${({ gap, theme }) => gap && theme.grids[gap] || gap};
  justify-items: ${({ justify }) => justify && justify};
  flex-grow: ${({ grow }) => grow && 1};
`;
export default Column;
