import { MethodKind } from "@bufbuild/protobuf";
import { ProxyRequest, ProxyResponse } from "./api_pb.ts";
export const proxy = {
  localName: "proxy",
  name: "Proxy",
  kind: MethodKind.Unary,
  I: ProxyRequest,
  O: ProxyResponse,
  service: {
    typeName: "conversionproxy.v1.ConversionProxyService"
  }
};
