import { Message, proto3 } from "@bufbuild/protobuf";
export class Header extends Message {
  /**
   * @generated from field: string key = 1;
   */
  key = "";
  /**
   * @generated from field: string value = 2;
   */
  value = "";
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static runtime = proto3;
  static typeName = "conversionproxy.v1.Header";
  static fields = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "key",
      kind: "scalar",
      T: 9
      /* ScalarType.STRING */
    },
    {
      no: 2,
      name: "value",
      kind: "scalar",
      T: 9
      /* ScalarType.STRING */
    }
  ]);
  static fromBinary(bytes, options) {
    return new Header().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Header().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Header().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Header, a, b);
  }
}
export class ProxyRequest extends Message {
  /**
   * @generated from field: string requestType = 1;
   */
  requestType = "";
  /**
   * @generated from field: string identifier = 2;
   */
  identifier = "";
  /**
   * @generated from field: string to = 3;
   */
  to = "";
  /**
   * @generated from field: string method = 4;
   */
  method = "";
  /**
   * @generated from field: optional string body = 5;
   */
  body;
  /**
   * @generated from field: repeated conversionproxy.v1.Header headers = 6;
   */
  headers = [];
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static runtime = proto3;
  static typeName = "conversionproxy.v1.ProxyRequest";
  static fields = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "requestType",
      kind: "scalar",
      T: 9
      /* ScalarType.STRING */
    },
    {
      no: 2,
      name: "identifier",
      kind: "scalar",
      T: 9
      /* ScalarType.STRING */
    },
    {
      no: 3,
      name: "to",
      kind: "scalar",
      T: 9
      /* ScalarType.STRING */
    },
    {
      no: 4,
      name: "method",
      kind: "scalar",
      T: 9
      /* ScalarType.STRING */
    },
    { no: 5, name: "body", kind: "scalar", T: 9, opt: true },
    { no: 6, name: "headers", kind: "message", T: Header, repeated: true }
  ]);
  static fromBinary(bytes, options) {
    return new ProxyRequest().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ProxyRequest().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ProxyRequest().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ProxyRequest, a, b);
  }
}
export class ProxyResponse extends Message {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;
  /**
   * @generated from field: string statusText = 2;
   */
  statusText = "";
  /**
   * @generated from field: string body = 3;
   */
  body = "";
  /**
   * @generated from field: repeated conversionproxy.v1.Header headers = 4;
   */
  headers = [];
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static runtime = proto3;
  static typeName = "conversionproxy.v1.ProxyResponse";
  static fields = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: "status",
      kind: "scalar",
      T: 5
      /* ScalarType.INT32 */
    },
    {
      no: 2,
      name: "statusText",
      kind: "scalar",
      T: 9
      /* ScalarType.STRING */
    },
    {
      no: 3,
      name: "body",
      kind: "scalar",
      T: 9
      /* ScalarType.STRING */
    },
    { no: 4, name: "headers", kind: "message", T: Header, repeated: true }
  ]);
  static fromBinary(bytes, options) {
    return new ProxyResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ProxyResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ProxyResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ProxyResponse, a, b);
  }
}
