import { G, Path, Svg } from "react-native-svg";
import { createIcon } from "../factories/createIcon";
export const [Bank, AnimatedBank] = createIcon({
  name: "Bank",
  getIcon: (props) => <Svg viewBox="0 0 24 24" fill="none" {...props}><G id="bank"><Path
    id="Union"
    d="M2.5 6.76393C2.5 6.38516 2.714 6.0389 3.05279 5.8695L10.6584 2.06672C11.5029 1.64443 12.4971 1.64443 13.3416 2.06672L20.9472 5.8695C21.286 6.0389 21.5 6.38516 21.5 6.76393V7.9959C21.5 8.00435 21.4999 8.01278 21.4997 8.02118C21.4999 8.02927 21.5 8.03739 21.5 8.04553C21.5 8.5702 21.0747 8.99553 20.55 8.99553H20.5274C20.5183 8.99577 20.5092 8.9959 20.5 8.9959H3.5C3.49083 8.9959 3.48169 8.99577 3.47258 8.99553H3.45C2.92533 8.99553 2.5 8.5702 2.5 8.04553C2.5 8.03734 2.5001 8.02917 2.50031 8.02103C2.5001 8.01268 2.5 8.0043 2.5 7.9959V6.76393ZM4.45003 17.5455C3.89774 17.5455 3.45003 17.9932 3.45003 18.5455V20.3455C3.45003 20.8978 3.89774 21.3455 4.45003 21.3455H19.55C20.1023 21.3455 20.55 20.8978 20.55 20.3455V18.5455C20.55 17.9932 20.1023 17.5455 19.55 17.5455H4.45003ZM6.34995 10.4205C5.79766 10.4205 5.34995 10.8682 5.34995 11.4205V15.1205C5.34995 15.6728 5.79766 16.1205 6.34995 16.1205H7.19995C7.75223 16.1205 8.19995 15.6728 8.19995 15.1205V11.4205C8.19995 10.8682 7.75223 10.4205 7.19995 10.4205H6.34995ZM10.575 11.4205C10.575 10.8682 11.0227 10.4205 11.575 10.4205H12.425C12.9773 10.4205 13.425 10.8682 13.425 11.4205V15.1205C13.425 15.6728 12.9773 16.1205 12.425 16.1205H11.575C11.0227 16.1205 10.575 15.6728 10.575 15.1205V11.4205ZM16.8 10.4205C16.2477 10.4205 15.8 10.8682 15.8 11.4205V15.1205C15.8 15.6728 16.2477 16.1205 16.8 16.1205H17.65C18.2023 16.1205 18.65 15.6728 18.65 15.1205V11.4205C18.65 10.8682 18.2023 10.4205 17.65 10.4205H16.8Z"
    fill={"currentColor"}
    fillRule="evenodd"
    clipRule="evenodd"
  /></G></Svg>,
  defaultFill: "#FC74FE"
});
