"use strict";
import { TickMath, nearestUsableTick } from "@uniswap/v3-sdk";
import { useMemo } from "react";
import { Bound } from "state/mint/v3/actions";
export default function useIsTickAtLimit(tickSpacing, tickLower, tickUpper) {
  return useMemo(
    () => ({
      [Bound.LOWER]: tickSpacing && tickLower ? tickLower === nearestUsableTick(TickMath.MIN_TICK, tickSpacing) : void 0,
      [Bound.UPPER]: tickSpacing && tickUpper ? tickUpper === nearestUsableTick(TickMath.MAX_TICK, tickSpacing) : void 0
    }),
    [tickSpacing, tickLower, tickUpper]
  );
}
