"use strict";
import { atomWithReset, useResetAtom, useUpdateAtom } from "jotai/utils";
import { ProtocolVersion } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
export const manualChainOutageAtom = atomWithReset(void 0);
export function useUpdateManualOutage({
  chainId,
  errorV3,
  errorV2
}) {
  const setManualOutage = useUpdateAtom(manualChainOutageAtom);
  const resetManualOutage = useResetAtom(manualChainOutageAtom);
  resetManualOutage();
  if (errorV3 && chainId) {
    setManualOutage({ chainId });
  }
  if (errorV2 && chainId) {
    setManualOutage({ chainId, version: ProtocolVersion.V2 });
  }
}
