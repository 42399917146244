/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * For **Classic** swaps, specify the amount to be used in the permit. `FULL` can be used to specify unlimited amount. `EXACT` can be used to specify exact swap amount. Defaults to `FULL`.
 */
export enum PermitAmount {
    FULL = 'FULL',
    EXACT = 'EXACT',
}
