import { XStack, styled } from "tamagui";
import { buttonStyledContext } from "ui/src/components/buttons/Button/constants";
import { getMaybeHexOrRGBColor } from "ui/src/components/buttons/Button/utils/getMaybeHexOrRGBColor";
import { getHoverCssFilter } from "ui/src/utils/colors";
const FOCUS_SCALE = 0.98;
const PRESS_SCALE = FOCUS_SCALE;
const defaultFocusVisibleStyle = {
  outlineColor: "$neutral3Hovered"
};
const brandedFocusVisibleStyle = {
  outlineColor: "$accent1Hovered"
};
const criticalFocusVisibleStyle = {
  outlineColor: "$statusCriticalHovered"
};
const COMMON_PRESS_STYLE = {
  scale: PRESS_SCALE
};
const withCommonPressStyle = (style) => ({
  ...COMMON_PRESS_STYLE,
  ...style
});
const CustomButtonFrameWithoutCustomProps = styled(XStack, {
  context: buttonStyledContext,
  name: "Button",
  tag: "button",
  group: "item",
  "$platform-web": {
    containerType: "normal"
  },
  animation: "fast",
  // TODO(WALL-6057): Ideally we'd like to animate everything; however, there's an issue when animating colors with alpha channels
  animateOnly: ["transform"],
  // instead of setting border: 0 when no border, make it 1px but transparent, so the size or alignment of a button won't change unexpectedly between variants
  borderWidth: 1,
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "$transparent",
  borderColor: "$borderColor",
  focusVisibleStyle: {
    outlineWidth: 1,
    outlineOffset: 2,
    outlineStyle: "solid"
  },
  cursor: "pointer",
  height: "auto",
  // `variants` is a Tamagui term that allows us to define variants for the component
  variants: {
    // adding emphasis as a variant gives `CustomButtonFrame` the the type inference it needs
    emphasis: {
      primary: {},
      secondary: {},
      tertiary: {},
      "text-only": {}
    },
    // By default, the button scales up and down in both directions, slightly more in the Y direction
    // The best strategy will depend on the Button' parent's styling
    // These presets are a good starting point; but, feel free to add more as needed!
    focusScaling: {
      default: {
        focusVisibleStyle: {
          scaleX: FOCUS_SCALE,
          scaleY: FOCUS_SCALE - 0.075
        }
      },
      equal: {
        focusVisibleStyle: {
          scaleX: FOCUS_SCALE,
          scaleY: FOCUS_SCALE
        }
      },
      // Scale is proportionate to the button's size
      // On Web, sometimes a smaller button needs to be scaled down more, to account for the `outlineWidth` and `outlineOffset`, so it won't extend beyond the button's bounds in its unfocused state
      // We could try to automatically detect this, but in reality it's based on a few different factors relating to the surrounding elements; so, we'll opt to manually apply this `focusScaling` variant as-needed
      "equal:smaller-button": {
        focusVisibleStyle: {
          scaleX: FOCUS_SCALE - 0.05,
          scaleY: FOCUS_SCALE - 0.05
        }
      },
      "more-x": {
        focusVisibleStyle: {
          scaleX: FOCUS_SCALE - 0.075,
          scaleY: FOCUS_SCALE
        }
      }
    },
    // `variant` refers to ButtonVariantProps['variant']
    variant: {
      // See tamagui docs on string, boolean, and number variants
      // https://arc.net/l/quote/lpoqmiea
      ":string": (untypedVariant, { props }) => {
        const variant = untypedVariant;
        const emphasis = (
          // @ts-expect-error we know emphasis will be ButtonEmphasis
          props.emphasis || "primary"
        );
        if (props.isDisabled) {
          return {
            backgroundColor: "$surface2"
          };
        }
        const hexOrRGBColorFromProps = getMaybeHexOrRGBColor(props.backgroundColor);
        const maybePrimaryColor = getMaybeHexOrRGBColor(props["primary-color"]);
        if (hexOrRGBColorFromProps) {
          const DARK_FILTER = getHoverCssFilter({ isDarkMode: true, differenceFrom1: 0.25 });
          const LIGHT_FILTER = getHoverCssFilter({ isDarkMode: true, differenceFrom1: 0.25 });
          return {
            borderColor: hexOrRGBColorFromProps,
            pressStyle: withCommonPressStyle({
              filter: DARK_FILTER
            }),
            "$theme-dark": {
              focusVisibleStyle: {
                filter: DARK_FILTER,
                outlineColor: maybePrimaryColor ?? hexOrRGBColorFromProps
              },
              hoverStyle: {
                filter: DARK_FILTER,
                borderColor: maybePrimaryColor
              },
              pressStyle: withCommonPressStyle({
                filter: DARK_FILTER
              })
            },
            "$theme-light": {
              focusVisibleStyle: {
                filter: LIGHT_FILTER,
                outlineColor: maybePrimaryColor ?? hexOrRGBColorFromProps
              },
              pressStyle: withCommonPressStyle({
                filter: LIGHT_FILTER
              }),
              hoverStyle: {
                borderColor: maybePrimaryColor ?? hexOrRGBColorFromProps,
                filter: LIGHT_FILTER
              }
            }
          };
        }
        if (variant === "branded") {
          if (emphasis === "tertiary") {
            return {
              borderColor: "$accent2",
              hoverStyle: {
                borderColor: "$accent2Hovered"
              },
              focusVisibleStyle: {
                backgroundColor: "$surface1",
                ...brandedFocusVisibleStyle
              },
              pressStyle: withCommonPressStyle({
                borderColor: "$accent2Hovered"
              })
            };
          }
          if (emphasis === "secondary") {
            return {
              backgroundColor: "$accent2",
              hoverStyle: {
                backgroundColor: "$accent2Hovered"
              },
              focusVisibleStyle: {
                backgroundColor: "$accent2Hovered",
                ...brandedFocusVisibleStyle
              },
              pressStyle: withCommonPressStyle({
                backgroundColor: "$accent2Hovered"
              })
            };
          }
          return {
            backgroundColor: "$accent1",
            hoverStyle: {
              backgroundColor: "$accent1Hovered"
            },
            focusVisibleStyle: {
              backgroundColor: "$accent1Hovered",
              ...brandedFocusVisibleStyle
            },
            pressStyle: withCommonPressStyle({
              backgroundColor: "$accent1Hovered"
            })
          };
        }
        if (variant === "critical") {
          if (emphasis === "tertiary") {
            return {
              borderColor: "$statusCritical2",
              hoverStyle: {
                borderColor: "$statusCritical2Hovered"
              },
              focusVisibleStyle: {
                backgroundColor: "$surface1",
                ...criticalFocusVisibleStyle
              },
              pressStyle: withCommonPressStyle({
                borderColor: "$statusCritical2Hovered"
              })
            };
          }
          if (emphasis === "text-only") {
            return {
              borderColor: "$transparent",
              focusVisibleStyle: criticalFocusVisibleStyle,
              pressStyle: withCommonPressStyle({
                borderColor: "$transparent"
              })
            };
          }
          if (emphasis === "secondary") {
            return {
              backgroundColor: "$statusCritical2",
              hoverStyle: {
                backgroundColor: "$statusCritical2Hovered"
              },
              focusVisibleStyle: {
                backgroundColor: "$statusCritical2Hovered",
                ...criticalFocusVisibleStyle
              },
              pressStyle: withCommonPressStyle({
                backgroundColor: "$statusCritical2Hovered"
              })
            };
          }
          return {
            backgroundColor: "$statusCritical",
            hoverStyle: {
              backgroundColor: "$statusCriticalHovered"
            },
            focusVisibleStyle: {
              backgroundColor: "$statusCriticalHovered",
              ...criticalFocusVisibleStyle
            },
            pressStyle: withCommonPressStyle({
              backgroundColor: "$statusCriticalHovered"
            })
          };
        }
        if (emphasis === "tertiary") {
          return {
            borderColor: "$surface3",
            hoverStyle: {
              borderColor: "$surface3Hovered"
            },
            focusVisibleStyle: {
              backgroundColor: "$surface1",
              ...defaultFocusVisibleStyle
            },
            pressStyle: withCommonPressStyle({
              borderColor: "$surface3Hovered"
            })
          };
        }
        if (emphasis === "text-only") {
          return {
            borderColor: "$transparent",
            focusVisibleStyle: defaultFocusVisibleStyle,
            pressStyle: withCommonPressStyle({
              borderColor: "$transparent"
            })
          };
        }
        if (emphasis === "secondary") {
          return {
            backgroundColor: "$surface3",
            hoverStyle: {
              backgroundColor: "$surface3Hovered"
            },
            focusVisibleStyle: {
              backgroundColor: "$surface3Hovered",
              ...defaultFocusVisibleStyle
            },
            pressStyle: withCommonPressStyle({
              backgroundColor: "$surface3Hovered"
            })
          };
        }
        return {
          backgroundColor: "$accent3",
          hoverStyle: {
            backgroundColor: "$accent3Hovered"
          },
          focusVisibleStyle: {
            backgroundColor: "$accent3Hovered",
            ...defaultFocusVisibleStyle
          },
          pressStyle: withCommonPressStyle({
            backgroundColor: "$accent3Hovered"
          })
        };
      }
    },
    iconPosition: {
      before: {
        flexDirection: "row"
      },
      after: {
        flexDirection: "row-reverse"
      }
    },
    size: {
      xxsmall: {
        px: "$spacing6",
        py: "$spacing4",
        borderRadius: "$rounded12",
        gap: "$spacing4"
      },
      xsmall: {
        px: "$spacing12",
        py: "$spacing8",
        borderRadius: "$rounded12",
        gap: "$spacing4"
      },
      small: {
        px: "$spacing12",
        py: "$spacing8",
        borderRadius: "$rounded12",
        gap: "$spacing8"
      },
      medium: {
        px: "$spacing16",
        py: "$spacing12",
        borderRadius: "$rounded16",
        gap: "$spacing8"
      },
      large: {
        px: "$spacing20",
        py: "$spacing16",
        borderRadius: "$rounded20",
        gap: "$spacing12"
      }
    },
    fill: {
      true: {
        alignSelf: "stretch",
        flex: 1,
        flexBasis: 0
      }
    },
    // TODO(WEB-6347): change variant name back to `disabled`
    isDisabled: {
      true: {
        pointerEvents: "none",
        userSelect: "none"
      }
    }
  },
  defaultVariants: {
    variant: "default",
    emphasis: "primary",
    focusScaling: "default",
    fill: true,
    size: "medium"
  }
});
export const CustomButtonFrame = CustomButtonFrameWithoutCustomProps;
