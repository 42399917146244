"use strict";
import localForage from "localforage";
import { createMigrate } from "redux-persist";
import { migration0 } from "state/migrations/0";
import { migration1 } from "state/migrations/1";
import { migration10 } from "state/migrations/10";
import { migration11 } from "state/migrations/11";
import { migration12 } from "state/migrations/12";
import { migration13 } from "state/migrations/13";
import { migration14 } from "state/migrations/14";
import { migration15 } from "state/migrations/15";
import { migration16 } from "state/migrations/16";
import { migration17 } from "state/migrations/17";
import { migration18 } from "state/migrations/18";
import { migration19 } from "state/migrations/19";
import { migration2 } from "state/migrations/2";
import { migration20 } from "state/migrations/20";
import { migration21 } from "state/migrations/21";
import { migration22 } from "state/migrations/22";
import { migration23 } from "state/migrations/23";
import { migration3 } from "state/migrations/3";
import { migration4 } from "state/migrations/4";
import { migration5 } from "state/migrations/5";
import { migration6 } from "state/migrations/6";
import { migration7 } from "state/migrations/7";
import { migration8 } from "state/migrations/8";
import { migration9 } from "state/migrations/9";
import { legacyLocalStorageMigration } from "state/migrations/legacy";
export const migrations = {
  0: migration0,
  1: migration1,
  2: migration2,
  3: migration3,
  4: migration4,
  5: migration5,
  6: migration6,
  7: migration7,
  8: migration8,
  9: migration9,
  10: migration10,
  11: migration11,
  12: migration12,
  13: migration13,
  14: migration14,
  15: migration15,
  16: migration16,
  17: migration17,
  18: migration18,
  19: migration19,
  20: migration20,
  21: migration21,
  22: migration22,
  23: migration23
};
export const PERSIST_VERSION = 23;
export const INDEXED_DB_REDUX_TABLE_NAME = "redux";
const dbInstance = localForage.createInstance({
  name: INDEXED_DB_REDUX_TABLE_NAME
});
export function customCreateMigrate(migrations2, options) {
  const defaultMigrate = createMigrate(migrations2, options);
  return async (state, currentVersion) => {
    if (state !== void 0) {
      return defaultMigrate(state, currentVersion);
    }
    const indexedDBState = await dbInstance.getItem("persist:interface");
    if (indexedDBState) {
      dbInstance.clear();
      return defaultMigrate(indexedDBState, currentVersion);
    }
    state = await legacyLocalStorageMigration();
    return defaultMigrate(state, currentVersion);
  };
}
