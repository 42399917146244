"use strict";
import { ProtocolVersion } from "@uniswap/client-pools/dist/pools/v1/types_pb";
import { V3_CORE_FACTORY_ADDRESSES } from "@uniswap/sdk-core";
import { Pool as V3Pool, tickToPrice as tickToPriceV3 } from "@uniswap/v3-sdk";
import { Pool as V4Pool, tickToPrice as tickToPriceV4 } from "@uniswap/v4-sdk";
import { ZERO_ADDRESS } from "constants/misc";
import JSBI from "jsbi";
import ms from "ms";
import { getCurrencyAddressWithWrap, poolEnabledProtocolVersion } from "pages/Pool/Positions/create/utils";
import { useEffect, useMemo, useState } from "react";
import { useMultichainContext } from "state/multichain/useMultichainContext";
import {
  useAllV3TicksQuery,
  useAllV4TicksQuery
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { useGetPoolsByTokens } from "uniswap/src/data/rest/getPools";
import { useEnabledChains } from "uniswap/src/features/chains/hooks/useEnabledChains";
import { useSupportedChainId } from "uniswap/src/features/chains/hooks/useSupportedChainId";
import { UniverseChainId } from "uniswap/src/features/chains/types";
import { toGraphQLChain } from "uniswap/src/features/chains/utils";
import { logger } from "utilities/src/logger/logger";
import computeSurroundingTicks from "utils/computeSurroundingTicks";
const PRICE_FIXED_DIGITS = 8;
const getActiveTick = (tickCurrent, feeAmount, tickSpacing) => tickCurrent && feeAmount && tickSpacing ? Math.floor(tickCurrent / tickSpacing) * tickSpacing : void 0;
const MAX_TICK_FETCH_VALUE = 1e3;
function usePaginatedTickQuery(poolId, version, skip = 0, chainId) {
  const { defaultChainId } = useEnabledChains();
  const supportedChainId = useSupportedChainId(chainId);
  const v3Result = useAllV3TicksQuery({
    variables: {
      address: poolId?.toLowerCase() ?? "",
      chain: toGraphQLChain(supportedChainId ?? defaultChainId),
      skip,
      first: MAX_TICK_FETCH_VALUE
    },
    skip: !poolId || version !== ProtocolVersion.V3,
    pollInterval: ms(`30s`)
  });
  const v4Result = useAllV4TicksQuery({
    variables: {
      poolId: poolId ?? "",
      chain: toGraphQLChain(supportedChainId ?? defaultChainId),
      skip,
      first: MAX_TICK_FETCH_VALUE
    },
    skip: !poolId || version !== ProtocolVersion.V4,
    pollInterval: ms(`30s`)
  });
  return useMemo(() => {
    if (version === ProtocolVersion.V3) {
      return v3Result;
    } else if (version === ProtocolVersion.V4) {
      return v4Result;
    }
    return {
      data: void 0,
      error: new Error("Invalid version"),
      loading: false
    };
  }, [v3Result, v4Result, version]);
}
function useAllPoolTicks({
  currencyA,
  currencyB,
  feeAmount,
  chainId,
  version,
  tickSpacing,
  hooks,
  precalculatedPoolId
}) {
  const [skipNumber, setSkipNumber] = useState(0);
  const [tickData, setTickData] = useState([]);
  const poolId = useMemo(() => {
    if (precalculatedPoolId) {
      return precalculatedPoolId;
    }
    const v3PoolAddress = currencyA && currencyB && feeAmount && version === ProtocolVersion.V3 ? V3Pool.getAddress(
      currencyA?.wrapped,
      currencyB?.wrapped,
      feeAmount,
      void 0,
      chainId ? V3_CORE_FACTORY_ADDRESSES[chainId] : void 0
    ) : void 0;
    const v4PoolId = version === ProtocolVersion.V4 && currencyA && currencyB && feeAmount && tickSpacing && hooks ? V4Pool.getPoolId(currencyA, currencyB, feeAmount, tickSpacing, hooks) : void 0;
    return version === ProtocolVersion.V3 ? v3PoolAddress : v4PoolId;
  }, [chainId, currencyA, currencyB, feeAmount, hooks, precalculatedPoolId, tickSpacing, version]);
  const { data, error, loading: isLoading } = usePaginatedTickQuery(poolId, version, skipNumber, chainId);
  const ticks = data?.v3Pool?.ticks ?? data?.v4Pool?.ticks;
  useEffect(() => {
    if (ticks?.length) {
      setTickData((tickData2) => [...tickData2, ...ticks]);
      if (ticks?.length === MAX_TICK_FETCH_VALUE) {
        setSkipNumber((skipNumber2) => skipNumber2 + MAX_TICK_FETCH_VALUE);
      }
    }
  }, [ticks]);
  return {
    isLoading: isLoading || ticks?.length === MAX_TICK_FETCH_VALUE,
    error,
    ticks: tickData
  };
}
export function usePoolActiveLiquidity({
  currencyA,
  currencyB,
  feeAmount,
  chainId,
  version,
  tickSpacing,
  hooks,
  poolId,
  skip
}) {
  const multichainContext = useMultichainContext();
  const defaultChainId = multichainContext.chainId ?? UniverseChainId.Mainnet;
  const poolsQueryEnabled = Boolean(poolEnabledProtocolVersion(version) && currencyA && currencyB && !skip);
  const { data: poolData, isLoading: poolIsLoading } = useGetPoolsByTokens(
    {
      fee: feeAmount,
      chainId: chainId ?? defaultChainId,
      protocolVersions: [version],
      token0: getCurrencyAddressWithWrap(currencyA, version),
      token1: getCurrencyAddressWithWrap(currencyB, version),
      hooks: hooks ?? ZERO_ADDRESS
    },
    poolsQueryEnabled
  );
  const pool = poolData?.pools && poolData.pools.length > 0 ? poolData.pools[0] : void 0;
  const liquidity = pool?.liquidity;
  const sqrtPriceX96 = pool?.sqrtPriceX96;
  const currentTick = pool?.tick;
  const activeTick = useMemo(
    () => getActiveTick(currentTick, feeAmount, tickSpacing),
    [currentTick, feeAmount, tickSpacing]
  );
  const { isLoading, error, ticks } = useAllPoolTicks({
    currencyA,
    currencyB,
    feeAmount,
    precalculatedPoolId: poolId,
    chainId: chainId ?? defaultChainId,
    version: version ?? ProtocolVersion.V3,
    tickSpacing,
    hooks
  });
  return useMemo(() => {
    if (!currencyA || !currencyB || activeTick === void 0 || !pool || !ticks || ticks.length === 0 || isLoading) {
      return {
        isLoading: isLoading || poolIsLoading,
        error,
        activeTick,
        data: void 0
      };
    }
    const token0 = version === ProtocolVersion.V3 ? currencyA?.wrapped : currencyA;
    const token1 = version === ProtocolVersion.V3 ? currencyB?.wrapped : currencyB;
    const pivot = ticks.findIndex((tickData) => tickData?.tick && tickData.tick > activeTick) - 1;
    if (pivot < 0) {
      logger.debug("usePoolTickData", "usePoolActiveLiquidity", "TickData pivot not found", {
        token0: token0.isToken ? token0.address : ZERO_ADDRESS,
        token1: token1.isToken ? token1.address : ZERO_ADDRESS,
        chainId: token0.chainId
      });
      return {
        isLoading,
        error,
        activeTick,
        data: void 0
      };
    }
    let sdkPrice;
    try {
      sdkPrice = version === ProtocolVersion.V3 ? tickToPriceV3(token0, token1, activeTick) : tickToPriceV4(token0, token1, activeTick);
    } catch (e) {
      logger.debug("usePoolTickData", "usePoolActiveLiquidity", "Error getting price", {
        error: e,
        token0: token0.isToken ? token0.address : ZERO_ADDRESS,
        token1: token1.isToken ? token1.address : ZERO_ADDRESS,
        chainId: token0.chainId
      });
      return {
        isLoading,
        error,
        activeTick,
        data: void 0
      };
    }
    const activeTickProcessed = {
      liquidityActive: JSBI.BigInt(pool?.liquidity ?? 0),
      tick: activeTick,
      liquidityNet: Number(ticks[pivot]?.tick) === activeTick ? JSBI.BigInt(ticks[pivot]?.liquidityNet ?? 0) : JSBI.BigInt(0),
      price0: sdkPrice.toFixed(PRICE_FIXED_DIGITS),
      sdkPrice
    };
    const subsequentTicks = computeSurroundingTicks(token0, token1, activeTickProcessed, ticks, pivot, true, version);
    const previousTicks = computeSurroundingTicks(token0, token1, activeTickProcessed, ticks, pivot, false, version);
    const ticksProcessed = previousTicks.concat(activeTickProcessed).concat(subsequentTicks);
    return {
      isLoading,
      error,
      currentTick,
      activeTick,
      liquidity: JSBI.BigInt(liquidity ?? 0),
      sqrtPriceX96: JSBI.BigInt(sqrtPriceX96 ?? 0),
      data: ticksProcessed
    };
  }, [
    currencyA,
    currencyB,
    activeTick,
    pool,
    ticks,
    isLoading,
    version,
    error,
    currentTick,
    liquidity,
    sqrtPriceX96,
    poolIsLoading
  ]);
}
