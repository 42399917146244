import { useCallback, useEffect, useRef, useState } from "react";
export const DEFAULT_DELAY = 200;
export function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(() => resolve(true), milliseconds));
}
export async function promiseTimeout(promise, milliseconds) {
  const timeout = new Promise((resolve) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      resolve(null);
    }, milliseconds);
  });
  const result = await Promise.race([promise, timeout]);
  return result;
}
export async function promiseMinDelay(promise, milliseconds) {
  const minDelay = new Promise((resolve) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      resolve(null);
    }, milliseconds);
  });
  const [result] = await Promise.all([promise, minDelay]);
  return result;
}
export function useInterval(callback, delay, immediateStart) {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const tick = () => {
      if (typeof savedCallback?.current !== "undefined") {
        savedCallback?.current();
      }
    };
    if (delay !== null) {
      if (immediateStart) {
        tick();
      }
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return void 0;
  }, [delay, immediateStart]);
}
export const useTimeout = (callback, delay = 0) => {
  const timeoutIdRef = useRef();
  const cancel = useCallback(() => {
    const timeoutId = timeoutIdRef.current;
    if (timeoutId) {
      timeoutIdRef.current = void 0;
      clearTimeout(timeoutId);
    }
  }, [timeoutIdRef]);
  useEffect(() => {
    if (delay >= 0) {
      timeoutIdRef.current = setTimeout(callback, delay);
    }
    return cancel;
  }, [callback, delay, cancel]);
  return cancel;
};
export function useDebounce(value, delay = DEFAULT_DELAY) {
  const [debouncedValue] = useDebounceWithStatus({ value, delay });
  return debouncedValue;
}
export function useDebounceWithStatus({
  value,
  delay = DEFAULT_DELAY,
  skipDebounce = false
}) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isDebouncing, setIsDebouncing] = useState(false);
  useEffect(() => {
    if (skipDebounce) {
      setDebouncedValue(value);
      return;
    }
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setIsDebouncing(false);
    }, delay);
    setIsDebouncing(true);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, skipDebounce]);
  if (skipDebounce) {
    return [value, false];
  }
  return [debouncedValue, isDebouncing];
}
export function debounceCallback(func, wait) {
  let timeout;
  const cancelDebounce = () => {
    clearTimeout(timeout);
  };
  return {
    triggerDebounce: () => {
      clearTimeout(timeout);
      timeout = setTimeout(func, wait);
    },
    cancelDebounce
  };
}
