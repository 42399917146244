"use strict";
import { useConnectorWithId } from "components/WalletModal/useOrderedConnections";
import { ThemeProvider as StyledComponentsThemeProvider, createGlobalStyle, css } from "lib/styled-components";
import { useEffect, useMemo } from "react";
import { darkTheme, lightTheme } from "theme/colors";
import { useIsDarkMode } from "theme/components/ThemeToggle";
import { darkDeprecatedTheme, lightDeprecatedTheme } from "theme/deprecatedColors";
import { getAccent2, getNeutralContrast } from "theme/utils";
import { breakpoints } from "ui/src/theme";
import { CONNECTION_PROVIDER_IDS } from "uniswap/src/constants/web3";
const MEDIA_WIDTHS = {
  deprecated_upToExtraSmall: 500,
  deprecated_upToSmall: 720,
  deprecated_upToMedium: 960,
  deprecated_upToLarge: 1280
};
const MAX_CONTENT_WIDTH_PX = 1200;
const deprecated_mediaWidthTemplates = Object.keys(
  MEDIA_WIDTHS
).reduce((acc, size) => {
  acc[size] = (a, b, c) => css`
    @media (max-width: ${MEDIA_WIDTHS[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return acc;
}, {});
const transitions = {
  duration: {
    slow: "500ms",
    medium: "250ms",
    fast: "125ms"
  },
  timing: {
    ease: "ease",
    in: "ease-in",
    out: "ease-out",
    inOut: "ease-in-out"
  }
};
const opacities = {
  hover: 0.6,
  click: 0.4,
  disabled: 0.5,
  enabled: 1
};
const blurs = {
  light: "blur(12px)"
};
const fonts = {
  code: "courier, courier new, serif"
};
const gapValues = {
  xs: "4px",
  sm: "8px",
  md: "12px",
  lg: "24px",
  xl: "32px"
};
function getSettings(darkMode) {
  return {
    darkMode,
    grids: gapValues,
    fonts,
    // shadows
    shadow1: darkMode ? "#000" : "#2F80ED",
    // media queries
    deprecated_mediaWidth: deprecated_mediaWidthTemplates,
    mobileBottomBarHeight: 48,
    maxWidth: MAX_CONTENT_WIDTH_PX,
    // deprecated - please use hardcoded exported values instead of
    // adding to the theme object
    breakpoint: breakpoints,
    transition: transitions,
    blur: blurs,
    opacity: opacities,
    text: {
      heading: {
        fontFamily: "inherit",
        fontWeight: 485
      }
    }
  };
}
export function getTheme(darkMode, overriddenColors) {
  const [colors, deprecatedColors] = darkMode ? [darkTheme, darkDeprecatedTheme] : [lightTheme, lightDeprecatedTheme];
  const colorsWithOverrides = applyOverriddenColors(colors, overriddenColors);
  return { ...colorsWithOverrides, ...deprecatedColors, ...getSettings(darkMode) };
}
function applyOverriddenColors(defaultColors, overriddenColors) {
  if (!overriddenColors) {
    return defaultColors;
  }
  const definedOverriddenColors = Object.keys(overriddenColors).reduce((acc, curr) => {
    const key = curr;
    if (overriddenColors[key] !== void 0) {
      acc[key] = overriddenColors[key];
    }
    return acc;
  }, {});
  const mergedColors = { ...defaultColors, ...definedOverriddenColors };
  if ((overriddenColors.accent1 || overriddenColors.surface1) && !overriddenColors.accent2) {
    mergedColors.accent2 = getAccent2(mergedColors.accent1, mergedColors.surface1);
  }
  if (overriddenColors.accent1 && !overriddenColors.neutralContrast) {
    mergedColors.neutralContrast = getNeutralContrast(mergedColors.accent1);
  }
  return mergedColors;
}
export function ThemeProvider({ children, ...overriddenColors }) {
  const darkMode = useIsDarkMode();
  const themeObject = useMemo(() => getTheme(darkMode, overriddenColors), [darkMode, JSON.stringify(overriddenColors)]);
  const walletConnectConnector = useConnectorWithId(CONNECTION_PROVIDER_IDS.WALLET_CONNECT_CONNECTOR_ID, {
    shouldThrow: true
  });
  useEffect(() => {
    walletConnectConnector.getProvider().then((p) => p.modal.setTheme({ themeMode: darkMode ? "dark" : "light" }));
  }, [darkMode, walletConnectConnector]);
  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>;
}
export const ThemedGlobalStyle = createGlobalStyle`
  html {
    color: ${({ theme }) => theme.neutral1};
    background-color: ${({ theme }) => theme.background} !important;
  }

 summary::-webkit-details-marker {
    display:none;
  }

  a {
    color: ${({ theme }) => theme.accent1}; 
  }
`;
