"use strict";
import { toast } from "sonner";
class PopupRegistry {
  listener;
  popupKeyToId = /* @__PURE__ */ new Map();
  addPopup(content, key, removeAfterMs) {
    if (this.popupKeyToId.has(key)) {
      return;
    }
    const toastId = this.listener?.(content, key, removeAfterMs);
    if (toastId) {
      this.popupKeyToId.set(key, toastId);
    }
  }
  addListener(listener) {
    this.listener = listener;
    return () => this.listener = void 0;
  }
  removePopup(key) {
    this.popupKeyToId.delete(key);
    toast.dismiss(this.popupKeyToId.get(key));
  }
}
export const popupRegistry = new PopupRegistry();
