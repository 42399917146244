export var PlatformIdType = /* @__PURE__ */ ((PlatformIdType2) => {
  PlatformIdType2["Google"] = "gclid";
  PlatformIdType2["Twitter"] = "twclid";
  PlatformIdType2["Reddit"] = "rdt_cid";
  PlatformIdType2["Persona3"] = "prsna_id";
  return PlatformIdType2;
})(PlatformIdType || {});
export var RequestType = /* @__PURE__ */ ((RequestType2) => {
  RequestType2["POST"] = "POST";
  return RequestType2;
})(RequestType || {});
