"use strict";
import { Interface } from "@ethersproject/abi";
import { CurrencyAmount, V2_FACTORY_ADDRESSES } from "@uniswap/sdk-core";
import IUniswapV2PairJSON from "@uniswap/v2-core/build/IUniswapV2Pair.json";
import { Pair, computePairAddress } from "@uniswap/v2-sdk";
import { useMultipleContractSingleData } from "lib/hooks/multicall";
import { useMemo } from "react";
const PAIR_INTERFACE = new Interface(IUniswapV2PairJSON.abi);
export var PairState = /* @__PURE__ */ ((PairState2) => {
  PairState2[PairState2["LOADING"] = 0] = "LOADING";
  PairState2[PairState2["NOT_EXISTS"] = 1] = "NOT_EXISTS";
  PairState2[PairState2["EXISTS"] = 2] = "EXISTS";
  PairState2[PairState2["INVALID"] = 3] = "INVALID";
  return PairState2;
})(PairState || {});
export function useV2Pairs(currencies) {
  const tokens = useMemo(
    () => currencies.map(([currencyA, currencyB]) => [currencyA?.wrapped, currencyB?.wrapped]),
    [currencies]
  );
  const pairAddresses = useMemo(
    () => tokens.map(([tokenA, tokenB]) => {
      return tokenA && tokenB && tokenA.chainId === tokenB.chainId && !tokenA.equals(tokenB) && V2_FACTORY_ADDRESSES[tokenA.chainId] ? computePairAddress({ factoryAddress: V2_FACTORY_ADDRESSES[tokenA.chainId], tokenA, tokenB }) : void 0;
    }),
    [tokens]
  );
  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, "getReserves");
  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result;
      const tokenA = tokens[i][0];
      const tokenB = tokens[i][1];
      if (loading) {
        return [0 /* LOADING */, null];
      }
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) {
        return [3 /* INVALID */, null];
      }
      if (!reserves) {
        return [1 /* NOT_EXISTS */, null];
      }
      const { reserve0, reserve1 } = reserves;
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA];
      return [
        2 /* EXISTS */,
        new Pair(
          CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
          CurrencyAmount.fromRawAmount(token1, reserve1.toString())
        )
      ];
    });
  }, [results, tokens]);
}
export function useV2Pair(tokenA, tokenB) {
  const inputs = useMemo(() => [[tokenA, tokenB]], [tokenA, tokenB]);
  return useV2Pairs(inputs)[0];
}
