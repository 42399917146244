import JSBI from "jsbi";
import { logger } from "utilities/src/logger/logger";
export function convertScientificNotationToNumber(value) {
  let convertedValue = value;
  if ((value.includes("e") || value.includes("E")) && !value.startsWith("0x")) {
    const [xStr, eStr] = value.split(/[eE]/);
    if (!xStr || !eStr || isNaN(Number(xStr)) || isNaN(Number(eStr))) {
      return value;
    }
    let x = Number(xStr);
    let e = Number(eStr);
    if (e < 0) {
      const isNegative = x < 0;
      const xDigits = Math.abs(x).toString().replace(".", "");
      const zeros = "0".repeat(-e - 1);
      convertedValue = `${isNegative ? "-" : ""}0.${zeros}${xDigits}`;
    } else {
      if (xStr.includes(".")) {
        const splitX = xStr.split(".");
        const decimalPlaces = splitX[1]?.split("").length ?? 0;
        e -= decimalPlaces;
        x *= Math.pow(10, decimalPlaces);
      }
      try {
        convertedValue = JSBI.multiply(JSBI.BigInt(x), JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(e))).toString();
      } catch (error) {
        logger.debug(
          "convertScientificNotation",
          "convertScientificNotationToNumber",
          "BigInt arithmetic unsuccessful",
          e
        );
        convertedValue = (x * Math.pow(10, e)).toString();
      }
    }
  }
  return convertedValue;
}
