"use strict";
import { createContext, useContext, useState } from "react";
import { useDerivedLimitInfo } from "state/limit/hooks";
import { LimitsExpiry } from "uniswap/src/types/limits";
const DEFAULT_LIMIT_STATE = {
  inputAmount: "",
  limitPrice: "",
  limitPriceEdited: false,
  limitPriceInverted: false,
  outputAmount: "",
  expiry: LimitsExpiry.Week,
  isInputAmountFixed: true
};
export const LimitContext = createContext({
  limitState: DEFAULT_LIMIT_STATE,
  setLimitState: () => void 0,
  derivedLimitInfo: {
    currencyBalances: {},
    parsedAmounts: {}
  }
});
export function useLimitContext() {
  return useContext(LimitContext);
}
export function LimitContextProvider({ children }) {
  const [limitState, setLimitState] = useState(DEFAULT_LIMIT_STATE);
  const derivedLimitInfo = useDerivedLimitInfo(limitState);
  return <LimitContext.Provider value={{ limitState, setLimitState, derivedLimitInfo }}>{children}</LimitContext.Provider>;
}
