"use strict";
import { CurrencyAmount } from "@uniswap/sdk-core";
import { useMemo } from "react";
import { assume0xAddress } from "utils/wagmi";
import { erc20Abi } from "viem";
import { useReadContract } from "wagmi";
export function useTotalSupply(token) {
  const address = token?.isToken ? assume0xAddress(token.address) : void 0;
  const { data } = useReadContract({ address, chainId: token?.chainId, abi: erc20Abi, functionName: "totalSupply" });
  return useMemo(
    () => token?.isToken && data ? CurrencyAmount.fromRawAmount(token, data?.toString()) : void 0,
    [token, data]
  );
}
