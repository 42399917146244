"use strict";
import {
  LiquidityBarSeriesRenderer
} from "components/Charts/LiquidityChart/renderer";
import {
  customSeriesDefaultOptions
} from "lightweight-charts";
export class LiquidityBarSeries {
  _renderer;
  _tokenAColor;
  _tokenBColor;
  _highlightColor;
  constructor(props) {
    this._tokenAColor = props.tokenAColor;
    this._renderer = new LiquidityBarSeriesRenderer(props);
    this._tokenBColor = props.tokenBColor;
    this._highlightColor = props.highlightColor;
  }
  priceValueBuilder(plotRow) {
    return [0, plotRow.liquidity];
  }
  isWhitespace(data) {
    return !data.liquidity;
  }
  renderer() {
    return this._renderer;
  }
  update(data, options) {
    this._renderer.update(data, options);
  }
  defaultOptions() {
    return {
      ...customSeriesDefaultOptions,
      tokenAColor: this._tokenAColor,
      tokenBColor: this._tokenBColor,
      highlightColor: this._highlightColor
    };
  }
}
