"use strict";
import { CurrencyAmount, Price, TradeType } from "@uniswap/sdk-core";
import { useAccount } from "hooks/useAccount";
import JSBI from "jsbi";
import { useCurrencyBalances } from "lib/hooks/useCurrencyBalance";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { useEffect, useMemo, useState } from "react";
import { expiryToDeadlineSeconds } from "state/limit/expiryToDeadlineSeconds";
import { getWrapInfo } from "state/routing/gas";
import { LimitOrderTrade, RouterPreference } from "state/routing/types";
import { useRoutingAPITrade } from "state/routing/useRoutingAPITrade";
import { getUSDCostPerGas, isClassicTrade } from "state/routing/utils";
import { useSwapAndLimitContext } from "state/swap/useSwapContext";
import { nativeOnChain } from "uniswap/src/constants/tokens";
import { getChainInfo } from "uniswap/src/features/chains/chainInfo";
import { isUniverseChainId } from "uniswap/src/features/chains/types";
import { FeatureFlags } from "uniswap/src/features/gating/flags";
import { useFeatureFlag } from "uniswap/src/features/gating/hooks";
import { CurrencyField } from "uniswap/src/types/currency";
function isStablecoin(currency) {
  return currency !== void 0 && isUniverseChainId(currency.chainId) && getChainInfo(currency.chainId).stablecoins.some((stablecoin) => stablecoin.equals(currency));
}
export function getDefaultPriceInverted(inputCurrency, outputCurrency) {
  const [isInputStablecoin, isOutputStablecoin] = [isStablecoin(inputCurrency), isStablecoin(outputCurrency)];
  return isInputStablecoin && !isOutputStablecoin;
}
export function useDerivedLimitInfo(state) {
  const account = useAccount();
  const { inputAmount, outputAmount, limitPriceInverted } = state;
  const {
    currencyState: { inputCurrency, outputCurrency }
  } = useSwapAndLimitContext();
  const relevantTokenBalances = useCurrencyBalances(
    account.address,
    useMemo(() => [inputCurrency ?? void 0, outputCurrency ?? void 0], [inputCurrency, outputCurrency])
  );
  const currencyBalances = useMemo(
    () => ({
      [CurrencyField.INPUT]: relevantTokenBalances[0],
      [CurrencyField.OUTPUT]: relevantTokenBalances[1]
    }),
    [relevantTokenBalances]
  );
  const parsedLimitPrice = useMemo(() => {
    if (!inputCurrency || !outputCurrency || !state.limitPrice) {
      return void 0;
    }
    const [baseCurrency, quoteCurrency] = limitPriceInverted ? [outputCurrency, inputCurrency] : [inputCurrency, outputCurrency];
    const parsedLimitPriceQuoteAmount = tryParseCurrencyAmount(state.limitPrice, quoteCurrency);
    if (!parsedLimitPriceQuoteAmount) {
      return void 0;
    }
    return new Price(
      baseCurrency,
      quoteCurrency,
      JSBI.BigInt(10 ** baseCurrency.decimals),
      parsedLimitPriceQuoteAmount.quotient
    );
  }, [inputCurrency, limitPriceInverted, outputCurrency, state.limitPrice]);
  const parsedAmounts = useMemo(() => {
    let parsedInputAmount;
    let parsedOutputAmount;
    const limitPrice = limitPriceInverted ? parsedLimitPrice?.invert() : parsedLimitPrice;
    if (state.isInputAmountFixed) {
      parsedInputAmount = tryParseCurrencyAmount(inputAmount, inputCurrency);
      parsedOutputAmount = !limitPrice ? tryParseCurrencyAmount(outputAmount, outputCurrency) : parsedInputAmount && limitPrice.quote(parsedInputAmount);
    } else {
      parsedOutputAmount = tryParseCurrencyAmount(outputAmount, outputCurrency);
      parsedInputAmount = !limitPrice ? tryParseCurrencyAmount(inputAmount, inputCurrency) : parsedOutputAmount && limitPrice.invert().quote(parsedOutputAmount);
    }
    return {
      [CurrencyField.INPUT]: parsedInputAmount,
      [CurrencyField.OUTPUT]: parsedOutputAmount
    };
  }, [
    inputAmount,
    inputCurrency,
    limitPriceInverted,
    outputAmount,
    outputCurrency,
    parsedLimitPrice,
    state.isInputAmountFixed
  ]);
  const { marketPrice, fee: swapFee } = useMarketPriceAndFee(inputCurrency, outputCurrency);
  const skip = !(inputCurrency && outputCurrency);
  const { trade } = useRoutingAPITrade(
    skip,
    TradeType.EXACT_INPUT,
    parsedAmounts?.[CurrencyField.INPUT],
    outputCurrency,
    RouterPreference.API
  );
  const limitOrderTrade = useLimitOrderTrade({
    inputCurrency,
    parsedAmounts,
    outputAmount: parsedAmounts[CurrencyField.OUTPUT],
    trade,
    state,
    swapFee
  });
  return {
    currencyBalances,
    parsedAmounts,
    parsedLimitPrice,
    limitOrderTrade,
    marketPrice
  };
}
function useLimitOrderTrade({
  state,
  trade,
  inputCurrency,
  parsedAmounts,
  outputAmount,
  swapFee
}) {
  const account = useAccount();
  const [wrapInfo, setWrapInfo] = useState();
  useEffect(() => {
    async function calculateWrapInfo() {
      if (!inputCurrency) {
        setWrapInfo(void 0);
        return;
      }
      const [currencyIn, needsWrap] = inputCurrency.isNative ? [inputCurrency.wrapped, true] : [inputCurrency, false];
      const [gasUseEstimate, gasUseEstimateUSD] = isClassicTrade(trade) ? [trade.gasUseEstimate, trade.gasUseEstimateUSD] : [void 0, void 0];
      const usdCostPerGas = getUSDCostPerGas(gasUseEstimateUSD, gasUseEstimate);
      if (needsWrap) {
        const wrapInfo2 = await getWrapInfo(needsWrap, account.address, currencyIn.chainId, "1", usdCostPerGas);
        setWrapInfo(wrapInfo2);
      } else {
        setWrapInfo({ needsWrap: false });
      }
    }
    calculateWrapInfo();
  }, [account.address, inputCurrency, trade]);
  const limitOrderTrade = useMemo(() => {
    if (!inputCurrency || !parsedAmounts?.[CurrencyField.INPUT] || !account.address || !outputAmount || !wrapInfo) {
      return void 0;
    }
    const amountIn = CurrencyAmount.fromRawAmount(inputCurrency.wrapped, parsedAmounts?.[CurrencyField.INPUT].quotient);
    return new LimitOrderTrade({
      amountIn,
      amountOut: outputAmount,
      tradeType: TradeType.EXACT_INPUT,
      wrapInfo,
      approveInfo: { needsApprove: false },
      swapper: account.address,
      deadlineBufferSecs: expiryToDeadlineSeconds(state.expiry),
      swapFee
    });
  }, [account.address, outputAmount, inputCurrency, parsedAmounts, state.expiry, wrapInfo, swapFee]);
  return limitOrderTrade;
}
function isNativeOrWrappedNative(currency) {
  return currency.isNative || nativeOnChain(currency.chainId).wrapped.equals(currency);
}
function useMarketPriceAndFee(inputCurrency, outputCurrency) {
  const skip = !(inputCurrency && outputCurrency);
  const baseCurrencyAmount = inputCurrency && CurrencyAmount.fromRawAmount(nativeOnChain(inputCurrency.chainId), 10 ** 18);
  const { trade: tradeA } = useRoutingAPITrade(
    skip,
    TradeType.EXACT_OUTPUT,
    baseCurrencyAmount,
    inputCurrency,
    RouterPreference.API
  );
  const { trade: tradeB } = useRoutingAPITrade(
    skip,
    TradeType.EXACT_INPUT,
    baseCurrencyAmount,
    outputCurrency,
    RouterPreference.API
  );
  const marketPrice = useMemo(() => {
    if (skip) {
      return void 0;
    }
    if (isNativeOrWrappedNative(inputCurrency)) {
      if (!tradeB?.outputAmount.currency.equals(outputCurrency) || !isClassicTrade(tradeB)) {
        return void 0;
      }
      const referencePrice = tradeB.routes[0]?.midPrice;
      return new Price(inputCurrency, outputCurrency, referencePrice.denominator, referencePrice.numerator);
    }
    if (isNativeOrWrappedNative(outputCurrency)) {
      if (!tradeA?.inputAmount.currency.equals(inputCurrency) || !isClassicTrade(tradeA)) {
        return void 0;
      }
      const referencePrice = tradeA.routes[0]?.midPrice;
      return new Price(inputCurrency, outputCurrency, referencePrice.denominator, referencePrice.numerator);
    }
    if (!tradeA?.inputAmount.currency.equals(inputCurrency) || !tradeB?.outputAmount.currency.equals(outputCurrency)) {
      return void 0;
    }
    if (!tradeA || !tradeB || !isClassicTrade(tradeA) || !isClassicTrade(tradeB)) {
      return void 0;
    }
    const priceA = tradeA.routes[0]?.midPrice;
    const priceB = tradeB.routes[0]?.midPrice;
    if (!priceA || !priceB) {
      return void 0;
    }
    return priceA.multiply(priceB);
  }, [inputCurrency, outputCurrency, skip, tradeA, tradeB]);
  const feesEnabled = useFeatureFlag(FeatureFlags.LimitsFees);
  const fee = useMemo(() => {
    if (!marketPrice || !inputCurrency || !outputCurrency || !feesEnabled) {
      return void 0;
    }
    if (isNativeOrWrappedNative(inputCurrency)) {
      if (!tradeB?.outputAmount.currency.equals(outputCurrency) || !isClassicTrade(tradeB)) {
        return void 0;
      }
      return tradeB.swapFee;
    }
    if (isNativeOrWrappedNative(outputCurrency)) {
      if (!tradeA?.inputAmount.currency.equals(inputCurrency) || !isClassicTrade(tradeA)) {
        return void 0;
      }
      return tradeA.swapFee;
    }
    if (!tradeA || !tradeB) {
      return void 0;
    }
    const canTakeFees = tradeA.swapFee?.percent.greaterThan(0) && tradeB.swapFee?.percent.greaterThan(0);
    return canTakeFees ? tradeB.swapFee : void 0;
  }, [inputCurrency, outputCurrency, marketPrice, tradeA, tradeB, feesEnabled]);
  return useMemo(() => ({ marketPrice, fee }), [marketPrice, fee]);
}
