export function handleReduxAction({
  newState,
  shouldLogState,
  action
}) {
  const isAction = typeof action !== "undefined" && action !== null;
  if (shouldLogState) {
    const stateIsObject = typeof newState === "object" && newState !== null;
    const allObjectKeysString = stateIsObject && Object.keys(newState).every((k) => typeof k === "string");
    const validState = stateIsObject && allObjectKeysString;
    return {
      reduxStateToLog: validState ? filterReduxState(newState) : void 0,
      isAction
    };
  } else {
    return {
      reduxStateToLog: void 0,
      isAction
    };
  }
}
const ALLOWED_REDUX_FIELDS = [
  // Uniswap
  "searchHistory",
  "transactions",
  "uniswapBehaviorHistory",
  "userSettings",
  // Wallet
  "appearanceSettings",
  "behaviorHistory",
  "wallet",
  // Mobile
  "biometricSettings",
  "cloudBackup",
  // Extension
  "dappRequests",
  // Web
  "user"
];
function filterReduxState(state) {
  if (state === void 0) {
    return {};
  }
  return Object.keys(state).reduce((filteredState, key) => {
    if (ALLOWED_REDUX_FIELDS.includes(key)) {
      filteredState[key] = state[key];
    }
    return filteredState;
  }, {});
}
