"use strict";
import { Percent } from "@uniswap/sdk-core";
import { createContext } from "react";
import { TradeState } from "state/routing/types";
import { CurrencyField } from "uniswap/src/types/currency";
import { SwapTab } from "uniswap/src/types/screens/interface";
const EMPTY_DERIVED_SWAP_INFO = Object.freeze({
  currencies: {},
  currencyBalances: {},
  inputTax: new Percent(0),
  outputTax: new Percent(0),
  autoSlippage: new Percent(0),
  allowedSlippage: new Percent(0),
  trade: {
    state: TradeState.LOADING
  }
});
export const initialSwapState = {
  typedValue: "",
  independentField: CurrencyField.INPUT
};
export const SwapContext = createContext({
  swapState: initialSwapState,
  derivedSwapInfo: EMPTY_DERIVED_SWAP_INFO,
  setSwapState: () => void 0
});
export const SwapAndLimitContext = createContext({
  currencyState: {
    inputCurrency: void 0,
    outputCurrency: void 0
  },
  setCurrencyState: () => void 0,
  currentTab: SwapTab.Swap,
  setCurrentTab: () => void 0
});
