"use strict";
import { useLocation } from "react-router-dom";
export var PageType = /* @__PURE__ */ ((PageType2) => {
  PageType2["BUY"] = "/buy";
  PageType2["EXPLORE"] = "/explore";
  PageType2["LANDING"] = "/";
  PageType2["LIMIT"] = "/limit";
  PageType2["MIGRATE_V3"] = "/migrate/v3";
  PageType2["NFTS"] = "/nfts";
  PageType2["NFTS_DETAILS"] = "/nfts/asset";
  PageType2["NFTS_PROFILE"] = "/nfts/profile";
  PageType2["POSITIONS"] = "/positions";
  PageType2["SEND"] = "/send";
  PageType2["SWAP"] = "/swap";
  return PageType2;
})(PageType || {});
export var MatchType = /* @__PURE__ */ ((MatchType2) => {
  MatchType2[MatchType2["EXACT"] = 0] = "EXACT";
  MatchType2[MatchType2["STARTS_WITH"] = 1] = "STARTS_WITH";
  MatchType2[MatchType2["ENDS_WITH"] = 2] = "ENDS_WITH";
  MatchType2[MatchType2["INCLUDES"] = 3] = "INCLUDES";
  return MatchType2;
})(MatchType || {});
const pageMatchDefaults = {
  ["/buy" /* BUY */]: 0 /* EXACT */,
  ["/explore" /* EXPLORE */]: 3 /* INCLUDES */,
  ["/" /* LANDING */]: 0 /* EXACT */,
  ["/limit" /* LIMIT */]: 2 /* ENDS_WITH */,
  ["/migrate/v3" /* MIGRATE_V3 */]: 3 /* INCLUDES */,
  ["/nfts" /* NFTS */]: 1 /* STARTS_WITH */,
  ["/nfts/asset" /* NFTS_DETAILS */]: 1 /* STARTS_WITH */,
  ["/nfts/profile" /* NFTS_PROFILE */]: 1 /* STARTS_WITH */,
  ["/positions" /* POSITIONS */]: 3 /* INCLUDES */,
  ["/send" /* SEND */]: 2 /* ENDS_WITH */,
  ["/swap" /* SWAP */]: 2 /* ENDS_WITH */
};
export function useIsPage(page, matchTypeOverride) {
  const { pathname } = useLocation();
  const matchType = matchTypeOverride ?? pageMatchDefaults[page];
  switch (matchType) {
    case 0 /* EXACT */:
      return pathname === page;
    case 2 /* ENDS_WITH */:
      return pathname.endsWith(page);
    case 3 /* INCLUDES */:
      return pathname.includes(page);
    case 1 /* STARTS_WITH */:
      return pathname.startsWith(page);
    default:
      return pathname === page;
  }
}
