"use strict";
import { lighten } from "polished";
import { Shine, View, useSporeColors } from "ui/src";
export const LoadingBubble = ({
  containerWidth,
  height,
  width,
  round,
  delay,
  margin,
  ...rest
}) => {
  const colors = useSporeColors();
  return <Shine $platform-web={{ animationDelay: delay, width: containerWidth ?? "100%" }}><View
    borderRadius={round ? "$roundedFull" : "$rounded12"}
    height={height ?? "$spacing24"}
    width={width ?? "50%"}
    m={margin}
    $platform-web={{
      background: `linear-gradient(to left, ${colors.surface3.val} 25%, ${lighten(0.075, colors.surface3.val ?? "#FFFFFF12")} 50%, ${colors.surface3.val} 75%)`
    }}
    {...rest}
  /></Shine>;
};
