"use strict";
import styled from "lib/styled-components";
import { Box } from "rebass/styled-components";
const Row = styled(Box)`
  width: ${({ width }) => width ?? "100%"};
  display: flex;
  padding: 0;
  align-items: ${({ align }) => align ?? "center"};
  justify-content: ${({ justify }) => justify ?? "flex-start"};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  gap: ${({ gap, theme }) => gap && (theme.grids[gap] || gap)};
`;
export const RowBetween = styled(Row)`
  justify-content: space-between;
`;
export const AutoRow = styled(Row)`
  flex-wrap: wrap;
  margin: ${({ gap }) => gap && `-${gap}`};
  justify-content: ${({ justify }) => justify && justify};

  & > * {
    margin: ${({ gap }) => gap} !important;
  }
`;
export const RowFixed = styled(Row)`
  position: relative;
  width: fit-content;
  margin: ${({ gap }) => gap && `-${gap}`};
`;
export default Row;
