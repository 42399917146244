"use strict";
import { GetHelpHeader } from "components/Modal/GetHelpHeader";
import { ColumnCenter } from "components/deprecated/Column";
import Row from "components/deprecated/Row";
import styled from "lib/styled-components";
import { ThemedText } from "theme/components";
import { Button } from "ui/src";
import { Modal } from "uniswap/src/components/modals/Modal";
import { ModalName } from "uniswap/src/features/telemetry/constants";
export const Container = styled(ColumnCenter)`
  background-color: ${({ theme }) => theme.surface1};
  border-radius: 16px;
  padding: 16px 24px 24px 24px;
  width: 100%;
`;
const IconContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.surface3};
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`;
const TitleText = styled(ThemedText.HeadlineMedium)`
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  font-weight: 500;
`;
const DescriptionText = styled(ThemedText.BodySecondary)`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;
const DialogHeader = styled(GetHelpHeader)`
  padding: 4px 0px;
`;
export var DialogButtonType = /* @__PURE__ */ ((DialogButtonType2) => {
  DialogButtonType2["Primary"] = "primary";
  DialogButtonType2["Error"] = "error";
  DialogButtonType2["Accent"] = "accent";
  return DialogButtonType2;
})(DialogButtonType || {});
function getButtonEmphasis(type) {
  switch (type) {
    case "accent" /* Accent */:
    case "error" /* Error */:
      return "primary";
    default:
      return "secondary";
  }
}
function getButtonVariant(type) {
  switch (type) {
    case "error" /* Error */:
      return "critical";
    case "accent" /* Accent */:
      return "branded";
    default:
      return "default";
  }
}
export function DialogContent({ icon, title, description, body, buttonsConfig }) {
  const { left, right, gap } = buttonsConfig ?? {};
  return <ColumnCenter gap="lg"><ColumnCenter gap="16px"><IconContainer>{icon}</IconContainer><ColumnCenter gap="sm"><TitleText>{title}</TitleText><DescriptionText>{description}</DescriptionText>{body}</ColumnCenter></ColumnCenter><Row align="center" justify="center" gap={gap ?? "md"}>{left ? "title" in left ? <Button
    size="small"
    onPress={left.onClick}
    isDisabled={left.disabled}
    height={40}
    emphasis={getButtonEmphasis(left.type)}
    variant={getButtonVariant(left.type)}
  >{left.title}</Button> : left : null}{right ? "title" in right ? <Button
    size="small"
    onPress={right.onClick}
    isDisabled={right.disabled}
    height={40}
    emphasis={getButtonEmphasis(right.type)}
    variant={getButtonVariant(right.type)}
  >{right.title}</Button> : right : null}</Row></ColumnCenter>;
}
export function Dialog(props) {
  return <Modal name={ModalName.Dialog} isModalOpen={props.isVisible} onClose={props.onCancel} padding={0}><Container gap="lg"><DialogHeader closeModal={props.onCancel} closeDataTestId="Dialog-closeButton" /><DialogContent {...props} /></Container></Modal>;
}
