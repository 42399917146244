"use strict";
import { OffScreenHandle, brushHandleAccentPath, brushHandlePath } from "components/Charts/LiquidityRangeInput/svg";
import { brushY, select } from "d3";
import usePrevious from "hooks/usePrevious";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSporeColors } from "ui/src";
const FLIP_HANDLE_THRESHOLD_PX = 20;
const BRUSH_EXTENT_MARGIN_PX = 2;
const compare = (a, b, yScale) => {
  const aNorm = a.map((y) => yScale(y).toFixed(1));
  const bNorm = b.map((y) => yScale(y).toFixed(1));
  return aNorm.every((v, i) => v === bNorm[i]);
};
const toYScale = (extent, yScale) => {
  return [yScale(extent[1]), yScale(extent[0])];
};
const toPriceExtent = (selection, yScale) => {
  return [yScale.invert(selection[1]), yScale.invert(selection[0])];
};
const normalizeExtent = (extent) => extent[0] < extent[1] ? extent : [extent[1], extent[0]];
export const Brush = ({
  id,
  yScale,
  interactive,
  brushExtent,
  setBrushExtent,
  hideHandles,
  width,
  height
}) => {
  const colors = useSporeColors();
  const brushRef = useRef(null);
  const brushBehavior = useRef(null);
  const { t } = useTranslation();
  const [localBrushExtent, setLocalBrushExtent] = useState(brushExtent);
  const previousBrushExtent = usePrevious(brushExtent);
  const [brushInProgress, setBrushInProgress] = useState(false);
  useEffect(() => {
    if (brushInProgress) {
      return;
    }
    setLocalBrushExtent(brushExtent);
  }, [brushExtent, brushInProgress]);
  useEffect(() => {
    if (!brushRef.current || brushInProgress) {
      return;
    }
    const normalizedExtent = normalizeExtent(brushExtent);
    const scaledExtent = toYScale(normalizedExtent, yScale);
    brushBehavior.current = brushY().extent([
      // x0, y0 (top left)
      [0, BRUSH_EXTENT_MARGIN_PX],
      // x1, y1 (bottom right)
      [width, height - BRUSH_EXTENT_MARGIN_PX]
    ]).handleSize(30).filter(() => interactive).filter((event) => {
      const target = event.target;
      return target.classList.contains("selection") || target.classList.contains("handle");
    }).on("brush", (event) => {
      const { selection } = event;
      setBrushInProgress(true);
      if (!selection) {
        setLocalBrushExtent(null);
        return;
      }
      const priceExtent = normalizeExtent(toPriceExtent(selection, yScale));
      setLocalBrushExtent(priceExtent);
    }).on("end", (event) => {
      const { selection, mode } = event;
      if (!selection) {
        setLocalBrushExtent(null);
        return;
      }
      const priceExtent = normalizeExtent(toPriceExtent(selection, yScale));
      if (!compare(normalizedExtent, priceExtent, yScale)) {
        setBrushExtent(priceExtent, mode);
      }
      setLocalBrushExtent(priceExtent);
      setBrushInProgress(false);
    });
    brushBehavior.current(select(brushRef.current));
    if (previousBrushExtent && compare(normalizedExtent, normalizeExtent(previousBrushExtent), yScale)) {
      select(brushRef.current).transition().call(brushBehavior.current.move, scaledExtent);
    }
    select(brushRef.current).selectAll(".overlay").attr("cursor", "default");
    select(brushRef.current).selectAll(".selection").attr("stroke", "none").attr("fill-opacity", "0.1").attr("fill", `url(#${id}-gradient-selection)`).attr("cursor", "grab");
  }, [brushExtent, id, height, interactive, previousBrushExtent, yScale, width, setBrushExtent, brushInProgress]);
  useEffect(() => {
    if (!brushRef.current || !brushBehavior.current) {
      return;
    }
    brushBehavior.current.move(
      select(brushRef.current),
      normalizeExtent(toYScale(brushExtent, yScale))
    );
  }, [brushExtent, yScale]);
  const normalizedBrushExtent = normalizeExtent(localBrushExtent ?? brushExtent);
  const flipNorthHandle = yScale(normalizedBrushExtent[1]) < FLIP_HANDLE_THRESHOLD_PX;
  const flipSouthHandle = yScale(normalizedBrushExtent[0]) > height - FLIP_HANDLE_THRESHOLD_PX;
  const showNorthArrow = normalizedBrushExtent && (yScale(normalizedBrushExtent[0]) < 0 || yScale(normalizedBrushExtent[1]) < 0);
  const showSouthArrow = normalizedBrushExtent && (yScale(normalizedBrushExtent[0]) > height || yScale(normalizedBrushExtent[1]) > height);
  const southHandleInView = normalizedBrushExtent && yScale(normalizedBrushExtent[0]) >= 0 && yScale(normalizedBrushExtent[0]) <= height;
  const northHandleInView = normalizedBrushExtent && yScale(normalizedBrushExtent[1]) >= 0 && yScale(normalizedBrushExtent[1]) <= height;
  return useMemo(
    () => <><defs><linearGradient id={`${id}-gradient-selection`} x1="0%" y1="100%" x2="100%" y2="100%"><stop stopColor={colors.accent1.val} /><stop stopColor={colors.accent1.val} offset="1" /></linearGradient>{
      /* clips at exactly the svg area */
    }<clipPath id={`${id}-brush-clip`}><rect x={0} y="0" width={width} height={height} /></clipPath></defs>{
      /* will host the d3 brush */
    }<g ref={brushRef} clipPath={`url(#${id}-brush-clip)`} />{
      /* custom brush handles */
    }{normalizedBrushExtent && !hideHandles && <>{northHandleInView ? <g
      transform={`translate(0, ${Math.max(0, yScale(normalizedBrushExtent[1]))}), scale(1, ${flipNorthHandle ? "1" : "-1"})`}
      cursor={interactive ? "ns-resize" : "default"}
      pointerEvents="none"
    ><g><path
      color={colors.neutral2.val}
      stroke={colors.neutral2.val}
      opacity={0.6}
      d={brushHandlePath(width)}
    /><path
      color={colors.neutral2.val}
      stroke={colors.neutral2.val}
      strokeWidth={4}
      strokeLinecap="round"
      d={brushHandleAccentPath(width)}
    /></g></g> : null}{southHandleInView ? <g
      transform={`translate(0, ${yScale(normalizedBrushExtent[0])}), scale(1, ${flipSouthHandle ? "-1" : "1"})`}
      cursor={interactive ? "ns-resize" : "default"}
      pointerEvents="none"
    ><g><path
      color={colors.neutral2.val}
      stroke={colors.neutral2.val}
      opacity={0.6}
      d={brushHandlePath(width)}
    /><path
      color={colors.neutral2.val}
      stroke={colors.neutral2.val}
      strokeWidth={4}
      strokeLinecap="round"
      d={brushHandleAccentPath(width)}
    /></g></g> : null}{showNorthArrow && <g transform="translate(18, 16) scale(1,-1)"><OffScreenHandle color={colors.accent1.val} /><text
      x={14}
      y={-3}
      fill={colors.accent1.val}
      fontSize={10}
      alignmentBaseline="middle"
      transform="scale(1,-1)"
    >{t("range.outOfView")}</text></g>}{showSouthArrow && <g transform={`translate(18, ${height - 16}) `}><OffScreenHandle color={colors.accent1.val} />{!showNorthArrow && <text x={14} y={5} fill={colors.accent1.val} fontSize={10} alignmentBaseline="middle">{t("range.outOfView")}</text>}</g>}</>}</>,
    [
      id,
      colors.accent1.val,
      colors.neutral2.val,
      width,
      height,
      normalizedBrushExtent,
      hideHandles,
      northHandleInView,
      yScale,
      flipNorthHandle,
      interactive,
      southHandleInView,
      flipSouthHandle,
      showNorthArrow,
      t,
      showSouthArrow
    ]
  );
};
