import { createPromiseClient } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import { startAuthentication, startRegistration } from "@simplewebauthn/browser";
import { EmbeddedWalletService } from "@uniswap/client-embeddedwallet/dist/uniswap/embeddedwallet/v1/service_connect";
import {
  Action,
  AuthenticationTypes
} from "@uniswap/client-embeddedwallet/dist/uniswap/embeddedwallet/v1/service_pb";
import { uniswapUrls } from "uniswap/src/constants/urls";
import { SharedQueryClient } from "uniswap/src/data/apiClients/SharedQueryClient";
import { isAddress } from "utilities/src/addresses";
import { logger } from "utilities/src/logger/logger";
const enclaveTransport = createConnectTransport({
  baseUrl: uniswapUrls.evervaultDevUrl,
  credentials: "include"
});
export const EMBEDDED_WALLET_CLIENT = createPromiseClient(EmbeddedWalletService, enclaveTransport);
export async function fetchChallengeRequest({
  type,
  action
}) {
  return await SharedQueryClient.fetchQuery({
    queryKey: ["challenge", type, action],
    queryFn: () => EMBEDDED_WALLET_CLIENT.challenge({ type, action })
  });
}
async function fetchCreateWalletRequest({ credential }) {
  return await SharedQueryClient.fetchQuery({
    queryKey: ["createWallet", credential],
    queryFn: () => EMBEDDED_WALLET_CLIENT.createWallet({ credential })
  });
}
async function fetchWalletSigninRequest({ credential }) {
  return await SharedQueryClient.fetchQuery({
    queryKey: ["walletSignin", credential],
    queryFn: () => EMBEDDED_WALLET_CLIENT.walletSignin({ credential })
  });
}
async function fetchSignMessagesRequest({
  messages,
  credential
}) {
  return await SharedQueryClient.fetchQuery({
    queryKey: ["signMessages", messages, credential],
    queryFn: () => EMBEDDED_WALLET_CLIENT.signMessages({ messages, credential })
  });
}
async function fetchSignTransactionRequest({
  transactions,
  credential
}) {
  return await SharedQueryClient.fetchQuery({
    queryKey: ["signTransaction", transactions, credential],
    queryFn: () => EMBEDDED_WALLET_CLIENT.signTransactions({ transactions, credential })
  });
}
async function fetchSignTypedDataRequest({
  typedDataBatch,
  credential
}) {
  return await SharedQueryClient.fetchQuery({
    queryKey: ["signTypedData", typedDataBatch, credential],
    queryFn: () => EMBEDDED_WALLET_CLIENT.signTypedDataBatch({ typedDataBatch, credential })
  });
}
async function fetchExportSeedPhraseRequest({
  encryptionKey,
  credential
}) {
  return await SharedQueryClient.fetchQuery({
    queryKey: ["exportSeedPhrase", credential, encryptionKey],
    queryFn: () => EMBEDDED_WALLET_CLIENT.exportSeedPhrase({ credential, b64EncryptionPublicKey: encryptionKey })
  });
}
async function fetchDisconnectRequest() {
  return await SharedQueryClient.fetchQuery({
    queryKey: ["disconnect"],
    queryFn: () => EMBEDDED_WALLET_CLIENT.disconnectWallet({})
  });
}
async function registerNewPasskey() {
  try {
    const challenge = await fetchChallengeRequest({
      type: AuthenticationTypes.PASSKEY_REGISTRATION,
      action: Action.CREATE_WALLET
    });
    if (challenge?.challengeOptions) {
      const challengeJson = JSON.parse(challenge.challengeOptions);
      const authResp = await startRegistration({ optionsJSON: challengeJson });
      return authResp;
    }
  } catch (registrationError) {
    if (registrationError instanceof Error && registrationError.name === "AbortError") {
      logger.debug("embeddedWallet.ts", "registerNewPasskey", "User aborted registration");
    } else {
      logger.debug("embeddedWallet.ts", "registerNewPasskey", `Error during registration: ${registrationError}`);
    }
    return void 0;
  }
  logger.debug("embeddedWallet.ts", "registerNewPasskey", "Error parsing challenge response");
  return void 0;
}
export async function createNewEmbeddedWallet() {
  try {
    const passkeyCredential = await registerNewPasskey();
    if (passkeyCredential) {
      const createWalletResp = await fetchCreateWalletRequest({ credential: JSON.stringify(passkeyCredential) });
      if (createWalletResp?.walletAddress) {
        logger.debug(
          "embeddedWallet.ts",
          "createNewEmbeddedWallet",
          `New wallet created: ${createWalletResp.walletAddress}`
        );
        if (!isAddress(createWalletResp.walletAddress)) {
          logger.error(new Error("Invalid address returned from create wallet response"), {
            tags: {
              file: "embeddedWallet.ts",
              function: "createNewEmbeddedWallet"
            }
          });
          return void 0;
        }
        return createWalletResp.walletAddress;
      }
    }
    return void 0;
  } catch (error) {
    logger.error(error, {
      tags: {
        file: "embeddedWallet.ts",
        function: "createNewEmbeddedWallet"
      }
    });
    return void 0;
  }
}
export async function authenticateWithPasskey(action) {
  try {
    const challenge = await fetchChallengeRequest({
      type: AuthenticationTypes.PASSKEY_AUTHENTICATION,
      action
    });
    if (challenge?.challengeOptions) {
      const challengeJson = JSON.parse(challenge.challengeOptions);
      const authResp = await startAuthentication({ optionsJSON: challengeJson });
      return authResp;
    }
  } catch (registrationError) {
    if (registrationError instanceof Error && registrationError.name === "AbortError") {
      logger.debug("embeddedWallet.ts", "authenticateWithPasskey", "User aborted the registration process");
    } else {
      logger.error(new Error("Error during registration"), {
        tags: {
          file: "embeddedWallet.ts",
          function: "authenticateWithPasskey"
        }
      });
    }
    return void 0;
  }
  return void 0;
}
export async function signInWithPasskey() {
  try {
    const authResponse = await authenticateWithPasskey(Action.WALLET_SIGNIN);
    if (authResponse) {
      const signInResp = await fetchWalletSigninRequest({ credential: JSON.stringify(authResponse) });
      if (signInResp?.walletAddress) {
        return signInResp.walletAddress;
      }
    }
    return void 0;
  } catch (error) {
    logger.error(error, {
      tags: {
        file: "embeddedWallet.ts",
        function: "signInWithPasskey"
      }
    });
    return void 0;
  }
}
export async function signMessagesWithPasskey(messages) {
  try {
    const credential = await authenticateWithPasskey(Action.SIGN_MESSAGES);
    const signedMessagesResp = await fetchSignMessagesRequest({ messages, credential: JSON.stringify(credential) });
    return signedMessagesResp.signedMessages;
  } catch (error) {
    logger.error(error, {
      tags: {
        file: "embeddedWallet.ts",
        function: "signMessagesWithPasskey"
      }
    });
    return void 0;
  }
}
export async function signTransactionWithPasskey(transactions) {
  try {
    const credential = await authenticateWithPasskey(Action.SIGN_TRANSACTIONS);
    const signedTransactionResp = await fetchSignTransactionRequest({
      transactions,
      credential: JSON.stringify(credential)
    });
    return signedTransactionResp.signedTransactions;
  } catch (error) {
    logger.error(error, {
      tags: {
        file: "embeddedWallet.ts",
        function: "signTransactionWithPasskey"
      }
    });
    return void 0;
  }
}
export async function signTypedDataWithPasskey(typedDataBatch) {
  try {
    const credential = await authenticateWithPasskey(Action.SIGN_TYPED_DATA_BATCH);
    const signedTypedDataResp = await fetchSignTypedDataRequest({
      typedDataBatch,
      credential: JSON.stringify(credential)
    });
    return signedTypedDataResp.signature;
  } catch (error) {
    logger.error(error, {
      tags: {
        file: "embeddedWallet.ts",
        function: "signTypedDataWithPasskey"
      }
    });
    return void 0;
  }
}
export async function exportSeedPhraseWithPasskey() {
  try {
    const keyPair = await window.crypto.subtle.generateKey(
      {
        name: "RSA-OAEP",
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
        // 65537
        hash: "SHA-256"
      },
      true,
      // extractable
      ["encrypt", "decrypt"]
    );
    const publicKeySpki = await window.crypto.subtle.exportKey("spki", keyPair.publicKey);
    const publicKeyBase64 = Buffer.from(publicKeySpki).toString("base64");
    const credential = await authenticateWithPasskey(Action.EXPORT_SEED_PHRASE);
    const seedPhraseResp = await fetchExportSeedPhraseRequest({
      encryptionKey: publicKeyBase64,
      credential: JSON.stringify(credential)
    });
    const seedPhrase = await window.crypto.subtle.decrypt(
      {
        name: "RSA-OAEP"
      },
      keyPair.privateKey,
      Buffer.from(seedPhraseResp.encryptedSeedPhrase, "base64")
    );
    return new TextDecoder().decode(seedPhrase).split(" ");
  } catch (error) {
    logger.error(error, {
      tags: {
        file: "embeddedWallet.ts",
        function: "exportSeedPhraseWithPasskey"
      }
    });
    return void 0;
  }
}
export async function disconnectWallet() {
  logger.debug("embeddedWallet.ts", "disconnectWallet", "Attempting to disconnect wallet");
  try {
    await fetchDisconnectRequest();
    logger.debug("embeddedWallet.ts", "disconnectWallet", "Wallet disconnected");
  } catch (error) {
    logger.error(error, {
      tags: {
        file: "embeddedWallet.ts",
        function: "disconnectWallet"
      }
    });
  }
}
