"use strict";
import { embeddedWalletProvider } from "connection/EmbeddedWalletProvider";
import {
  ResourceUnavailableRpcError,
  SwitchChainError,
  UserRejectedRequestError,
  getAddress
} from "viem";
import { ChainNotConfiguredError, createConnector } from "wagmi";
export function embeddedWallet(parameters = {}) {
  return createConnector((config) => ({
    id: "embeddedUniswapWalletConnector",
    name: "Embedded Wallet",
    type: "embeddedUniswapWallet",
    async setup() {
      const provider = await this.getProvider();
      if (provider) {
        provider.on("connect", this.onConnect.bind(this));
      }
    },
    async getProvider() {
      return embeddedWalletProvider;
    },
    async connect({ chainId } = {}) {
      if (!localStorage.getItem("embeddedUniswapWallet.address")) {
        throw new ResourceUnavailableRpcError(new Error("No accounts available"));
      }
      const provider = await this.getProvider();
      let accounts = [];
      accounts = await this.getAccounts().catch(() => []);
      try {
        provider.removeListener("connect", this.onConnect.bind(this));
        provider.on("accountsChanged", this.onAccountsChanged.bind(this));
        provider.on("chainChanged", this.onChainChanged);
        provider.on("disconnect", this.onDisconnect.bind(this));
        let currentChainId = await this.getChainId();
        if (chainId && currentChainId !== chainId) {
          const chain = await this.switchChain({ chainId }).catch((error) => {
            if (error.code === UserRejectedRequestError.code) {
              throw error;
            }
            return { id: currentChainId };
          });
          currentChainId = chain?.id ?? currentChainId;
        }
        await config.storage?.removeItem("embeddedUniswapWallet.disconnected");
        if (!accounts || accounts.length === 0) {
          throw new ResourceUnavailableRpcError(new Error("No accounts available"));
        }
        return { accounts, chainId: currentChainId };
      } catch (err) {
        const error = err;
        if (error.code === UserRejectedRequestError.code) {
          throw new UserRejectedRequestError(error);
        }
        if (error.code === ResourceUnavailableRpcError.code) {
          throw new ResourceUnavailableRpcError(error);
        }
        throw error;
      }
    },
    async disconnect() {
      const provider = await this.getProvider();
      provider.removeListener("accountsChanged", this.onAccountsChanged.bind(this));
      provider.removeListener("chainChanged", this.onChainChanged);
      provider.removeListener("disconnect", this.onDisconnect.bind(this));
      provider.on("connect", this.onConnect.bind(this));
      localStorage.removeItem("embeddedUniswapWallet.address");
      config.storage?.setItem("embeddedUniswapWallet.disconnected", true);
    },
    async getAccounts() {
      const provider = await this.getProvider();
      const accounts = await provider.request({
        method: "eth_accounts"
      });
      return accounts.map((x) => getAddress(x));
    },
    async getChainId() {
      const provider = await this.getProvider();
      const chainId = provider.getChainId() || await provider?.request({ method: "eth_chainId" });
      return Number(chainId);
    },
    async isAuthorized() {
      try {
        const accounts = await this.getAccounts();
        return !!accounts.length;
      } catch {
        return false;
      }
    },
    async switchChain({ chainId }) {
      const provider = await this.getProvider();
      const chain = config.chains.find((x) => x.id === chainId);
      if (!chain) {
        throw new SwitchChainError(new ChainNotConfiguredError());
      }
      try {
        await Promise.all([
          provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId }]
          })
        ]);
        return chain;
      } catch (err) {
        const error = err;
        if (error.code === UserRejectedRequestError.code) {
          throw new UserRejectedRequestError(error);
        }
        throw new SwitchChainError(error);
      }
    },
    async onAccountsChanged(accounts) {
      if (accounts.length === 0) {
        this.onDisconnect();
      } else if (config.emitter.listenerCount("connect")) {
        const chainId = (await this.getChainId()).toString();
        this.onConnect({ chainId });
        await config.storage?.removeItem("embeddedUniswapWallet.disconnected");
      } else {
        config.emitter.emit("change", {
          accounts: accounts.map((x) => getAddress(x))
        });
      }
    },
    onChainChanged(chain) {
      const chainId = Number(chain);
      config.emitter.emit("change", { chainId });
    },
    async onConnect(connectInfo) {
      const accounts = await this.getAccounts();
      if (accounts.length === 0) {
        return;
      }
      const chainId = Number(connectInfo.chainId);
      config.emitter.emit("connect", { accounts, chainId });
      const provider = await this.getProvider();
      if (provider) {
        provider.removeListener("connect", this.onConnect.bind(this));
        provider.on("accountsChanged", this.onAccountsChanged.bind(this));
        provider.on("chainChanged", this.onChainChanged);
        provider.on("disconnect", this.onDisconnect.bind(this));
      }
    },
    // this can accept an `error` argument if needed.
    async onDisconnect() {
      const provider = await this.getProvider();
      config.emitter.emit("disconnect");
      provider.removeListener("accountsChanged", this.onAccountsChanged.bind(this));
      provider.removeListener("chainChanged", this.onChainChanged);
      provider.removeListener("disconnect", this.onDisconnect.bind(this));
      provider.on("connect", this.onConnect.bind(this));
    }
  }));
}
