"use strict";
import { popupRegistry } from "components/Popups/registry";
import { PopupType } from "components/Popups/types";
import { SwapTab } from "uniswap/src/types/screens/interface";
export function useShowSwapNetworkNotification() {
  return ({ chainId, prevChainId, outputChainId }) => {
    if (!chainId || chainId === prevChainId || chainId === outputChainId) {
      return;
    }
    const isBridgeNotification = chainId && outputChainId;
    if (isBridgeNotification) {
      popupRegistry.addPopup(
        {
          type: PopupType.Bridge,
          inputChainId: chainId,
          outputChainId
        },
        `bridge-${chainId}-to-${outputChainId}`,
        3e3
      );
    } else if (prevChainId) {
      popupRegistry.addPopup(
        {
          type: PopupType.SwitchNetwork,
          chainId,
          action: SwapTab.Swap
        },
        `switchNetwork-${chainId}`,
        3e3
      );
    }
  };
}
