import { Percent } from "@uniswap/sdk-core";
import { logger } from "utilities/src/logger/logger";
export function getSwapFee(quoteResponse) {
  const quote = quoteResponse?.quote;
  if (!quote) {
    return void 0;
  }
  const aggregatedOutputs = "aggregatedOutputs" in quote ? quote.aggregatedOutputs : void 0;
  const swapper = "swapper" in quote ? quote.swapper : void 0;
  if (!aggregatedOutputs || !swapper) {
    if (!quote.portionAmount || !quote.portionBips) {
      return void 0;
    }
    return {
      recipient: quote.portionRecipient,
      percent: new Percent(quote.portionBips, "10000"),
      amount: quote.portionAmount
    };
  }
  const ulFees = aggregatedOutputs.filter((output) => output.recipient !== swapper);
  if (ulFees.length > 1) {
    logger.error(new Error("Multiple UL fees found in quote"), {
      tags: {
        file: "getSwapFee.ts",
        function: "getSwapFee"
      },
      extra: { ulFees, quote }
    });
  }
  const ulFee = ulFees[0];
  if (!ulFee?.bps || !ulFee?.amount) {
    return void 0;
  }
  return {
    recipient: ulFee.recipient,
    percent: new Percent(ulFee.bps, "10000"),
    amount: ulFee.amount
  };
}
