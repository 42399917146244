/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ChainId {
    '_1' = 1,
    '_10' = 10,
    '_56' = 56,
    '_137' = 137,
    '_8453' = 8453,
    '_42161' = 42161,
    '_81457' = 81457,
    '_43114' = 43114,
    '_42220' = 42220,
    '_7777777' = 7777777,
    '_324' = 324,
    '_11155111' = 11155111,
    '_1301' = 1301,
    '_480' = 480,
    '_10143' = 10143,
    '_84532' = 84532,
    '_130' = 130,
    '_1868' = 1868,
}
