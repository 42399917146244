import { createConnectTransport } from "@connectrpc/connect-web";
import { uniswapUrls } from "uniswap/src/constants/urls";
import { BASE_UNISWAP_HEADERS } from "uniswap/src/data/apiClients/createApiClient";
import { isMobileApp } from "utilities/src/platform";
export const createConnectTransportWithDefaults = (options = {}) => createConnectTransport({
  baseUrl: uniswapUrls.apiBaseUrlV2,
  // Mobile app needs to manually set headers
  interceptors: isMobileApp ? [
    (next) => (request) => {
      Object.entries(BASE_UNISWAP_HEADERS).forEach(([key, value]) => {
        request.header.set(key, value);
      });
      return next(request);
    }
  ] : [],
  ...options
});
export const uniswapGetTransport = createConnectTransportWithDefaults({ useHttpGet: true });
export const ALL_NETWORKS_ARG = "ALL_NETWORKS";
