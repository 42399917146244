"use strict";
import { calculateColumnPositionsInPlace, positionsBox } from "components/Charts/VolumeChart/utils";
import { roundRect } from "components/Charts/utils";
export class LiquidityBarSeriesRenderer {
  _data = null;
  _options;
  constructor(props) {
    this._options = props;
    this._options.hoveredTick = props.activeTick;
  }
  draw(target, priceConverter) {
    target.useBitmapCoordinateSpace((scope) => this._drawImpl(scope, priceConverter));
  }
  update(data, options) {
    this._data = data;
    this._options = { ...this._options, ...options };
  }
  _drawImpl(renderingScope, priceToCoordinate) {
    if (this._data === null || this._data.bars.length === 0 || this._data.visibleRange === null || this._options === null) {
      return;
    }
    const ctx = renderingScope.context;
    const bars = this._data.bars.map((bar) => {
      return {
        x: bar.x,
        y: priceToCoordinate(bar.originalData.liquidity) ?? 0,
        tick: bar.originalData.tick
      };
    });
    calculateColumnPositionsInPlace(
      bars,
      this._data.barSpacing,
      renderingScope.horizontalPixelRatio,
      this._data.visibleRange.from,
      this._data.visibleRange.to
    );
    const zeroY = priceToCoordinate(0) ?? 0;
    ctx.fillStyle = this._options.tokenAColor;
    for (let i = this._data.visibleRange.from; i < this._data.visibleRange.to; i++) {
      const stack = bars[i];
      const column = stack.column;
      const isCurrentTick = this._options.activeTick === stack.tick;
      const isHoveredTick = this._options.hoveredTick === stack.tick;
      if (!column) {
        return;
      }
      const width = Math.min(
        Math.max(renderingScope.horizontalPixelRatio, column.right - column.left),
        this._data.barSpacing * renderingScope.horizontalPixelRatio
      );
      const margin = 0.3 * width;
      const widthWithMargin = width - margin * 2;
      const totalBox = positionsBox(zeroY, stack.y, renderingScope.verticalPixelRatio);
      if (isHoveredTick) {
        const highlightOffset = 0.3 * ctx.canvas.height;
        const highlightLength = ctx.canvas.height - highlightOffset;
        ctx.fillStyle = this._options.highlightColor;
        roundRect(ctx, column.left + margin, highlightOffset, widthWithMargin, highlightLength, 8);
        ctx.globalAlpha = 1;
      } else {
        ctx.globalAlpha = 0.4;
      }
      if (isCurrentTick) {
        ctx.fillStyle = this._options.tokenBColor;
      } else if (this._options.activeTick === void 0) {
        ctx.fillStyle = this._options.color ?? "white";
      } else if (this._options.activeTick > stack.tick) {
        ctx.fillStyle = this._options.tokenBColor;
      } else if (this._options.activeTick < stack.tick) {
        ctx.fillStyle = this._options.tokenAColor;
      }
      roundRect(ctx, column.left + margin, totalBox.position, widthWithMargin, totalBox.length, 8);
      ctx.globalAlpha = 1;
      if (isCurrentTick && this._options.activeTickProgress) {
        ctx.globalCompositeOperation = "source-atop";
        const activeRangeAdjustedHeight = totalBox.length * this._options.activeTickProgress;
        const activeRangeAdjustedPosition = totalBox.position + (totalBox.length - activeRangeAdjustedHeight);
        ctx.fillStyle = this._options.tokenAColor;
        ctx.beginPath();
        ctx.fillRect(column.left, activeRangeAdjustedPosition, width, activeRangeAdjustedHeight);
        ctx.globalCompositeOperation = "source-over";
      }
    }
  }
}
