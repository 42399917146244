import { Children, forwardRef, isValidElement, useMemo } from "react";
import { Trans } from "react-i18next";
import { I18nManager } from "react-native";
import { withStaticProperties } from "tamagui";
import { useLayoutAnimationOnChange } from "ui/src/animations";
import { CustomButtonFrame } from "ui/src/components/buttons/Button/components/CustomButtonFrame";
import { CustomButtonText } from "ui/src/components/buttons/Button/components/CustomButtonText";
import { ThemedIcon } from "ui/src/components/buttons/Button/components/ThemedIcon";
import { ThemedSpinningLoader } from "ui/src/components/buttons/Button/components/ThemedSpinnerLoader";
import { getIsButtonDisabled } from "ui/src/components/buttons/Button/utils/getIsButtonDisabled";
import { Flex } from "ui/src/components/layout/Flex";
const hasDirectOrResolvedTransChild = (child) => {
  if (!isValidElement(child)) {
    return false;
  }
  if (child.type === Trans) {
    return true;
  }
  if (child.type === Flex) {
    return false;
  }
  return true;
};
const ButtonComponent = forwardRef(function Button2({
  children,
  icon,
  fill = true,
  shouldAnimateBetweenLoadingStates = true,
  variant = "default",
  focusScaling = "default",
  emphasis = "primary",
  size = "medium",
  loading,
  iconPosition: propIconPosition = "before",
  isDisabled: propDisabled,
  ...props
}, ref) {
  useLayoutAnimationOnChange(shouldAnimateBetweenLoadingStates ? loading : false);
  const isDisabled = getIsButtonDisabled({ isDisabled: propDisabled, loading });
  const iconPosition = I18nManager.isRTL ? propIconPosition === "before" ? "after" : "before" : propIconPosition;
  const arrayedChildren = useMemo(() => Children.toArray(children), [children]);
  const numberOfArrayedChildren = Children.count(children);
  const firstChild = arrayedChildren[0];
  const isChildrenAString = typeof children === "string";
  const isStringOrTransTag = useMemo(() => {
    if (isChildrenAString) {
      return true;
    }
    return numberOfArrayedChildren === 1 && hasDirectOrResolvedTransChild(firstChild);
  }, [isChildrenAString, numberOfArrayedChildren, firstChild]);
  return <CustomButtonFrame
    ref={ref}
    fill={fill}
    focusScaling={focusScaling}
    emphasis={emphasis}
    variant={variant}
    size={size}
    iconPosition={iconPosition}
    isDisabled={isDisabled}
    {...props}
  ><ThemedIcon isDisabled={isDisabled} emphasis={emphasis} size={size} variant={variant} typeOfButton="button">{loading ? void 0 : icon}</ThemedIcon>{
    /* `iconPosition` takes care of setting flexDirection: 'row' | 'row-reverse', so we don't need to worry about it here */
  }{loading ? <ThemedSpinningLoader
    isDisabled={isDisabled}
    emphasis={emphasis}
    size={size}
    variant={variant}
    typeOfButton="button"
  /> : null}{isStringOrTransTag ? <CustomButtonText customBackgroundColor={props.backgroundColor}>{children}</CustomButtonText> : children}</CustomButtonFrame>;
});
export const Button = withStaticProperties(ButtonComponent, {
  Text: CustomButtonText,
  Icon: ThemedIcon
});
