/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum OrderTypeQuery {
    DUTCH = 'Dutch',
    DUTCH_V2 = 'Dutch_V2',
    DUTCH_V1_V2 = 'Dutch_V1_V2',
    DUTCH_V3 = 'Dutch_V3',
    LIMIT = 'Limit',
    PRIORITY = 'Priority',
}
