import { Rect, Svg } from "react-native-svg";
import { createIcon } from "../factories/createIcon";
export const [HorizontalDensityChart, AnimatedHorizontalDensityChart] = createIcon({
  name: "HorizontalDensityChart",
  getIcon: (props) => <Svg viewBox="0 0 124 160" fill="none" {...props}><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 10)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 13.75)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 17.5)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 21.25)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 25)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 28.75)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 32.5)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 36.25)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 40)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 43.75)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 47.5)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 51.25)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 55)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 58.75)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 62.5)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 66.25)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 70)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 73.75)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 77.5)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 81.25)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 85)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 88.75)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 92.5)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 96.25)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 100)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 103.75)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 107.5)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 111.25)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 115)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 118.75)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 122.5)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 126.25)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 130)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 133.75)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 137.5)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 141.25)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 145)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 148.75)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /><Rect
    width="60"
    height="1"
    rx="0.5"
    transform="matrix(1 0 0 -1 84 152.5)"
    fill={"currentColor"}
    fillOpacity="0.6"
  /></Svg>,
  defaultFill: "#5E5E5E"
});
