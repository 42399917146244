"use strict";
import { ProtocolVersion } from "@uniswap/client-pools/dist/pools/v1/types_pb";
import { FeeAmount, TICK_SPACINGS } from "@uniswap/v3-sdk";
const DYNAMIC_FEE_AMOUNT = 8388608;
export const DYNAMIC_FEE_DATA = {
  feeAmount: DYNAMIC_FEE_AMOUNT,
  tickSpacing: 60
};
export var PositionFlowStep = /* @__PURE__ */ ((PositionFlowStep2) => {
  PositionFlowStep2[PositionFlowStep2["SELECT_TOKENS_AND_FEE_TIER"] = 0] = "SELECT_TOKENS_AND_FEE_TIER";
  PositionFlowStep2[PositionFlowStep2["PRICE_RANGE"] = 1] = "PRICE_RANGE";
  PositionFlowStep2[PositionFlowStep2["DEPOSIT"] = 2] = "DEPOSIT";
  return PositionFlowStep2;
})(PositionFlowStep || {});
export const DEFAULT_POSITION_STATE = {
  currencyInputs: {},
  fee: { feeAmount: FeeAmount.MEDIUM, tickSpacing: TICK_SPACINGS[FeeAmount.MEDIUM] },
  hook: void 0,
  userApprovedHook: void 0,
  protocolVersion: ProtocolVersion.V4
};
