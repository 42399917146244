"use strict";
import { createSlice } from "@reduxjs/toolkit";
export var ApplicationModal = /* @__PURE__ */ ((ApplicationModal2) => {
  ApplicationModal2[ApplicationModal2["ADDRESS_CLAIM"] = 0] = "ADDRESS_CLAIM";
  ApplicationModal2[ApplicationModal2["BLOCKED_ACCOUNT"] = 1] = "BLOCKED_ACCOUNT";
  ApplicationModal2[ApplicationModal2["CLAIM_POPUP"] = 2] = "CLAIM_POPUP";
  ApplicationModal2[ApplicationModal2["DELEGATE"] = 3] = "DELEGATE";
  ApplicationModal2[ApplicationModal2["EXECUTE"] = 4] = "EXECUTE";
  ApplicationModal2[ApplicationModal2["FEATURE_FLAGS"] = 5] = "FEATURE_FLAGS";
  ApplicationModal2[ApplicationModal2["FIAT_ONRAMP"] = 6] = "FIAT_ONRAMP";
  ApplicationModal2[ApplicationModal2["RECEIVE_CRYPTO"] = 7] = "RECEIVE_CRYPTO";
  ApplicationModal2[ApplicationModal2["RECEIVE_CRYPTO_QR"] = 8] = "RECEIVE_CRYPTO_QR";
  ApplicationModal2[ApplicationModal2["RECOVERY_PHRASE"] = 9] = "RECOVERY_PHRASE";
  ApplicationModal2[ApplicationModal2["PRIVACY_POLICY"] = 10] = "PRIVACY_POLICY";
  ApplicationModal2[ApplicationModal2["QUEUE"] = 11] = "QUEUE";
  ApplicationModal2[ApplicationModal2["SETTINGS"] = 12] = "SETTINGS";
  ApplicationModal2[ApplicationModal2["VOTE"] = 13] = "VOTE";
  ApplicationModal2[ApplicationModal2["UK_DISCLAIMER"] = 14] = "UK_DISCLAIMER";
  ApplicationModal2[ApplicationModal2["GET_THE_APP"] = 15] = "GET_THE_APP";
  return ApplicationModal2;
})(ApplicationModal || {});
const initialState = {
  chainId: null,
  openModal: null,
  suppressedPopups: []
};
const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    updateChainId(state, action) {
      const { chainId } = action.payload;
      state.chainId = chainId;
    },
    setOpenModal(state, action) {
      state.openModal = action.payload;
    },
    setCloseModal(state, action) {
      const { payload } = action;
      if (!payload || state.openModal?.name === payload) {
        state.openModal = null;
      }
    },
    addSuppressedPopups(state, { payload: { popupTypes } }) {
      state.suppressedPopups = Array.from(/* @__PURE__ */ new Set([...state.suppressedPopups, ...popupTypes]));
    },
    removeSuppressedPopups(state, { payload: { popupTypes } }) {
      state.suppressedPopups = state.suppressedPopups.filter((type) => !popupTypes.includes(type));
    }
  }
});
export const { updateChainId, setOpenModal, setCloseModal, addSuppressedPopups, removeSuppressedPopups } = applicationSlice.actions;
export default applicationSlice.reducer;
