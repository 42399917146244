"use strict";
import { getChainInfo } from "uniswap/src/features/chains/chainInfo";
import { isUniverseChainId } from "uniswap/src/features/chains/types";
const getDefaultBorderRadius = (size) => size / 2 - 4;
export function ChainLogo({
  chainId,
  style,
  size = 12,
  borderRadius = getDefaultBorderRadius(size),
  testId,
  fillContainer = false
}) {
  const isSupportedChain = isUniverseChainId(chainId);
  if (!isSupportedChain) {
    return null;
  }
  const { label, logo } = getChainInfo(chainId);
  const iconSize = fillContainer ? "100%" : size + "px";
  return <img
    aria-labelledby="titleID"
    data-testid={testId}
    width={iconSize}
    height={iconSize}
    src={logo}
    style={{ ...style, borderRadius: borderRadius + "px" }}
    alt={`${label} logo`}
  />;
}
