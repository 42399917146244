"use strict";
import { ProtocolVersion } from "@uniswap/client-pools/dist/pools/v1/types_pb";
import { useCurrency } from "hooks/Tokens";
import { useV2Pair } from "hooks/useV2Pairs";
import { getCurrencyWithWrap } from "pages/Pool/Positions/create/utils";
import PoolFinder from "pages/PoolFinder";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { parseCurrencyFromURLParameter } from "state/swap/hooks";
import { useEnabledChains } from "uniswap/src/features/chains/hooks/useEnabledChains";
import { toGraphQLChain } from "uniswap/src/features/chains/utils";
import { getChainIdFromChainUrlParam, searchParamToBackendName } from "utils/chainParams";
import { useAccount } from "wagmi";
export function LegacyPoolRedirects() {
  return <Navigate to="/positions" replace />;
}
export function PoolFinderRedirects() {
  return <PoolFinder />;
}
export function RemoveLiquidityV2WithTokenRedirects() {
  const { chainId: connectedChainId } = useAccount();
  const { defaultChainId } = useEnabledChains();
  const { currencyIdA, currencyIdB } = useParams();
  const [searchParams] = useSearchParams();
  const chainParam = searchParams.get("chain") ?? void 0;
  const currencyAddressA = parseCurrencyFromURLParameter(currencyIdA ?? "");
  const currencyAddressB = parseCurrencyFromURLParameter(currencyIdB ?? "");
  const chainId = getChainIdFromChainUrlParam(chainParam) ?? connectedChainId ?? defaultChainId;
  const [currencyA, currencyB] = [useCurrency(currencyAddressA, chainId), useCurrency(currencyAddressB, chainId)];
  const [, pair] = useV2Pair(
    getCurrencyWithWrap(currencyA, ProtocolVersion.V2),
    getCurrencyWithWrap(currencyB, ProtocolVersion.V2)
  );
  if (pair) {
    return <Navigate to={`/positions/v2/${toGraphQLChain(chainId).toLowerCase()}/${pair.liquidityToken.address}`} replace />;
  }
  return <Navigate to="/positions" replace />;
}
export function LegacyPositionPageRedirects() {
  const { tokenId } = useParams();
  const [searchParams] = useSearchParams();
  const { chainId: connectedChainId } = useAccount();
  const { defaultChainId } = useEnabledChains();
  const chainName = searchParamToBackendName(searchParams.get("chain"))?.toLowerCase() ?? toGraphQLChain(connectedChainId ?? defaultChainId).toLowerCase();
  return <Navigate to={`/positions/v3/${chainName}/${tokenId}`} replace />;
}
