"use strict";
export const brushHandlePath = (width) => [
  `M 0 0`,
  // move to origin
  `h ${width}`
  // horizontal line with specified width
].join(" ");
export const brushHandleAccentPath = (width) => {
  const lineStart = width / 2 - 15;
  return [
    "M 0 0",
    // move to origin
    `m ${lineStart} 8`,
    // move to start of accent line
    `h 30`
    // horizontal line
  ].join(" ");
};
export const OffScreenHandle = ({ color, size = 6 }) => {
  const center = size / 3;
  return <polygon
    points={`0 0, ${size} ${size}, 0 ${size}`}
    transform={`translate(${center}, ${center}) rotate(-45) translate(-${center}, -${center})`}
    fill={color}
    stroke={color}
    strokeWidth="4"
    strokeLinejoin="round"
  />;
};
