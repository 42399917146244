"use strict";
import { BigNumber } from "@ethersproject/bignumber";
import { MixedRouteSDK } from "@uniswap/router-sdk";
import { CurrencyAmount, Percent, Token, TradeType } from "@uniswap/sdk-core";
import { Pair, Route as V2Route } from "@uniswap/v2-sdk";
import { Pool, Route as V3Route } from "@uniswap/v3-sdk";
import { getApproveInfo, getWrapInfo } from "state/routing/gas";
import {
  ClassicTrade,
  DutchOrderTrade,
  OffchainOrderType,
  PoolType,
  PreviewTrade,
  PriorityOrderTrade,
  QuoteState,
  RouterPreference,
  SwapRouterNativeAssets,
  TradeFillType,
  URAQuoteType,
  V2DutchOrderTrade,
  V3DutchOrderTrade,
  isClassicQuoteResponse
} from "state/routing/types";
import { BIPS_BASE } from "uniswap/src/constants/misc";
import { isAvalanche, isBsc, isPolygon, nativeOnChain } from "uniswap/src/constants/tokens";
import { logger } from "utilities/src/logger/logger";
import { toSlippagePercent } from "utils/slippage";
export function computeRoutes(args, routes) {
  if (routes.length === 0) {
    return [];
  }
  const [currencyIn, currencyOut] = getTradeCurrencies(args, false, routes);
  try {
    return routes.map((route) => {
      if (route.length === 0) {
        throw new Error("Expected route to have at least one pair or pool");
      }
      const rawAmountIn = route[0].amountIn;
      const rawAmountOut = route[route.length - 1].amountOut;
      if (!rawAmountIn || !rawAmountOut) {
        throw new Error("Expected both amountIn and amountOut to be present");
      }
      const isOnlyV2 = isVersionedRoute(PoolType.V2Pool, route);
      const isOnlyV3 = isVersionedRoute(PoolType.V3Pool, route);
      return {
        routev3: isOnlyV3 ? new V3Route(route.map(parsePool), currencyIn, currencyOut) : null,
        routev2: isOnlyV2 ? new V2Route(route.map(parsePair), currencyIn, currencyOut) : null,
        mixedRoute: !isOnlyV3 && !isOnlyV2 ? new MixedRouteSDK(route.map(parsePoolOrPair), currencyIn, currencyOut) : null,
        inputAmount: CurrencyAmount.fromRawAmount(currencyIn, rawAmountIn),
        outputAmount: CurrencyAmount.fromRawAmount(currencyOut, rawAmountOut)
      };
    });
  } catch (e) {
    logger.warn("routing/utils", "computeRoutes", "Failed to compute routes", { error: e });
    return void 0;
  }
}
const parsePoolOrPair = (pool) => {
  return pool.type === PoolType.V3Pool ? parsePool(pool) : parsePair(pool);
};
function isVersionedRoute(type, route) {
  return route.every((pool) => pool.type === type);
}
function toDutchOrderInfo(orderInfoJSON) {
  const { nonce, input, outputs, exclusivityOverrideBps } = orderInfoJSON;
  return {
    ...orderInfoJSON,
    nonce: BigNumber.from(nonce),
    exclusivityOverrideBps: BigNumber.from(exclusivityOverrideBps),
    input: {
      ...input,
      startAmount: BigNumber.from(input.startAmount),
      endAmount: BigNumber.from(input.endAmount)
    },
    outputs: outputs.map((output) => ({
      ...output,
      startAmount: BigNumber.from(output.startAmount),
      endAmount: BigNumber.from(output.endAmount)
    }))
  };
}
function toUnsignedV2DutchOrderInfo(orderInfoJSON) {
  const { nonce, input, outputs } = orderInfoJSON;
  return {
    ...orderInfoJSON,
    nonce: BigNumber.from(nonce),
    input: {
      ...input,
      startAmount: BigNumber.from(input.startAmount),
      endAmount: BigNumber.from(input.endAmount)
    },
    outputs: outputs.map((output) => ({
      ...output,
      startAmount: BigNumber.from(output.startAmount),
      endAmount: BigNumber.from(output.endAmount)
    }))
  };
}
function toUnsignedV3DutchOrderInfo(orderInfoJSON) {
  const { nonce, input, outputs, startingBaseFee } = orderInfoJSON;
  return {
    ...orderInfoJSON,
    nonce: BigNumber.from(nonce),
    startingBaseFee: BigNumber.from(startingBaseFee),
    input: {
      ...input,
      startAmount: BigNumber.from(input.startAmount),
      maxAmount: BigNumber.from(input.maxAmount),
      adjustmentPerGweiBaseFee: BigNumber.from(input.adjustmentPerGweiBaseFee),
      curve: {
        relativeBlocks: input.curve.relativeBlocks,
        relativeAmounts: input.curve.relativeAmounts.map((amount) => BigNumber.from(amount).toBigInt())
      }
    },
    outputs: outputs.map((output) => ({
      ...output,
      startAmount: BigNumber.from(output.startAmount),
      minAmount: BigNumber.from(output.minAmount),
      adjustmentPerGweiBaseFee: BigNumber.from(output.adjustmentPerGweiBaseFee),
      curve: {
        relativeBlocks: output.curve.relativeBlocks,
        relativeAmounts: output.curve.relativeAmounts.map((amount) => BigNumber.from(amount).toBigInt())
      }
    }))
  };
}
function toUnsignedPriorityOrderInfo(orderInfoJSON) {
  const { nonce, auctionStartBlock, baselinePriorityFeeWei, input, outputs } = orderInfoJSON;
  return {
    ...orderInfoJSON,
    nonce: BigNumber.from(nonce),
    auctionStartBlock: BigNumber.from(auctionStartBlock),
    baselinePriorityFeeWei: BigNumber.from(baselinePriorityFeeWei),
    input: {
      ...input,
      amount: BigNumber.from(input.amount),
      mpsPerPriorityFeeWei: BigNumber.from(input.mpsPerPriorityFeeWei)
    },
    outputs: outputs.map((output) => ({
      ...output,
      amount: BigNumber.from(output.amount),
      mpsPerPriorityFeeWei: BigNumber.from(output.mpsPerPriorityFeeWei)
    }))
  };
}
function getTradeCurrencies(args, isUniswapXTrade2, routes) {
  const {
    tokenInAddress,
    tokenInChainId,
    tokenInDecimals,
    tokenInSymbol,
    tokenOutAddress,
    tokenOutChainId,
    tokenOutDecimals,
    tokenOutSymbol
  } = args;
  const tokenInIsNative = Object.values(SwapRouterNativeAssets).includes(tokenInAddress);
  const tokenOutIsNative = Object.values(SwapRouterNativeAssets).includes(tokenOutAddress);
  const serializedTokenIn = routes?.[0]?.[0]?.tokenIn;
  const serializedTokenOut = routes?.[0]?.[routes[0]?.length - 1]?.tokenOut;
  const currencyIn = tokenInIsNative ? nativeOnChain(tokenInChainId) : parseToken({
    address: tokenInAddress,
    chainId: tokenInChainId,
    decimals: tokenInDecimals,
    symbol: tokenInSymbol,
    buyFeeBps: serializedTokenIn?.buyFeeBps,
    sellFeeBps: serializedTokenIn?.sellFeeBps
  });
  const currencyOut = tokenOutIsNative ? nativeOnChain(tokenOutChainId) : parseToken({
    address: tokenOutAddress,
    chainId: tokenOutChainId,
    decimals: tokenOutDecimals,
    symbol: tokenOutSymbol,
    buyFeeBps: serializedTokenOut?.buyFeeBps,
    sellFeeBps: serializedTokenOut?.sellFeeBps
  });
  if (!isUniswapXTrade2) {
    return [currencyIn, currencyOut];
  }
  return [currencyIn.isNative ? currencyIn.wrapped : currencyIn, currencyOut];
}
function getSwapFee(data) {
  const { portionAmount, portionBips, portionRecipient } = data;
  if (!portionAmount || !portionBips || !portionRecipient) {
    return void 0;
  }
  return {
    recipient: portionRecipient,
    percent: new Percent(portionBips, BIPS_BASE),
    amount: portionAmount
  };
}
function getClassicTradeDetails(args, data) {
  const classicQuote = data.routing === URAQuoteType.CLASSIC ? data.quote : data.allQuotes.find(isClassicQuoteResponse)?.quote;
  if (!classicQuote) {
    return {};
  }
  return {
    gasUseEstimate: classicQuote.gasUseEstimate ? parseFloat(classicQuote.gasUseEstimate) : void 0,
    gasUseEstimateUSD: classicQuote.gasUseEstimateUSD ? parseFloat(classicQuote.gasUseEstimateUSD) : void 0,
    blockNumber: classicQuote.blockNumber,
    routes: computeRoutes(args, classicQuote.route),
    swapFee: getSwapFee(classicQuote)
  };
}
export function transformQuickRouteToTrade(args, data) {
  const { amount, tradeType } = args;
  const [currencyIn, currencyOut] = getTradeCurrencies(args, false);
  const [rawAmountIn, rawAmountOut] = data.tradeType === "EXACT_IN" ? [amount, data.quote.amount] : [data.quote.amount, amount];
  const inputAmount = CurrencyAmount.fromRawAmount(currencyIn, rawAmountIn);
  const outputAmount = CurrencyAmount.fromRawAmount(currencyOut, rawAmountOut);
  return new PreviewTrade({ inputAmount, outputAmount, tradeType });
}
export function getUSDCostPerGas(gasUseEstimateUSD, gasUseEstimate) {
  if (!gasUseEstimateUSD || !gasUseEstimate) {
    return void 0;
  }
  return gasUseEstimateUSD / gasUseEstimate;
}
export async function transformQuoteToTrade(args, data, quoteMethod) {
  const { tradeType, needsWrapIfUniswapX, routerPreference, account, amount, routingType } = args;
  const showUniswapXTrade = (routingType === URAQuoteType.DUTCH_V2 || routingType === URAQuoteType.DUTCH_V3 || routingType === URAQuoteType.PRIORITY) && routerPreference === RouterPreference.X;
  const [currencyIn, currencyOut] = getTradeCurrencies(args, showUniswapXTrade);
  const { gasUseEstimateUSD, blockNumber, routes, gasUseEstimate, swapFee } = getClassicTradeDetails(args, data);
  const usdCostPerGas = getUSDCostPerGas(gasUseEstimateUSD, gasUseEstimate);
  const approveInfo = await getApproveInfo(account, currencyIn, amount, usdCostPerGas);
  const classicTrade = new ClassicTrade({
    v2Routes: routes?.filter((r) => r.routev2 !== null).map(({ routev2, inputAmount, outputAmount }) => ({
      routev2,
      inputAmount,
      outputAmount
    })) ?? [],
    v3Routes: routes?.filter((r) => r.routev3 !== null).map(({ routev3, inputAmount, outputAmount }) => ({
      routev3,
      inputAmount,
      outputAmount
    })) ?? [],
    mixedRoutes: routes?.filter(
      (r) => r.mixedRoute !== null
    ).map(({ mixedRoute, inputAmount, outputAmount }) => ({
      mixedRoute,
      inputAmount,
      outputAmount
    })) ?? [],
    tradeType,
    gasUseEstimateUSD,
    gasUseEstimate,
    approveInfo,
    blockNumber,
    requestId: data.quote.requestId,
    quoteMethod,
    swapFee
  });
  const isUniswapXBetter = data.routing === URAQuoteType.DUTCH_V1 || data.routing === URAQuoteType.DUTCH_V2 || data.routing === URAQuoteType.DUTCH_V3 || data.routing === URAQuoteType.PRIORITY;
  if (isUniswapXBetter) {
    const swapFee2 = getSwapFee(data.quote);
    const wrapInfo = await getWrapInfo(needsWrapIfUniswapX, account, currencyIn.chainId, amount, usdCostPerGas);
    if (data.routing === URAQuoteType.DUTCH_V3) {
      const orderInfo = toUnsignedV3DutchOrderInfo(data.quote.orderInfo);
      const uniswapXv3Trade = new V3DutchOrderTrade({
        currencyIn,
        currenciesOut: [currencyOut],
        orderInfo,
        tradeType,
        quoteId: data.quote.quoteId,
        requestId: data.quote.requestId,
        classicGasUseEstimateUSD: classicTrade.totalGasUseEstimateUSD,
        wrapInfo,
        approveInfo,
        deadlineBufferSecs: data.quote.deadlineBufferSecs,
        slippageTolerance: toSlippagePercent(data.quote.slippageTolerance),
        swapFee: swapFee2
      });
      return {
        state: QuoteState.SUCCESS,
        trade: uniswapXv3Trade
      };
    } else if (data.routing === URAQuoteType.DUTCH_V2) {
      const orderInfo = toUnsignedV2DutchOrderInfo(data.quote.orderInfo);
      const uniswapXv2Trade = new V2DutchOrderTrade({
        currencyIn,
        currenciesOut: [currencyOut],
        orderInfo,
        tradeType,
        quoteId: data.quote.quoteId,
        requestId: data.quote.requestId,
        classicGasUseEstimateUSD: classicTrade.totalGasUseEstimateUSD,
        wrapInfo,
        approveInfo,
        deadlineBufferSecs: data.quote.deadlineBufferSecs,
        slippageTolerance: toSlippagePercent(data.quote.slippageTolerance),
        swapFee: swapFee2
      });
      return {
        state: QuoteState.SUCCESS,
        trade: uniswapXv2Trade
      };
    } else if (data.routing === URAQuoteType.DUTCH_V1) {
      const orderInfo = toDutchOrderInfo(data.quote.orderInfo);
      const uniswapXTrade = new DutchOrderTrade({
        currencyIn,
        currenciesOut: [currencyOut],
        orderInfo,
        tradeType,
        quoteId: data.quote.quoteId,
        requestId: data.quote.requestId,
        classicGasUseEstimateUSD: classicTrade.totalGasUseEstimateUSD,
        wrapInfo,
        approveInfo,
        auctionPeriodSecs: data.quote.auctionPeriodSecs,
        startTimeBufferSecs: data.quote.startTimeBufferSecs,
        deadlineBufferSecs: data.quote.deadlineBufferSecs,
        slippageTolerance: toSlippagePercent(data.quote.slippageTolerance),
        swapFee: swapFee2
      });
      return {
        state: QuoteState.SUCCESS,
        trade: uniswapXTrade
      };
    } else if (data.routing === URAQuoteType.PRIORITY) {
      const orderInfo = toUnsignedPriorityOrderInfo(data.quote.orderInfo);
      const priorityOrderTrade = new PriorityOrderTrade({
        currencyIn,
        currenciesOut: [currencyOut],
        orderInfo,
        tradeType,
        approveInfo,
        wrapInfo,
        startTimeBufferSecs: data.quote.startTimeBufferSecs,
        deadlineBufferSecs: data.quote.deadlineBufferSecs,
        slippageTolerance: toSlippagePercent(data.quote.slippageTolerance),
        classicGasUseEstimateUSD: classicTrade.totalGasUseEstimateUSD,
        swapFee: swapFee2,
        quoteId: data.quote.quoteId,
        requestId: data.quote.requestId
      });
      return {
        state: QuoteState.SUCCESS,
        trade: priorityOrderTrade
      };
    }
  }
  return { state: QuoteState.SUCCESS, trade: classicTrade };
}
function parseToken({ address, chainId, decimals, symbol, buyFeeBps, sellFeeBps }) {
  const buyFeeBpsBN = buyFeeBps ? BigNumber.from(buyFeeBps) : void 0;
  const sellFeeBpsBN = sellFeeBps ? BigNumber.from(sellFeeBps) : void 0;
  return new Token(chainId, address, parseInt(decimals.toString()), symbol, void 0, false, buyFeeBpsBN, sellFeeBpsBN);
}
function parsePool({ fee, sqrtRatioX96, liquidity, tickCurrent, tokenIn, tokenOut }) {
  return new Pool(
    parseToken(tokenIn),
    parseToken(tokenOut),
    parseInt(fee),
    sqrtRatioX96,
    liquidity,
    parseInt(tickCurrent)
  );
}
const parsePair = ({ reserve0, reserve1 }) => new Pair(
  CurrencyAmount.fromRawAmount(parseToken(reserve0.token), reserve0.quotient),
  CurrencyAmount.fromRawAmount(parseToken(reserve1.token), reserve1.quotient)
);
export function isExactInput(tradeType) {
  return tradeType === TradeType.EXACT_INPUT;
}
export function currencyAddressForSwapQuote(currency) {
  if (currency.isNative) {
    if (isPolygon(currency.chainId)) {
      return SwapRouterNativeAssets.MATIC;
    }
    if (isBsc(currency.chainId)) {
      return SwapRouterNativeAssets.BNB;
    }
    if (isAvalanche(currency.chainId)) {
      return SwapRouterNativeAssets.AVAX;
    }
    return SwapRouterNativeAssets.ETH;
  }
  return currency.address;
}
export function isClassicTrade(trade) {
  return trade?.fillType === TradeFillType.Classic;
}
export function isPreviewTrade(trade) {
  return trade?.fillType === TradeFillType.None;
}
export function isSubmittableTrade(trade) {
  return isClassicTrade(trade) || isUniswapXTrade(trade);
}
export function isUniswapXTradeType(tradeType) {
  return tradeType === TradeFillType.UniswapX || tradeType === TradeFillType.UniswapXv2 || tradeType === TradeFillType.UniswapXv3;
}
export function isUniswapXTrade(trade) {
  return isUniswapXTradeType(trade?.fillType);
}
export function isUniswapXSwapTrade(trade) {
  return isUniswapXTrade(trade) && (trade?.offchainOrderType === OffchainOrderType.DUTCH_AUCTION || trade?.offchainOrderType === OffchainOrderType.DUTCH_V2_AUCTION || trade?.offchainOrderType === OffchainOrderType.DUTCH_V3_AUCTION || trade?.offchainOrderType === OffchainOrderType.PRIORITY_ORDER);
}
export function isLimitTrade(trade) {
  return isUniswapXTrade(trade) && trade?.offchainOrderType === OffchainOrderType.LIMIT_ORDER;
}
