import { colorsLight } from "ui/src/theme";
import { hex } from "wcag-contrast";
export const MIN_COLOR_CONTRAST_THRESHOLD = 3;
export function getContrastPassingTextColor(backgroundColor) {
  const lightText = colorsLight.white;
  if (hex(lightText, backgroundColor) >= MIN_COLOR_CONTRAST_THRESHOLD) {
    return "$white";
  }
  return "$black";
}
