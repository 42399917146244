import { useCallback, useEffect, useMemo, useRef, useState } from "react";
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
export function useAsyncData(asyncCallback, onCancel) {
  const [state, setState] = useState({
    data: void 0,
    isLoading: true,
    error: void 0
  });
  const onCancelRef = useRef(onCancel);
  const lastCompletedAsyncCallbackRef = useRef(asyncCallback);
  useEffect(() => {
    let isPending = false;
    async function runCallback() {
      isPending = true;
      setState((prevState) => {
        if (!prevState.error) {
          return prevState;
        }
        return { ...prevState, error: void 0 };
      });
      const data = await asyncCallback();
      if (isPending) {
        lastCompletedAsyncCallbackRef.current = asyncCallback;
        setState((prevState) => ({ ...prevState, data, isLoading: false }));
      }
    }
    runCallback().catch((error) => {
      setState((prevState) => ({ ...prevState, error }));
      if (isPending) {
        lastCompletedAsyncCallbackRef.current = asyncCallback;
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    }).finally(() => {
      isPending = false;
    });
    const handleCancel = onCancelRef.current;
    return () => {
      if (!isPending) {
        return;
      }
      isPending = false;
      if (handleCancel) {
        handleCancel();
      }
    };
  }, [asyncCallback]);
  return useMemo(() => {
    if (asyncCallback !== lastCompletedAsyncCallbackRef.current) {
      return { isLoading: true, data: void 0 };
    }
    return state;
  }, [asyncCallback, state]);
}
export function useMemoCompare(next, compare) {
  const previousRef = useRef();
  const previous = previousRef.current;
  const nextValue = next();
  const isEqual = compare(previous, nextValue);
  if (!isEqual || !previous) {
    previousRef.current = nextValue;
  }
  return isEqual && previous ? previous : nextValue;
}
export function useOnClickOutside(node, handler, ignoredNodes = []) {
  const handlerRef = useRef(handler);
  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);
  useEffect(() => {
    const handleClickOutside = (e) => {
      const nodeClicked = node.current?.contains(e.target);
      const ignoredNodeClicked = ignoredNodes.reduce(
        (reducer, val) => reducer || !!val.current?.contains(e.target),
        false
      );
      if ((nodeClicked || ignoredNodeClicked) ?? false) {
        return;
      }
      if (handlerRef.current) {
        handlerRef.current();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [node, ignoredNodes]);
}
export function useEvent(callback) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  return useCallback((...args) => callbackRef.current(...args), []);
}
