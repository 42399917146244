import { useTheme } from "@tamagui/core";
import { useMemo } from "react";
export const useSporeColors = (name) => {
  const config = useMemo(() => ({ name }), [name]);
  return useTheme(config);
};
export const useSporeColorsForTheme = (name) => {
  const darkColors = useSporeColors("dark");
  const themeColors = useSporeColors();
  return name === "dark" ? darkColors : themeColors;
};
