import { ChainId as UniswapSDKChainId } from "@uniswap/sdk-core";
export function isUniverseChainId(chainId) {
  return !!chainId && ALL_CHAIN_IDS.includes(chainId);
}
export var UniverseChainId = ((UniverseChainId2) => {
  UniverseChainId2[UniverseChainId2["Mainnet"] = UniswapSDKChainId.MAINNET] = "Mainnet";
  UniverseChainId2[UniverseChainId2["ArbitrumOne"] = UniswapSDKChainId.ARBITRUM_ONE] = "ArbitrumOne";
  UniverseChainId2[UniverseChainId2["Avalanche"] = UniswapSDKChainId.AVALANCHE] = "Avalanche";
  UniverseChainId2[UniverseChainId2["Base"] = UniswapSDKChainId.BASE] = "Base";
  UniverseChainId2[UniverseChainId2["Blast"] = UniswapSDKChainId.BLAST] = "Blast";
  UniverseChainId2[UniverseChainId2["Bnb"] = UniswapSDKChainId.BNB] = "Bnb";
  UniverseChainId2[UniverseChainId2["Celo"] = UniswapSDKChainId.CELO] = "Celo";
  UniverseChainId2[UniverseChainId2["MonadTestnet"] = UniswapSDKChainId.MONAD_TESTNET] = "MonadTestnet";
  UniverseChainId2[UniverseChainId2["Optimism"] = UniswapSDKChainId.OPTIMISM] = "Optimism";
  UniverseChainId2[UniverseChainId2["Polygon"] = UniswapSDKChainId.POLYGON] = "Polygon";
  UniverseChainId2[UniverseChainId2["Sepolia"] = UniswapSDKChainId.SEPOLIA] = "Sepolia";
  UniverseChainId2[UniverseChainId2["Soneium"] = UniswapSDKChainId.SONEIUM] = "Soneium";
  UniverseChainId2[UniverseChainId2["Unichain"] = UniswapSDKChainId.UNICHAIN] = "Unichain";
  UniverseChainId2[UniverseChainId2["UnichainSepolia"] = UniswapSDKChainId.UNICHAIN_SEPOLIA] = "UnichainSepolia";
  UniverseChainId2[UniverseChainId2["WorldChain"] = UniswapSDKChainId.WORLDCHAIN] = "WorldChain";
  UniverseChainId2[UniverseChainId2["Zksync"] = UniswapSDKChainId.ZKSYNC] = "Zksync";
  UniverseChainId2[UniverseChainId2["Zora"] = UniswapSDKChainId.ZORA] = "Zora";
  return UniverseChainId2;
})(UniverseChainId || {});
export const SUPPORTED_CHAIN_IDS = [
  UniverseChainId.Mainnet,
  UniverseChainId.Unichain,
  UniverseChainId.Polygon,
  UniverseChainId.ArbitrumOne,
  UniverseChainId.Optimism,
  UniverseChainId.Base,
  UniverseChainId.Bnb,
  UniverseChainId.Blast,
  UniverseChainId.Avalanche,
  UniverseChainId.Celo,
  UniverseChainId.WorldChain,
  UniverseChainId.Soneium,
  UniverseChainId.Zora,
  UniverseChainId.Zksync
];
export const SUPPORTED_TESTNET_CHAIN_IDS = [
  UniverseChainId.Sepolia,
  UniverseChainId.UnichainSepolia,
  UniverseChainId.MonadTestnet
];
export const ALL_CHAIN_IDS = [...SUPPORTED_CHAIN_IDS, ...SUPPORTED_TESTNET_CHAIN_IDS];
export var RPCType = /* @__PURE__ */ ((RPCType2) => {
  RPCType2["Public"] = "public";
  RPCType2["Private"] = "private";
  RPCType2["PublicAlt"] = "public_alternative";
  RPCType2["Interface"] = "interface";
  RPCType2["Fallback"] = "fallback";
  RPCType2["Default"] = "default";
  return RPCType2;
})(RPCType || {});
export var NetworkLayer = /* @__PURE__ */ ((NetworkLayer2) => {
  NetworkLayer2[NetworkLayer2["L1"] = 0] = "L1";
  NetworkLayer2[NetworkLayer2["L2"] = 1] = "L2";
  return NetworkLayer2;
})(NetworkLayer || {});
