"use strict";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Flex } from "ui/src";
export function createAdaptiveRefetchContext() {
  const Context = createContext(void 0);
  function Provider({ children, fetch, query, stale }) {
    const [numSubscribers, setNumSubscribers] = useState(0);
    const [isStale, setIsStale] = useState(true);
    useEffect(() => {
      if (stale) {
        setIsStale(true);
      }
    }, [stale]);
    useEffect(() => {
      if (isStale && numSubscribers) {
        fetch();
        setIsStale(false);
      }
    }, [numSubscribers, fetch, isStale]);
    const subscribe = useCallback(() => {
      setNumSubscribers((prev) => prev + 1);
      return () => setNumSubscribers((prev) => prev - 1);
    }, []);
    const refetch = useCallback(() => {
      if (!isStale) {
        return;
      }
      fetch();
      setIsStale(false);
    }, [fetch, isStale]);
    return <Context.Provider
      value={useMemo(
        () => ({
          query,
          refetch,
          subscribe
        }),
        [refetch, query, subscribe]
      )}
    >{children}</Context.Provider>;
  }
  function useQuery(options) {
    const context = useContext(Context);
    if (!context) {
      throw new Error("useAdaptiveRefetchQuery must be used within an AdaptiveRefetchProvider");
    }
    const { subscribe } = context;
    useEffect(() => {
      if (options?.cacheOnly === true) {
        return void 0;
      }
      return subscribe();
    }, [options?.cacheOnly, subscribe]);
    return context.query;
  }
  function PrefetchWrapper({ children, className }) {
    const contextValue = useContext(Context);
    if (!contextValue) {
      throw new Error("PrefetchWrapper must be used within an AdaptiveRefetchProvider");
    }
    const { refetch } = contextValue;
    return <Flex className={className} onMouseEnter={refetch}>{children}</Flex>;
  }
  return { Provider, PrefetchWrapper, useQuery };
}
