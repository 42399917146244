"use strict";
import CurrencyLogo from "components/Logo/CurrencyLogo";
import { MouseoverTooltip } from "components/Tooltip";
import Column from "components/deprecated/Column";
import Row from "components/deprecated/Row";
import styled from "lib/styled-components";
import { ThemedText } from "theme/components";
import { useDeviceDimensions } from "ui/src/hooks/useDeviceDimensions";
import { breakpoints } from "ui/src/theme";
import { NumberType, useFormatter } from "utils/formatNumbers";
const Label = styled(ThemedText.BodySmall)`
  cursor: ${({ cursor }) => cursor};
  color: ${({ theme }) => theme.neutral2};
  margin-right: 8px;
`;
const ResponsiveHeadline = ({ children, ...textProps }) => {
  const { fullWidth: width } = useDeviceDimensions();
  if (width && width < breakpoints.xs) {
    return <ThemedText.HeadlineMedium {...textProps}>{children}</ThemedText.HeadlineMedium>;
  }
  return <ThemedText.HeadlineLarge {...textProps}>{children}</ThemedText.HeadlineLarge>;
};
export function SwapModalHeaderAmount({
  tooltipText,
  label,
  amount,
  usdAmount,
  field,
  currency,
  isLoading,
  headerTextProps
}) {
  const { formatNumber, formatReviewSwapCurrencyAmount } = useFormatter();
  return <Row align="center" justify="space-between" gap="md"><Column gap="xs">{label && <ThemedText.BodySecondary><MouseoverTooltip text={tooltipText} disabled={!tooltipText}><Label cursor={tooltipText ? "help" : void 0}>{label}</Label></MouseoverTooltip></ThemedText.BodySecondary>}<Column gap="xs"><ResponsiveHeadline
    data-testid={`${field}-amount`}
    color={isLoading ? "neutral2" : "neutral1"}
    {...headerTextProps}
  >{formatReviewSwapCurrencyAmount(amount)} {currency?.symbol}</ResponsiveHeadline><ThemedText.BodySmall color="neutral2">{formatNumber({
    input: usdAmount,
    type: NumberType.FiatTokenQuantity
  })}</ThemedText.BodySmall></Column></Column><CurrencyLogo currency={currency} size={36} /></Row>;
}
