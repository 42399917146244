"use strict";
export function isIFramed(redirect = false) {
  try {
    if (window.location.ancestorOrigins !== void 0) {
      if (window.location.ancestorOrigins.length > 0) {
        if (redirect && window.top) {
          window.top.location = window.self.location;
        }
        return true;
      }
    }
    if (window.self !== window.top) {
      if (redirect && window.top) {
        window.top.location = window.self.location;
      }
      return true;
    }
  } catch {
    if (redirect) {
      throw new Error("isIFramed: should redirect, but unable to determine if framed");
    }
    return true;
  }
  return false;
}
