"use strict";
import { wagmiConfig } from "components/Web3Provider/wagmiConfig";
import { isTestnetChain } from "uniswap/src/features/chains/utils";
import { didUserReject } from "utils/swapErrorToUserReadableMessage";
import { getAccount } from "wagmi/actions";
export const beforeSend = (event, hint) => {
  if (shouldRejectError(hint.originalException)) {
    return null;
  }
  const exception = event.exception?.values?.[0];
  if (exception?.mechanism && exception.mechanism.synthetic && !exception.mechanism.handled) {
    if (isProviderRpcError(hint.originalException)) {
      if (shouldRejectProviderRpcError(event, hint.originalException)) {
        return null;
      }
      event.exception?.values?.push({
        type: "ProviderRpcError",
        value: `${hint.originalException.code}: ${hint.originalException.message}`
      });
    }
  }
  updateRequestUrl(event);
  updateConnection(event);
  return event;
};
function isErrorLike(error) {
  return error instanceof Object && "message" in error && typeof error?.message === "string";
}
function isEthersRequestErrorLike(error) {
  return "requestBody" in error && typeof error.requestBody === "string";
}
function updateRequestUrl(event) {
  if (event.request?.url) {
    event.request.url = event.request.url.replace("/#", "");
    if (event.request.url.endsWith("/")) {
      event.request.url = event.request.url.slice(0, -1);
    }
  }
}
function updateConnection(event) {
  const { status, address, connector } = getAccount(wagmiConfig);
  event.extra = {
    ...event.extra,
    connection: {
      type: connector?.type,
      name: connector?.name,
      rdns: connector?.id,
      address,
      status
    }
  };
  event.tags = {
    ...event.tags,
    connectionStatus: status,
    connection: connector?.id ?? connector?.name ?? connector?.type
  };
}
function shouldRejectError(error) {
  if (isErrorLike(error)) {
    if (isEthersRequestErrorLike(error)) {
      const method = JSON.parse(error.requestBody).method;
      if (method === "eth_blockNumber") {
        return true;
      }
    }
    if (didUserReject(error)) {
      return true;
    }
    if (error.message.match(/underlying network changed/)) {
      return true;
    }
    if (error.message.match(/Unexpected token '<'/)) {
      return true;
    }
    if (error.message.match(/'unsafe-eval'.*content security policy/i)) {
      return true;
    }
    if (error.message.match(/Blocked a frame with origin ".*" from accessing a cross-origin frame./)) {
      return true;
    }
    if (error.message.match(/NotAllowedError: Write permission denied./)) {
      return true;
    }
    if (error.message.match(/WebAssembly.instantiate\(\): Wasm code generation disallowed by embedder/)) {
      return true;
    }
    if (error.message.match(/null is not an object \(evaluating 'document\.querySelector\('meta\[[^\]]+\]'\)\.content'\)/)) {
      return true;
    }
    if ("stack" in error && typeof error.stack === "string") {
      if (error.stack.match(/-extension:\/\//i)) {
        return true;
      }
      if (error.stack.match(/OneKey/i)) {
        return true;
      }
    }
    if (error instanceof DOMException && error?.name === "AbortError") {
      return true;
    }
  }
  return false;
}
function isProviderRpcError(error) {
  return error instanceof Object && "code" in error && typeof error?.code === "number" && "message" in error && typeof error?.message === "string";
}
function shouldRejectProviderRpcError(event, originalException) {
  const state = event.contexts?.state?.state?.value;
  const chainId = state?.application?.chainId;
  if (chainId && isTestnetChain(chainId)) {
    return true;
  }
  if (originalException.message.includes("Must call 'eth_requestAccounts' before other methods")) {
    return true;
  }
  return false;
}
