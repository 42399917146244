import { useMemo } from "react";
import { getTokenValue } from "tamagui";
import { lineHeights } from "ui/src/components/buttons/Button/constants";
import { getLineHeightForButtonFontTokenKey } from "ui/src/components/buttons/Button/utils/getLineHeightForButtonFontTokenKey";
const getIconSizesForButton = () => ({
  xxsmall: getLineHeightForButtonFontTokenKey(lineHeights.xxsmall),
  xsmall: getLineHeightForButtonFontTokenKey(lineHeights.xsmall),
  small: getLineHeightForButtonFontTokenKey(lineHeights.small),
  medium: getLineHeightForButtonFontTokenKey(lineHeights.medium),
  large: getLineHeightForButtonFontTokenKey(lineHeights.large)
});
const getIconSizesForIconButton = () => ({
  xxsmall: getTokenValue("$icon.16"),
  xsmall: getTokenValue("$icon.16"),
  small: getTokenValue("$icon.20"),
  medium: getTokenValue("$icon.24"),
  large: getTokenValue("$icon.24")
});
export const useIconSizes = (typeOfButton) => useMemo(() => {
  if (typeOfButton === "button") {
    return getIconSizesForButton();
  }
  return getIconSizesForIconButton();
}, [typeOfButton]);
