"use strict";
import i18n from "uniswap/src/i18n";
import { v4 as uuid } from "uuid";
export class UserRejectedRequestError extends Error {
  constructor(message) {
    super(message);
    this.name = "UserRejectedRequestError";
  }
}
export function toReadableError(errorText, error) {
  if (typeof error === "object" && error !== null) {
    const e = error;
    return new Error(`${errorText} \u{1F47A} ${e.message ?? e.reason ?? "unknown"}`);
  }
  return new Error(`${errorText} \u{1F47A} ${error}`);
}
export class WrongChainError extends Error {
  constructor() {
    super(i18n.t("wallet.wrongNet"));
  }
}
export class UniswapXv2HardQuoteError extends Error {
  constructor() {
    super(i18n.t("uniswapx.v2QuoteFailed"));
    this.name = "UniswapXv2HardQuoteError";
  }
}
export class SignatureExpiredError extends Error {
  _id;
  constructor() {
    super(i18n.t("common.signatureExpired"));
    this.name = "SignatureExpiredError";
    this._id = `SignatureExpiredError-${uuid()}`;
  }
  get id() {
    return this._id;
  }
}
