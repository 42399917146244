"use strict";
import { unchecksumDismissedTokenWarningKeys } from "uniswap/src/state/uniswapMigrations";
export const migration21 = (state) => {
  if (!state) {
    return void 0;
  }
  return {
    ...unchecksumDismissedTokenWarningKeys(state),
    _persist: { ...state._persist, version: 21 }
  };
};
