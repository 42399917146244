"use strict";
import { css, keyframes } from "lib/styled-components";
export const flexColumnNoWrap = css`
  display: flex;
  flex-flow: column nowrap;
`;
export const flexRowNoWrap = css`
  display: flex;
  flex-flow: row nowrap;
`;
export var TRANSITION_DURATIONS = /* @__PURE__ */ ((TRANSITION_DURATIONS2) => {
  TRANSITION_DURATIONS2[TRANSITION_DURATIONS2["slow"] = 500] = "slow";
  TRANSITION_DURATIONS2[TRANSITION_DURATIONS2["medium"] = 250] = "medium";
  TRANSITION_DURATIONS2[TRANSITION_DURATIONS2["fast"] = 125] = "fast";
  return TRANSITION_DURATIONS2;
})(TRANSITION_DURATIONS || {});
export const transitions = {
  duration: {
    slow: `${500 /* slow */}ms`,
    medium: `${250 /* medium */}ms`,
    fast: `${125 /* fast */}ms`
  },
  timing: {
    ease: "ease",
    in: "ease-in",
    out: "ease-out",
    inOut: "ease-in-out"
  }
};
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const textFadeIn = css`
  animation: ${fadeIn} ${transitions.duration.fast} ${transitions.timing.in};
`;
