"use strict";
import { CurrencyAmount, Price, TradeType } from "@uniswap/sdk-core";
import JSBI from "jsbi";
import { useEffect, useMemo, useState } from "react";
import { PositionField } from "types/position";
import { PollingInterval } from "uniswap/src/constants/misc";
import { useTrade } from "uniswap/src/features/transactions/swap/hooks/useTrade";
export function useDefaultInitialPrice({
  currencies,
  skip
}) {
  const [price, setPrice] = useState();
  const currencyIn = currencies[PositionField.TOKEN0];
  const currencyOut = currencies[PositionField.TOKEN1];
  const amountSpecified = useMemo(() => {
    if (!currencyIn) {
      return void 0;
    }
    return CurrencyAmount.fromRawAmount(currencyIn, JSBI.BigInt(10 ** currencyIn.decimals));
  }, [currencyIn]);
  const { trade, isLoading } = useTrade({
    amountSpecified,
    otherCurrency: currencyOut,
    tradeType: TradeType.EXACT_INPUT,
    pollInterval: PollingInterval.Slow,
    skip: !amountSpecified || !currencyOut || !!price || skip
  });
  useEffect(() => {
    setPrice(void 0);
  }, [currencyIn, currencyOut]);
  useEffect(() => {
    if (trade?.outputAmount && currencyIn && currencyOut) {
      setPrice(new Price(currencyIn, currencyOut, trade.inputAmount.quotient, trade.outputAmount.quotient));
    }
  }, [trade, currencyIn, currencyOut]);
  return { isLoading, price };
}
