/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Routing {
    DUTCH_LIMIT = 'DUTCH_LIMIT',
    CLASSIC = 'CLASSIC',
    DUTCH_V2 = 'DUTCH_V2',
    DUTCH_V3 = 'DUTCH_V3',
    BRIDGE = 'BRIDGE',
    LIMIT_ORDER = 'LIMIT_ORDER',
    PRIORITY = 'PRIORITY',
}
